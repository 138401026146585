import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { InputAdornment, Snackbar, IconButton } from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FunctionalPage } from '../../component';
import {
  COLOR_PRIMARY, PAGE_MODE_TABLE, PAGE_MODE_VIEW, RXFIELD_API_KEY_CONSUMER,
  RXFIELD_API_KEY_OWNER, RXFIELD_API_KEY_SCOPE, RXFIELD_API_KEY_TOKEN, RXFORM_API_KEY,
  RXSTATE_API_KEYS, RXSTATE_API_KEY_PAGE, snackbarDuration, FILTER_TYPE_MULTIPLE_VALUES,
} from '../../constant';
import {
  renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField,
} from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateApiKey } from '../../validation';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const renderDialogContent = (defaultConsumerValue, defaultOwnerValue, defaultScopeValue,
  defaultTokenValue, scopes, services, addingEditing, copyStatus, loadingScope, loadingService,
  onChangeConsumerAppNameText, onChangeOwnerAppNameText, onClickCopyIcon, onConsumerOptionSelected,
  onOwnerAppNameOptionSelected, onChangeScopeText, onScopeOptionSelected, pageMode) => (
    <div>
      <Field
        name={RXFIELD_API_KEY_TOKEN}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.apiKeyScreen.placeholderToken}
        label={LocalizedString.apiKeyScreen.placeholderToken}
        disabled
        hidden={pageMode === PAGE_MODE_TABLE}
        required={pageMode === PAGE_MODE_TABLE}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <IconButton onClick={() => onClickCopyIcon(copyStatus)} edge="end">
                <CopyToClipboard text={defaultTokenValue}>
                  <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                </CopyToClipboard>
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <Field
        name={RXFIELD_API_KEY_OWNER}
        component={renderReduxFormOutlinedDropdownTextField}
        placeholder={LocalizedString.apiKeyScreen.placeholderOwner}
        label={LocalizedString.apiKeyScreen.placeholderOwner}
        disabled={addingEditing || pageMode !== PAGE_MODE_TABLE}
        data={services}
        value={defaultOwnerValue}
        loading={loadingService}
        onChangeText={(text) => onChangeOwnerAppNameText(text, pageMode)}
        onOptionSelected={onOwnerAppNameOptionSelected}
        required={pageMode === PAGE_MODE_TABLE}
      />

      <Field
        name={RXFIELD_API_KEY_SCOPE}
        component={renderReduxFormOutlinedDropdownTextField}
        placeholder={LocalizedString.apiKeyScreen.placeholderScope}
        label={LocalizedString.apiKeyScreen.placeholderScope}
        disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
        data={scopes}
        value={defaultScopeValue}
        loading={loadingScope}
        onChangeText={onChangeScopeText}
        onOptionSelected={onScopeOptionSelected}
        required
      />

      <Field
        name={RXFIELD_API_KEY_CONSUMER}
        component={renderReduxFormOutlinedDropdownTextField}
        placeholder={LocalizedString.apiKeyScreen.placeholderConsumer}
        label={LocalizedString.apiKeyScreen.placeholderConsumer}
        disabled={addingEditing || pageMode !== PAGE_MODE_TABLE}
        data={services}
        value={defaultConsumerValue}
        loading={loadingService}
        onChangeText={onChangeConsumerAppNameText}
        onOptionSelected={onConsumerOptionSelected}
        required={pageMode === PAGE_MODE_TABLE}
      />

      <Snackbar
        open={copyStatus}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.apiKeyScreen.labelCopied}
        onClose={() => onClickCopyIcon(copyStatus)}
      />
    </div>
);

const ApiKeyPage = ({
  initialValues, scopes, services,
  addingEditing, copyStatus, downloading, loadingScope, loadingService,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangeConsumerAppNameText, onChangeOwnerAppNameText, onChangePage, onChangePageSize,
  onClickCopyIcon, onConfirmDeletePressed, onConsumerAdvancedFilterOptionSelected,
  onConsumerOptionSelected, onCreatePressed, onDeletePressed, onEditPressed,
  onOwnerAppNameAdvancedFilterOptionSelected, onOwnerAppNameOptionSelected, onChangeScopeText,
  onScopeOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onScopeOptionAdvancedFilterSelected, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed,
  apiKeys, pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_API_KEYS}
    uiPage={RXSTATE_API_KEY_PAGE}
    filterColumns={[
      {
        title: LocalizedString.apiKeyScreen.labelOwner,
        field: RXFIELD_API_KEY_OWNER,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: services,
        loading: loadingService,
        onChangeFilterText: onChangeOwnerAppNameText,
        onFilterOptionSelected: onOwnerAppNameAdvancedFilterOptionSelected,
      },
      {
        title: LocalizedString.apiKeyScreen.labelConsumer,
        field: RXFIELD_API_KEY_CONSUMER,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: services,
        loading: loadingService,
        onChangeFilterText: onChangeConsumerAppNameText,
        onFilterOptionSelected: onConsumerAdvancedFilterOptionSelected,
      },
      {
        title: LocalizedString.apiKeyScreen.labelScope,
        field: RXFIELD_API_KEY_SCOPE,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: scopes,
        loading: loadingScope,
        onChangeFilterText: onChangeScopeText,
        onFilterOptionSelected: onScopeOptionAdvancedFilterSelected,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.apiKeyScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      {
        title: LocalizedString.apiKeyScreen.labelToken,
        field: 'token',
        render: ({ token }) => (token.substring(0, 10)),
        customFilterAndSearch: (term, rowData) => (rowData.token.substring(0, 10))
          .indexOf(term) !== -1,
        sorting: !downloading,
      },
      { title: LocalizedString.apiKeyScreen.labelScope, field: 'scope', sorting: !downloading },
      { title: LocalizedString.apiKeyScreen.labelConsumer, field: 'consumerAppName', sorting: !downloading },
      { title: LocalizedString.apiKeyScreen.labelOwner, field: 'ownerAppName', sorting: !downloading },
      { title: LocalizedString.apiKeyScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={(id) => onEditPressed(id, apiKeys)}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.apiKeyScreen.buttonCaptionCreateNewApiKey}
    deleteButtonCaption={LocalizedString.apiKeyScreen.buttonCaptionDeleteApiKey}
    editButtonCaption={LocalizedString.apiKeyScreen.buttonCaptionEditApiKey}
    title={LocalizedString.apiKeyScreen.title}
    createPermissionName="AUTH_CREATE_APIKEY"
    deletePermissionName="AUTH_DELETE_APIKEY"
    editPermissionName="AUTH_EDIT_APIKEY"
  >
    {renderDialogContent(initialValues.consumerAppName, initialValues.ownerAppName,
      initialValues.scope, initialValues.token, scopes, services, addingEditing, copyStatus,
      loadingScope, loadingService, onChangeConsumerAppNameText, onChangeOwnerAppNameText,
      onClickCopyIcon, onConsumerOptionSelected, onOwnerAppNameOptionSelected, onChangeScopeText,
      onScopeOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_API_KEY,
  validate: rxformValidateApiKey,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ApiKeyPage);

ApiKeyPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  scopes: PropTypes.arrayOf(SimpleDataShape).isRequired,
  services: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  copyStatus: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingScope: PropTypes.bool.isRequired,
  loadingService: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeConsumerAppNameText: PropTypes.func.isRequired,
  onChangeOwnerAppNameText: PropTypes.func.isRequired,
  onChangeScopeText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onClickCopyIcon: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onConsumerAdvancedFilterOptionSelected: PropTypes.func.isRequired,
  onConsumerOptionSelected: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onOwnerAppNameAdvancedFilterOptionSelected: PropTypes.func.isRequired,
  onOwnerAppNameOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onScopeOptionAdvancedFilterSelected: PropTypes.func.isRequired,
  onScopeOptionSelected: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  apiKeys: PropTypes.objectOf(PropTypes.object).isRequired,
  pageMode: PropTypes.string.isRequired,
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl, FormHelperText, InputLabel, Input,
} from '@material-ui/core';
import AutocompleteCheckboxField from './component/autocomplete-checkbox-field';
import AutocompleteTableField from './component/autocomplete-table-field';
import AutocompleteTextField from './component/autocomplete-text-field';
import CodeEditor from './component/code-editor';
import CheckboxOption from './component/checkbox-option';
import ColorPickerField from './component/color-picker-field';
import DateTimePickerField from './component/date-time-picker-field';
import DropzonePicker from './component/dropzone-picker';
import EditableTableField from './component/editable-table-field';
import FilePickerField from './component/file-picker-field';
import ImageInputField from './component/image-input-field';
import OutlinedDropdownField from './component/outlined-dropdown-field';
import OutlinedTextField from './component/outlined-text-field';
import RadioButtonField from './component/radio-button-field';
import RichTextEditorField from './component/rich-text-editor-field';
import SwitchField from './component/switch-field';
import TransferListField from './component/transfer-list-field';
import WebCam from './component/web-cam';
import { SimpleDataShape } from './type';

const onPickerValueSelected = (
  selectedValue, onValueSelected, onChange, fileName = '',
) => {
  if (onValueSelected) {
    onValueSelected(selectedValue, fileName);
  }
  onChange(selectedValue);
};

export const renderReduxFormTextField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    label,
    type,
    ...restProps
  } = props;
  const errorProps = touched && error ? error : undefined;
  return (
    <div>
      <FormControl margin="normal" error={touched && !!error} {...restProps}>
        <InputLabel htmlFor="component-error">{label}</InputLabel>
        <Input type={type} autoComplete="off" {...restInput} />
        {touched && error && <FormHelperText>{errorProps}</FormHelperText>}
      </FormControl>
    </div>
  );
};

renderReduxFormTextField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  label: PropTypes.bool,
  type: PropTypes.string,
};

renderReduxFormTextField.defaultProps = {
  label: '',
  type: '',
};

export const renderReduxFormOutlinedTextField = (props) => {
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
    helperText,
    defaultValue,
    ...restProps
  } = props;
  const errorProps = touched && error ? error : undefined;
  return (
    <OutlinedTextField
      error={touched && !!error}
      helperText={helperText || errorProps}
      onChangeText={onChange}
      autoComplete="off"
      value={defaultValue || value}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormOutlinedTextField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  helperText: PropTypes.string,
  defaultValue: PropTypes.string,
};

renderReduxFormOutlinedTextField.defaultProps = {
  helperText: '',
  defaultValue: '',
};

export const renderReduxFormOutlinedDropdownTextField = (props) => {
  const {
    input: { onChange: onChangeText, ...restInput },
    meta: { touched, error },
    data,
    helperText,
    ...restProps
  } = props;
  const errorProps = touched && error ? error : undefined;
  return (
    <AutocompleteTextField
      options={data}
      error={touched && !!error}
      helperText={helperText || errorProps}
      onChangeText={onChangeText}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormOutlinedDropdownTextField.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  helperText: PropTypes.string,
};

renderReduxFormOutlinedDropdownTextField.defaultProps = {
  helperText: '',
};

export const renderReduxFormImageInputField = (props) => {
  const {
    input: { ...restInput },
    meta: { touched, error },
    required,
    onImageSelected,
    defaultValue,
    label,
    helperText,
    result,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const value = result || defaultValue;
  const errorProps = touched && error && value.length === 0 ? error : undefined;
  return (
    <ImageInputField
      error={!!errorProps}
      helperText={helperText || errorProps}
      onImageSelected={onImageSelected}
      label={labelProps}
      defaultValue={defaultValue}
      result={result}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormImageInputField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  onImageSelected: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  result: PropTypes.string,
};

renderReduxFormImageInputField.defaultProps = {
  required: false,
  onImageSelected: () => {},
  defaultValue: '',
  helperText: '',
  result: '',
};

export const renderReduxFormDateTimePickerField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    required,
    dateFormat,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <DateTimePickerField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      onChangeDate={onChange}
      autoComplete="off"
      format={dateFormat}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormDateTimePickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  dateFormat: PropTypes.string,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

renderReduxFormDateTimePickerField.defaultProps = {
  required: false,
  dateFormat: '',
  helperText: '',
};

export const renderReduxFormTransferListField = (props) => {
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
    data,
    required,
    onOptionSelected,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <TransferListField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      data={data}
      selectedData={value instanceof Array ? value : []}
      onOptionSelected={onOptionSelected}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormTransferListField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string),
      PropTypes.arrayOf(SimpleDataShape),
      PropTypes.bool]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  data: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(SimpleDataShape)]).isRequired,
  required: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

renderReduxFormTransferListField.defaultProps = {
  required: false,
  onOptionSelected: () => {},
  helperText: '',
};

export const renderReduxFormSwitchField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    required,
    onOptionSelected,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <SwitchField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      onOptionSelected={onOptionSelected}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormSwitchField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  onOptionSelected: PropTypes.func,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

renderReduxFormSwitchField.defaultProps = {
  required: false,
  onOptionSelected: () => {},
  helperText: '',
};

export const renderReduxFormEditableTableField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    required,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <EditableTableField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormEditableTableField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string,
};

renderReduxFormEditableTableField.defaultProps = {
  required: false,
  helperText: '',
  label: '',
};

export const renderReduxFormRadioButtonField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    required,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <RadioButtonField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormRadioButtonField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

renderReduxFormRadioButtonField.defaultProps = {
  required: false,
  helperText: '',
};

export const renderReduxFormRichTextEditorField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    required,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <RichTextEditorField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormRichTextEditorField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

renderReduxFormRichTextEditorField.defaultProps = {
  required: false,
  helperText: '',
};

export const renderReduxFormSimpleDropdownField = (props) => {
  const {
    input: { onChange, ...restInput },
    meta: { touched, error },
    required,
    helperText,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <OutlinedDropdownField
      error={touched && !!error}
      helperText={helperText || errorProps}
      label={labelProps}
      {...restProps}
      {...restInput}
    />
  );
};

renderReduxFormSimpleDropdownField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  label: PropTypes.string.isRequired,
};

renderReduxFormSimpleDropdownField.defaultProps = {
  required: false,
  helperText: '',
};

export const renderReduxFormFilePickerField = (props) => {
  const {
    input: { value, ...restInput },
    meta: { touched, error },
    required,
    onFileSelected,
    label,
    helperText,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error && value.length === 0 ? error : undefined;
  return (
    <FilePickerField
      error={!!errorProps}
      helperText={helperText || errorProps}
      onFileSelected={onFileSelected}
      label={labelProps}
      value={value}
      required={required}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormFilePickerField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  onFileSelected: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

renderReduxFormFilePickerField.defaultProps = {
  required: false,
  helperText: '',
};

export const renderReduxFormAutocompleteCheckboxField = (props) => {
  const {
    input: { onChange: onChangeText, ...restInput },
    meta: { touched, error },
    data,
    helperText,
    ...restProps
  } = props;
  const errorProps = touched && error ? error : undefined;
  return (
    <AutocompleteCheckboxField
      options={data}
      error={touched && !!error}
      helperText={helperText || errorProps}
      onChangeText={onChangeText}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormAutocompleteCheckboxField.propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])).isRequired,
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  helperText: PropTypes.string,
};

renderReduxFormAutocompleteCheckboxField.defaultProps = {
  helperText: '',
};

export const renderReduxFormTakePictureField = (props) => {
  const {
    input: { value, ...restInput },
    meta: { touched, error },
    required,
    onPictureTaken,
    label,
    helperText,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error && value.length === 0 ? error : undefined;
  return (
    <WebCam
      error={!!errorProps}
      helperText={helperText || errorProps}
      onPictureTaken={onPictureTaken}
      label={labelProps}
      value={value}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormTakePictureField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  onImageSelected: PropTypes.func,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onPictureTaken: PropTypes.func,
};

renderReduxFormTakePictureField.defaultProps = {
  required: false,
  helperText: '',
  defaultValue: '',
  onImageSelected: () => {},
  onPictureTaken: () => {},
};

export const renderReduxFormAutocompleteTableField = (props) => {
  const {
    input: { onChange: onChangeText, ...restInput },
    meta: { touched, error },
    required,
    label,
    helperText,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <AutocompleteTableField
      label={labelProps}
      error={touched && !!error}
      helperText={helperText || errorProps}
      onChangeText={onChangeText}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormAutocompleteTableField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
};

renderReduxFormAutocompleteTableField.defaultProps = {
  required: false,
  helperText: '',
};

export const renderReduxFormCodeEditorField = (props) => {
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
    required,
    label,
    helperText,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error && value.length === 0 ? error : undefined;
  return (
    <CodeEditor
      error={!!errorProps}
      helperText={helperText || errorProps}
      onChangeText={onChange}
      label={labelProps}
      value={value}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormCodeEditorField.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    onChange: PropTypes.func,
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  onValueChanged: PropTypes.func,
};

renderReduxFormCodeEditorField.defaultProps = {
  required: false,
  helperText: '',
  defaultValue: '',
  onValueChanged: () => { },
};

export const renderReduxFormColorPickerField = (props) => {
  const {
    input: { onChange, value, ...restInput },
    meta: { touched, error },
    helperText,
    defaultValue,
    InputProps,
    required,
    label,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const errorProps = touched && error ? error : undefined;
  return (
    <div>
      <ColorPickerField
        label={labelProps}
        error={!!errorProps}
        helperText={helperText || errorProps}
        value={value}
        {...restInput}
        {...restProps}
      />
    </div>
  );
};

renderReduxFormColorPickerField.propTypes = {
  input: PropTypes.shape({
    onChange: PropTypes.func,
    value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  required: PropTypes.bool,
  helperText: PropTypes.string,
  defaultValue: PropTypes.string,
  InputProps: PropTypes.object,
  label: PropTypes.string.isRequired,
};

renderReduxFormColorPickerField.defaultProps = {
  helperText: '',
  defaultValue: '',
  InputProps: {},
  required: false,
};

export const renderReduxFormCheckboxOption = (params) => {
  const {
    input: { onChange, value, ...inputProps },
    meta: { touched, error },
    onValueSelected,
    horizontal,
    ...optionProps
  } = params;
  const errorProps = touched && error ? error : undefined;
  return (
    <CheckboxOption
      selectedValue={value}
      onValueSelected={(selectedValue) => onPickerValueSelected(
        selectedValue,
        onValueSelected,
        onChange,
      )}
      error={errorProps}
      horizontal={horizontal}
      {...inputProps}
      {...optionProps}
    />
  );
};

export const renderReduxFormDropzonePicker = (props) => {
  const {
    input: { ...restInput },
    meta: { touched, error },
    onImageSelected,
    required,
    defaultValue,
    label,
    helperText,
    result,
    imageFileExtension,
    ...restProps
  } = props;
  const labelProps = required ? `${label} *` : label;
  const value = result || defaultValue;
  const errorProps = touched && error && value.length === 0 ? error : undefined;
  return (
    <DropzonePicker
      error={!!errorProps}
      helperText={helperText || errorProps}
      label={labelProps}
      defaultValue={defaultValue}
      result={result}
      imageFileExtension={imageFileExtension}
      onImageSelected={onImageSelected}
      {...restInput}
      {...restProps}
    />
  );
};

renderReduxFormDropzonePicker.propTypes = {
  input: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  }).isRequired,
  meta: PropTypes.shape({
    touched: PropTypes.bool,
    error: PropTypes.string,
  }).isRequired,
  onImageSelected: PropTypes.func.isRequired,
  required: PropTypes.bool,
  defaultValue: PropTypes.string,
  label: PropTypes.string.isRequired,
  helperText: PropTypes.string,
  result: PropTypes.string,
  imageFileExtension: PropTypes.string,
};

renderReduxFormDropzonePicker.defaultProps = {
  required: false,
  defaultValue: '',
  helperText: '',
  result: '',
  imageFileExtension: '',
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_QUIZ_CATEGORY_NAME, RXFIELD_QUIZ_CATEGORY_DESCRIPTION,
  RXSTATE_QUIZ_CATEGORIES, RXSTATE_QUIZ_CATEGORY_PAGE, RXFORM_QUIZ_CATEGORY,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateQuizCategory } from '../../validation';

const renderDialogContent = (addingEditing, downloadingDeleting, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_QUIZ_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.quizCategoryScreen.placeholderName}
          label={LocalizedString.quizCategoryScreen.placeholderName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_QUIZ_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.quizCategoryScreen.placeholderDescription}
          label={LocalizedString.quizCategoryScreen.placeholderDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const QuizCategoryPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_QUIZ_CATEGORIES}
    uiPage={RXSTATE_QUIZ_CATEGORY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.quizCategoryScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.quizCategoryScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.quizCategoryScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.quizCategoryScreen.buttonCaptionCreateNewQuizCategory}
    editButtonCaption={LocalizedString.quizCategoryScreen.buttonCaptionEditQuizCategory}
    deleteButtonCaption={LocalizedString.quizCategoryScreen.buttonCaptionDeleteQuizCategory}
    title={LocalizedString.quizCategoryScreen.title}
    useFullWidth
    createPermissionName="SURVEY_CREATE_QUIZ_CATEGORY"
    editPermissionName="SURVEY_EDIT_QUIZ_CATEGORY"
    deletePermissionName="SURVEY_DELETE_QUIZ_CATEGORY"
  >
    {renderDialogContent(addingEditing, downloadingDeleting, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_QUIZ_CATEGORY,
  validate: rxformValidateQuizCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuizCategoryPage);

QuizCategoryPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};

import { connect } from 'react-redux';
import { change, reset } from 'redux-form';
import {
  INITIAL_ORDER_BY_WFA_APPROVAL_MAPPINGS, MENUID_ATTENDANCE_ADDON_WFA_APPROVAL_MAPPING,
  PAGE_MODE_TABLE, REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_WFA_APPROVAL_MAPPING_APPROVER,
  RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT, RXFIELD_WFA_APPROVAL_MAPPING_TYPE,
  RXFIELD_WFA_APPROVAL_MAPPING_USER, RXFORM_ADVANCED_FILTER_DIALOG, RXFORM_WFA_APPROVAL_MAPPING,
  WFA_APPROVAL_MAPPING_TYPE_USER, RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE,
} from '../../constant';
import {
  debounceSearch, transformDropdownData, transformInitialValues, transformUserDropdownData,
} from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import {
  addEditWfaApprovalMappingAsync, clearDepartments, clearProfiles, clearWfaApprovalMappings,
  downloadDeleteWfaApprovalMappingAsync, downloadWfaApprovalMappingsAsync, setActiveSideMenuItem,
  setAlertConfirmationMessage, setAlertErrorMessage, setDepartmentSearchText,
  setProfileAdvancedFilterDialogSelectedFilterString, setProfileSearchText,
  setWfaApprovalMappingAdvancedFilterDialogSelectedFilterString, setWfaApprovalMappingSearchText,
  setWfaApprovalMappingSelectedApprover, setWfaApprovalMappingSelectedDepartment,
  setWfaApprovalMappingSelectedOrderBy, setWfaApprovalMappingSelectedPageSize,
  setWfaApprovalMappingSelectedType, setWfaApprovalMappingSelectedUser,
  setWfaApprovalMappingTappedId, setWfaApprovalMappingSelectedImportFile,
  importWfaApprovalMappingFileAsync,
} from '../../redux/action';
import { downloadDepartmentsAsync, downloadProfilesAsync } from '../../../../redux/action';
import { clearEmployees, downloadEmployeesAsync, setHistoryEmployeeSearchText } from '../../../attendance/redux/action';
import WfaApprovalMappingPage from './wfa-approval-mapping.presentation';
import downloadMyConfigItemsAsync from '../../../configuration/redux/action/async/downloadMyConfigItemsAsync';

const getInitialValues = (state) => {
  const { wfaApprovalMappings, uiWfaApprovalMapping, uiFunctionalPage } = state;
  const { downloadingDeleting, tappedId } = uiWfaApprovalMapping;
  const { pageMode } = uiFunctionalPage;

  const found = pageMode !== PAGE_MODE_TABLE && !downloadingDeleting
    ? wfaApprovalMappings.data[tappedId] : {};
  const initVal = Object.keys(found).length > 0 ? transformInitialValues(found, {
    approver: found?.approver ? { label: found?.approver?.fullName, value: found?.approver?.id } : '',
    departmentName: found?.departmentName ? { label: found?.departmentName, value: found?.departmentName } : '',
    user: found?.user ? { label: found?.user?.fullName, value: found?.user?.id } : '',
  }) : {};
  return initVal;
};

const mapStateToProps = (state) => ({
  initialValues: getInitialValues(state),
  approvers: transformUserDropdownData(state.profiles.data),
  departments: transformDropdownData(state.departments.data),
  users: transformUserDropdownData(state.employees.data),
  addingEditing: state.uiWfaApprovalMapping.addingEditing,
  importing: state.uiWfaApprovalMapping.importing,
  downloading: state.uiWfaApprovalMapping.downloading,
  downloadingDeleting: state.uiWfaApprovalMapping.downloadingDeleting,
  loadingApprover: state.uiProfile.downloading,
  loadingDepartment: state.notification.downloadingDepartments,
  loadingUser: state.uiHistory.downloadingEmployees,
  pageMode: state.uiFunctionalPage.pageMode,
  selectedType: state.uiWfaApprovalMapping.selectedType,
  templateUrl: state.myConfigItems?.attendanceAddOn?.wfaApprovalMappingImportTemplate || '',
});

const searchApproverDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearProfiles());
    dispatch(downloadProfilesAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchDepartmentDebounce = debounceSearch(
  (dispatch) => {
    dispatch(clearDepartments());
    dispatch(downloadDepartmentsAsync(1))
      .catch((error) => setAlertErrorMessage(error));
  },
);

const searchUserDebounce = debounceSearch((dispatch) => {
  dispatch(clearEmployees());
  dispatch(downloadEmployeesAsync(1))
    .catch((err) => dispatch(setAlertErrorMessage(err)));
});

const mapDispatchToProps = (dispatch) => ({
  onAdvancedFilterPressed: () => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearDepartments());
    dispatch(clearProfiles());
    dispatch(clearEmployees());
    dispatch(downloadDepartmentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onAppear: () => {
    dispatch(setWfaApprovalMappingAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setActiveSideMenuItem(MENUID_ATTENDANCE_ADDON_WFA_APPROVAL_MAPPING));
    dispatch(setWfaApprovalMappingSearchText(''));
    dispatch(clearWfaApprovalMappings());
    dispatch(setWfaApprovalMappingSelectedPageSize(20));
    dispatch(setWfaApprovalMappingSelectedOrderBy(INITIAL_ORDER_BY_WFA_APPROVAL_MAPPINGS));
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(downloadMyConfigItemsAsync(true))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onApplyAdvancedFilterPressed: (filterString) => {
    const text = filterString.replace(/approver/, 'approver.id').replace(/user/, 'user.id');
    dispatch(setWfaApprovalMappingAdvancedFilterDialogSelectedFilterString(text));
    dispatch(clearWfaApprovalMappings());
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onApproverOptionSelected: (option) => {
    if (option) {
      dispatch(setWfaApprovalMappingSelectedApprover(option));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_APPROVER, option));
    } else {
      dispatch(setWfaApprovalMappingSelectedApprover(''));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_APPROVER, ''));
    }
  },
  onCancelAdvancedFilterPressed: () => {
    dispatch(setWfaApprovalMappingSelectedType(''));
    dispatch(setWfaApprovalMappingSelectedApprover(''));
    dispatch(setWfaApprovalMappingSelectedDepartment(''));
    dispatch(setWfaApprovalMappingSelectedUser(''));
    dispatch(reset(RXFORM_WFA_APPROVAL_MAPPING));
  },
  onCancelPressed: () => {
    dispatch(setWfaApprovalMappingSelectedType(''));
    dispatch(setWfaApprovalMappingSelectedApprover(''));
    dispatch(setWfaApprovalMappingSelectedDepartment(''));
    dispatch(setWfaApprovalMappingSelectedUser(''));
    dispatch(reset(RXFORM_WFA_APPROVAL_MAPPING));
  },
  onChangeApproverText: (text) => {
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setProfileSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchApproverDebounce(dispatch, true);
    }
  },
  onChangeDepartmentText: (text) => {
    dispatch(setDepartmentSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchDepartmentDebounce(dispatch, true);
    }
  },
  onChangePage: (pageNo) => {
    dispatch(downloadWfaApprovalMappingsAsync(pageNo + 1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangePageSize: (pageSize) => {
    dispatch(setWfaApprovalMappingSelectedPageSize(pageSize));
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onChangeUserText: async (text) => {
    dispatch(setHistoryEmployeeSearchText(text));
    if (text.length >= 3 || text.length === 0) {
      searchUserDebounce(dispatch);
    }
  },
  onConfirmDeletePressed: () => {
    dispatch(downloadDeleteWfaApprovalMappingAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onCreatePressed: () => {
    dispatch(setWfaApprovalMappingTappedId(''));
    dispatch(setWfaApprovalMappingSelectedType(''));
    dispatch(setWfaApprovalMappingSelectedApprover(''));
    dispatch(setWfaApprovalMappingSelectedDepartment(''));
    dispatch(setWfaApprovalMappingSelectedUser(''));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearDepartments());
    dispatch(clearProfiles());
    dispatch(clearEmployees());
    dispatch(downloadDepartmentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onDeletePressed: (id) => {
    dispatch(setWfaApprovalMappingTappedId(id));
    dispatch(setAlertConfirmationMessage(GlobalLocalizedString.common.msgDeleteConfirmation));
  },
  onDepartmentOptionSelected: (option) => {
    if (option) {
      dispatch(setWfaApprovalMappingSelectedDepartment(option));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT,
        option));
    } else {
      dispatch(setWfaApprovalMappingSelectedDepartment(''));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT, ''));
    }
  },
  onEditPressed: (id) => {
    dispatch(setWfaApprovalMappingTappedId(id));
    dispatch(downloadDeleteWfaApprovalMappingAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
    dispatch(setProfileAdvancedFilterDialogSelectedFilterString(''));
    dispatch(setDepartmentSearchText(''));
    dispatch(setHistoryEmployeeSearchText(''));
    dispatch(setProfileSearchText(''));
    dispatch(clearDepartments());
    dispatch(clearProfiles());
    dispatch(clearEmployees());
    dispatch(downloadDepartmentsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadEmployeesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(downloadProfilesAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  },
  onRefresh: (pageSize) => {
    dispatch(setWfaApprovalMappingSelectedPageSize(pageSize));
    dispatch(clearWfaApprovalMappings());
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onResetAdvancedFilterPressed: () => {
    dispatch(setWfaApprovalMappingSelectedType(''));
    dispatch(setWfaApprovalMappingSelectedApprover(''));
    dispatch(setWfaApprovalMappingSelectedDepartment(''));
    dispatch(setWfaApprovalMappingSelectedUser(''));
    dispatch(setWfaApprovalMappingAdvancedFilterDialogSelectedFilterString(''));
    dispatch(reset(RXFORM_ADVANCED_FILTER_DIALOG));
    dispatch(clearWfaApprovalMappings());
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSavePressed: async (values) => {
    try {
      await dispatch(addEditWfaApprovalMappingAsync(values));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSearchBarTextChanged: async (text) => {
    try {
      dispatch(setWfaApprovalMappingSearchText(text));
      dispatch(clearWfaApprovalMappings());
      await dispatch(downloadWfaApprovalMappingsAsync(1));
    } catch (error) {
      dispatch(setAlertErrorMessage(error));
    }
  },
  onSortPressed: (orderBy) => {
    dispatch(setWfaApprovalMappingSelectedOrderBy(orderBy));
    dispatch(clearWfaApprovalMappings());
    dispatch(downloadWfaApprovalMappingsAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onSubmitPressed: async (values) => {
    await dispatch(addEditWfaApprovalMappingAsync(values));
  },
  onTypeOptionSelected: (option) => {
    if (option) {
      dispatch(setWfaApprovalMappingSelectedDepartment(''));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT, ''));
      dispatch(setWfaApprovalMappingSelectedUser(''));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_USER, ''));
      dispatch(setWfaApprovalMappingSelectedType(option));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_TYPE, option));
      if (option === WFA_APPROVAL_MAPPING_TYPE_USER) {
        dispatch(setHistoryEmployeeSearchText(''));
        dispatch(clearEmployees());
        dispatch(downloadEmployeesAsync(1))
          .catch((err) => dispatch(setAlertErrorMessage(err)));
      } else {
        dispatch(setDepartmentSearchText(''));
        dispatch(clearDepartments());
        dispatch(downloadDepartmentsAsync(1))
          .catch((err) => dispatch(setAlertErrorMessage(err)));
      }
    } else {
      dispatch(setWfaApprovalMappingSelectedType(''));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_TYPE, ''));
    }
  },
  onUserOptionSelected: (option) => {
    if (option) {
      dispatch(setWfaApprovalMappingSelectedUser(option));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_USER, option));
    } else {
      dispatch(setWfaApprovalMappingSelectedUser(''));
      dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_USER, ''));
    }
  },
  onViewPressed: (id) => {
    dispatch(setWfaApprovalMappingTappedId(id));
    dispatch(downloadDeleteWfaApprovalMappingAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onFileSelected: (file) => {
    dispatch(setWfaApprovalMappingSelectedImportFile(file));
    dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE, file));
  },
  onDownloadTemplatePressed: async (templateUrl) => {
    try {
      const extension = templateUrl?.match(/(\.[^.]*)$/g)[0];
      const fileUrl = `${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}${templateUrl}`;
      const response = await fetch(fileUrl);
      response.blob().then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `WFA Approval Mapping Import Template${extension}`;
        a.click();
      });
    } catch (e) {
      dispatch(setAlertErrorMessage(e));
    }
  },
  onImportSubmitPressed: async () => {
    await dispatch(importWfaApprovalMappingFileAsync())
      .catch((error) => dispatch(setAlertErrorMessage(error)));
  },
  onImportCancelPressed: () => {
    dispatch(setWfaApprovalMappingSelectedImportFile(''));
    dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE, ''));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(WfaApprovalMappingPage);

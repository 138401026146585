import { downloadWfaApprovalMappings, transformSearchText } from '../../../helper';
import { downloadingWfaApprovalMappings, setWfaApprovalMappings } from '../simple-action';

export default (selectedPageNo) => async (dispatch, getState) => {
  const { authentication, wfaApprovalMappings, uiWfaApprovalMapping } = getState();
  const { token } = authentication;
  const { meta } = wfaApprovalMappings;
  const {
    selectedPageSize, searchBarText, orderBy, filterString,
  } = uiWfaApprovalMapping;

  const searchText = transformSearchText(searchBarText);

  try {
    dispatch(downloadingWfaApprovalMappings(true));

    const pageToBeDownloaded = selectedPageNo || meta.currentPage + 1;
    const list = await downloadWfaApprovalMappings(
      pageToBeDownloaded,
      selectedPageSize,
      orderBy,
      searchText,
      filterString,
      token,
    );

    dispatch(setWfaApprovalMappings(list));
  } finally {
    dispatch(downloadingWfaApprovalMappings(false));
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogActions, DialogContent, DialogTitle, withStyles,
} from '@material-ui/core';
import LocalizedString from '../../../localization';
import {
  COLOR_DANGEROUS, COLOR_PRIMARY, COLOR_TEXT_ON_PRIMARY,
} from '../../../constant';
import AccentButton from '../../accent-button';

const styles = (() => ({
  paperWithStepper: {
    minHeight: '96vh',
  },
  paper: {
    transform: 'translateZ(0px)',
  },
  headerContainer: {
    flexDirection: 'row',
    display: 'flex',
    justifyContent: 'space-between',
    alignContent: 'center',
  },
  headerButtonText: {
    color: COLOR_DANGEROUS,
    marginRight: 20,
  },
  dialogContent: {
    paddingTop: 0,
    paddingBottom: 0,
    overflow: 'auto',
  },
  form: {
    alignItems: 'stretch',
    display: 'flex',
    flexDirection: 'column',
  },
  buttonContainer: {
    justifyContent: 'center',
  },
  button: {
    backgroundColor: COLOR_PRIMARY,
    color: COLOR_TEXT_ON_PRIMARY,
    margin: '15px 0px 10px 0px',
    borderRadius: 50,
    '&:hover': {
      backgroundColor: COLOR_PRIMARY,
      boxShadow: 'none',
    },
    '&:active': {
      boxShadow: 'none',
      backgroundColor: COLOR_PRIMARY,
    },
    '&:focus': {
      boxShadow: `0 0 0 0.2rem ${COLOR_PRIMARY}`,
    },
  },
  buttonText: {
    color: COLOR_DANGEROUS,
  },
}));

const ImportDialog = ({
  title, disabled, submitting, usefullWidthDialog, visibility,
  classes, children, headerContainerStyle, handleSubmit, onCancelPressed,
  onClosePressed, onError, onSubmitPressed,
}) => {
  const onCancelPress = () => {
    onCancelPressed();
    onClosePressed();
  };

  const onHandleSubmit = async (e) => {
    try {
      await onSubmitPressed(e);
      onClosePressed();
    } catch (error) {
      onError(error);
    }
  };

  return (
    <Dialog
      open={visibility}
      aria-labelledby="form-dialog-title"
      maxWidth="lg"
      fullWidth={usefullWidthDialog}
      classes={{ paper: classes.paper }}
    >
      <div className={`${classes.headerContainer} ${headerContainerStyle}`}>
        <DialogTitle>{title}</DialogTitle>
      </div>

      <DialogContent>
        <form
          onSubmit={handleSubmit(onHandleSubmit)}
          className={classes.form}
        >
          {children}
          <DialogActions className={classes.buttonContainer}>
            <AccentButton
              onClick={() => onCancelPress(onCancelPressed, onClosePressed)}
              variant="text"
              caption={LocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              disabled={disabled || submitting}
            />
            <AccentButton
              variant="contained"
              type="submit"
              className={classes.button}
              loading={submitting}
              disableElevation
              caption={LocalizedString.common.buttonCaptionAdd}
              disabled={disabled || submitting}
            />
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(ImportDialog);

ImportDialog.propTypes = {
  disabled: PropTypes.bool,
  submitting: PropTypes.bool.isRequired,
  usefullWidthDialog: PropTypes.bool,
  visibility: PropTypes.bool.isRequired,

  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func,
  onClosePressed: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,

  children: PropTypes.node,

  classes: PropTypes.object.isRequired,

  headerContainerStyle: PropTypes.string,
  title: PropTypes.string.isRequired,
};

ImportDialog.defaultProps = {
  disabled: false,
  usefullWidthDialog: false,
  onCancelPressed: () => {},
  children: (<div />),
  headerContainerStyle: '',
};

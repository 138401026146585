import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, InputAdornment, IconButton, Snackbar,
} from '@material-ui/core';
import { FileCopyOutlined } from '@material-ui/icons';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FunctionalPage } from '../../component';
import {
  approval, COLOR_BACKGROUND, COLOR_DISABLED_ROW, COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE,
  FILTER_TYPE_DATE_RANGE, PAGE_MODE_TABLE, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME,
  FILTER_TYPE_DROPDOWN, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, RXFIELD_VOUCHER_ACTION_TYPE,
  RXFIELD_VOUCHER_APPLICABLE_AREA_MODE, RXFIELD_VOUCHER_APPLICABLE_BRANCHES,
  RXFIELD_VOUCHER_CATEGORY, RXFIELD_VOUCHER_CODE, RXFIELD_VOUCHER_DEEP_LINK,
  RXFIELD_VOUCHER_DESCRIPTION, RXFIELD_VOUCHER_IMAGE, RXFIELD_VOUCHER_IS_PROMOTED,
  RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE, RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER,
  RXFIELD_VOUCHER_MERCHANT, RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE, RXFIELD_VOUCHER_ORDER,
  RXFIELD_VOUCHER_POINT, RXFIELD_VOUCHER_STATUS, RXFIELD_VOUCHER_TERMS_AND_CONDITIONS,
  RXFIELD_VOUCHER_TITLE, RXFIELD_VOUCHER_TYPE, RXFIELD_VOUCHER_VALID_FROM,
  RXFIELD_VOUCHER_VALID_UNTIL, RXFIELD_VOUCHER_VISIBILITY, RXFORM_VOUCHER, RXSTATE_VOUCHERS,
  RXSTATE_VOUCHER_PAGE, snackbarDuration, status, STATUS_DISABLED, STATUS_ENABLED,
  visibilityStatuses, VOUCHER_ACTION_TYPES, VOUCHER_APPLICABLE_AREA_MODES, VOUCHER_TYPES,
  RXFIELD_VOUCHER_TAGS,
} from '../../constant';
import { toMoment } from '../../helper';
import GlobalLocalizedString from '../../../../localization';
import LocalizedString from '../../localization';
import {
  renderReduxFormAutocompleteTableField, renderReduxFormDateTimePickerField,
  renderReduxFormImageInputField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormRichTextEditorField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { SimpleDataShape, FormInitialValueShape } from '../../type';
import { rxformValidateVoucher } from '../../validation';

const renderDialogContent = (initialValues, tappedVoucher, branches, merchants,
  tableDataApplicableBranches, voucherCategories, addingApplicableBranches, addingEditing, copying,
  downloadingDeleting, isPublicType, isSpecificBranches, loadingBranches, loadingMerchant,
  loadingVoucherCategory, onActionTypeOptionSelected, onAddApplicableBranchesPressed,
  onApplicableAreaModeOptionSelected, onApplicableBranchesOptionSelected, onCategoryOptionSelected,
  onChangeApplicableBranchesText, onChangeCategoryText, onChangeDescriptionText,
  onChangeMerchantText, onChangeTermsConditionsText, onCopyPressed,
  onDeleteApplicableBranchesPressed, onImageSelected, onMerchantOptionSelected,
  onStatusOptionSelected, onTypeOptionSelected, onVisibilityOptionSelected,
  selectedApplicableBranches, currentMerchant, selectedMerchantId, pageMode,
  onIsPromotedSelected, isPromoted) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderTitle}
              label={LocalizedString.voucherPage.placeholderTitle}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_IS_PROMOTED}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.voucherPage.placeholderPromoted}
              label={LocalizedString.voucherPage.placeholderPromoted}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.isPromoted}
              onOptionSelected={onIsPromotedSelected}
              required
              onBlur={(e) => e.preventDefault()}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_ORDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderOrder}
              label={LocalizedString.voucherPage.placeholderOrder}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={!isPromoted}
              type="number"
              required={isPromoted}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.voucherPage.placeholderType}
              label={LocalizedString.voucherPage.placeholderType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={VOUCHER_TYPES}
              value={initialValues.type}
              onOptionSelected={onTypeOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_CODE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderCode}
              label={LocalizedString.voucherPage.placeholderCode}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required={isPublicType}
              hidden={!isPublicType}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_POINT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderPoint}
              label={LocalizedString.voucherPage.placeholderPoint}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={isPublicType}
              required
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_MAX_PURCHASE_PER_USER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderMaxPurchasePerUser}
              label={LocalizedString.voucherPage.placeholderMaxPurchasePerUser}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_MIN_ACHIEVEMENT_POIN_TO_PURCHASE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderMinAchievementPoinToPurchase}
              label={LocalizedString.voucherPage.placeholderMinAchievementPoinToPurchase}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_MAX_ACHIEVEMENT_POIN_TO_PURCHASE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderMaxAchievementPoinToPurchase}
              label={LocalizedString.voucherPage.placeholderMaxAchievementPoinToPurchase}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_DEEP_LINK}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderDeepLink}
              label={LocalizedString.voucherPage.placeholderDeepLink}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              multiline
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                      <CopyToClipboard text={initialValues.deepLink}>
                        <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                      </CopyToClipboard>
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_ACTION_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.voucherPage.placeholderActionType}
              label={LocalizedString.voucherPage.placeholderActionType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={VOUCHER_ACTION_TYPES}
              value={initialValues.actionType}
              onOptionSelected={onActionTypeOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_VISIBILITY}
              component={renderReduxFormSimpleDropdownField}
              data={visibilityStatuses}
              value={initialValues.visibility}
              onOptionSelected={onVisibilityOptionSelected}
              placeholder={LocalizedString.voucherPage.placeholderVisibility}
              label={LocalizedString.voucherPage.placeholderVisibility}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_VALID_FROM}
              component={renderReduxFormDateTimePickerField}
              pickerMode={PICKER_MODE_DATE_TIME}
              label={LocalizedString.voucherPage.placeholderValidFrom}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.voucherPage.placeholderValidUntil}
              pickerMode={PICKER_MODE_DATE_TIME}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_TAGS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.voucherPage.placeholderTags}
              label={LocalizedString.voucherPage.placeholderTags}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_STATUS}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.voucherPage.placeholderStatus}
              label={LocalizedString.voucherPage.placeholderStatus}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={status}
              value={initialValues.status}
              onOptionSelected={onStatusOptionSelected}
              hidden={pageMode !== PAGE_MODE_TABLE}
              required={pageMode === PAGE_MODE_TABLE}
            />
          </Grid>

          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_IMAGE}
              component={renderReduxFormImageInputField}
              label={LocalizedString.voucherPage.placeholderImage}
              onImageSelected={onImageSelected}
              onBlur={(e) => e.preventDefault()}
              defaultValue={initialValues.image}
              useFullWidthImage
              useAvatarWithoutCropper
              fullImageWidth="100%"
              useCropper={false}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm={12}>
          <Field
            name={RXFIELD_VOUCHER_DESCRIPTION}
            component={renderReduxFormRichTextEditorField}
            label={LocalizedString.voucherPage.placeholderDescription}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onChangeText={onChangeDescriptionText}
            loading={downloadingDeleting}
            required
          />
        </Grid>

        <Grid item sm={12}>
          <Field
            name={RXFIELD_VOUCHER_TERMS_AND_CONDITIONS}
            component={renderReduxFormRichTextEditorField}
            label={LocalizedString.voucherPage.placeholderTermsAndConditions}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onChangeText={onChangeTermsConditionsText}
            loading={downloadingDeleting}
            // required
          />
        </Grid>
      </Grid>

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_MERCHANT}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.voucherPage.placeholderMerchant}
              label={LocalizedString.voucherPage.placeholderMerchant}
              data={merchants}
              loading={loadingMerchant}
              value={initialValues.merchant.label}
              onChangeText={onChangeMerchantText}
              onOptionSelected={(option) => onMerchantOptionSelected(option, selectedMerchantId,
                tappedVoucher)}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              onBlur={(e) => e.preventDefault()}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_APPLICABLE_AREA_MODE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.voucherPage.placeholderApplicableAreaMode}
              label={LocalizedString.voucherPage.placeholderApplicableAreaMode}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={VOUCHER_APPLICABLE_AREA_MODES}
              value={initialValues.applicableAreaMode}
              onOptionSelected={onApplicableAreaModeOptionSelected}
              required
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_VOUCHER_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.voucherPage.placeholderCategory}
              label={LocalizedString.voucherPage.placeholderCategory}
              data={voucherCategories}
              loading={loadingVoucherCategory}
              value={initialValues.category.label}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Field
          name={RXFIELD_VOUCHER_APPLICABLE_BRANCHES}
          component={renderReduxFormAutocompleteTableField}
          label={LocalizedString.voucherPage.placeholderApplicableBranches}
          currentTableData={tableDataApplicableBranches}
          defaultValue={initialValues.applicableBranches}
          options={branches}
          adding={addingApplicableBranches}
          disableAdd={pageMode === PAGE_MODE_VIEW}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          hidden={!isSpecificBranches}
          loadingOptions={loadingBranches}
          onAddPressed={(defaultValue, newInitialValue, selectedOption,
            currentData) => onAddApplicableBranchesPressed(defaultValue, newInitialValue,
            selectedOption, currentData, currentMerchant)}
          onChangeOptionText={(text) => onChangeApplicableBranchesText(text, currentMerchant)}
          onDeletePressed={(newValue, currentData) => onDeleteApplicableBranchesPressed(
            newValue, currentData, currentMerchant,
          )}
          onOptionSelected={onApplicableBranchesOptionSelected}
          selectedOption={selectedApplicableBranches}
          optionPlaceholder={LocalizedString.voucherPage.labelBranch}
          currentTappedData={tappedVoucher}
          onBlur={(e) => e.preventDefault()}
          required={isSpecificBranches}
        />
      </Grid>

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.voucherPage.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
);

const VoucherPage = ({
  initialValues, tappedVoucher, branches, merchants, tableDataApplicableBranches, voucherCategories,
  addingApplicableBranches, addingEditing, copying, downloading, downloadingDeleting,
  enablingDisabling, isPublicType, isSpecificBranches, loadingBranches, loadingMerchant,
  loadingVoucherCategory,
  handleSubmit, onActionTypeOptionSelected, onAddApplicableBranchesPressed, onAdvancedFilterPressed,
  onAppear, onApplicableAreaModeOptionSelected, onApplicableBranchesOptionSelected,
  onApplyAdvancedFilterPressed, onCancelPressed, onCategoryOptionSelected,
  onChangeApplicableBranchesText, onChangeCategoryText, onChangeDescriptionText,
  onChangeMerchantText, onChangePage, onChangePageSize, onChangeTermsConditionsText,
  onConfirmPressed, onCopyPressed, onCreatePressed, onDeleteApplicableBranchesPressed,
  onEditPressed, onEnableDisablePressed, onImageSelected, onMerchantOptionSelected, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onStatusOptionSelected, onSubmitPressed, onTypeOptionSelected, onViewPressed,
  onVisibilityOptionSelected, isPromoted,
  vouchers, selectedApplicableBranches, onIsPromotedSelected,
  currentMerchant, currentVoucherStatus, pageMode, selectedMerchantId, tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_VOUCHERS}
    uiPage={RXSTATE_VOUCHER_PAGE}
    moreMenus={[
      {
        caption: GlobalLocalizedString.common.buttonCaptionEnable,
        disabled: currentVoucherStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onEnableDisablePressed(tappedId,
          GlobalLocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: GlobalLocalizedString.common.buttonCaptionDisable,
        disabled: currentVoucherStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onEnableDisablePressed(tappedId,
          GlobalLocalizedString.common.msgDisableConfirmation),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.voucherPage.placeholderValidFrom,
        field: RXFIELD_VOUCHER_VALID_FROM,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherPage.placeholderValidUntil,
        field: RXFIELD_VOUCHER_VALID_UNTIL,
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
      {
        title: LocalizedString.voucherPage.placeholderType,
        field: RXFIELD_VOUCHER_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: VOUCHER_TYPES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPage.placeholderActionType,
        field: RXFIELD_VOUCHER_ACTION_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: VOUCHER_ACTION_TYPES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPage.placeholderVisibility,
        field: RXFIELD_VOUCHER_VISIBILITY,
        type: FILTER_TYPE_DROPDOWN,
        data: visibilityStatuses,
      },
      {
        title: LocalizedString.voucherPage.placeholderMerchant,
        field: RXFIELD_VOUCHER_MERCHANT,
        type: FILTER_TYPE_DROPDOWN,
        data: merchants,
        loading: loadingMerchant,
        onChangeFilterText: onChangeMerchantText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPage.placeholderApplicableAreaMode,
        field: RXFIELD_VOUCHER_APPLICABLE_AREA_MODE,
        type: FILTER_TYPE_DROPDOWN,
        data: VOUCHER_APPLICABLE_AREA_MODES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPage.placeholderCategory,
        field: RXFIELD_VOUCHER_CATEGORY,
        type: FILTER_TYPE_DROPDOWN,
        data: voucherCategories,
        loading: loadingVoucherCategory,
        onChangeFilterText: onChangeCategoryText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.voucherPage.placeholderStatus,
        field: RXFIELD_VOUCHER_STATUS,
        type: FILTER_TYPE_DROPDOWN,
        data: status,
        useDropdownValue: true,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.voucherPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.voucherPage.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.voucherPage.labelPoint, field: 'point', sorting: !downloading },
      { title: LocalizedString.voucherPage.labelVisibility, field: 'visibility', sorting: !downloading },
      { title: LocalizedString.voucherPage.labelMerchantName, field: 'merchant.name', sorting: !downloading },
      {
        title: LocalizedString.voucherPage.labelValidFrom,
        field: 'validFrom',
        sorting: !downloading,
        render: ({ validFrom }) => (validFrom
          ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      {
        title: LocalizedString.voucherPage.labelValidUntil,
        field: 'validUntil',
        sorting: !downloading,
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
      { title: LocalizedString.voucherPage.labelApplicableMode, field: 'applicableAreaMode', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmPressed}
    onCreatePressed={onCreatePressed}
    onEditPressed={(id) => onEditPressed(id, vouchers)}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.voucherPage.buttonCaptionCreateNewVoucher}
    editButtonCaption={LocalizedString.voucherPage.buttonCaptionEditVoucher}
    title={LocalizedString.voucherPage.title}
    useFullWidth
    disableDelete
    rowStyle={({ status: itemStatus, validUntil }) => {
      const currDate = toMoment().format(DATE_TIME_FORMAT_WITHOUT_PIPE);
      const validDate = toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE);

      return ({
        backgroundColor: itemStatus === STATUS_DISABLED || toMoment(validDate).isBefore(currDate)
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      });
    }}
    createPermissionName="VOUCHER_CREATE_VOUCHER"
    editPermissionName="VOUCHER_EDIT_VOUCHER"
    savePermissionName="VOUCHER_GET_VOUCHER"
  >
    {renderDialogContent(initialValues, tappedVoucher, branches, merchants,
      tableDataApplicableBranches, voucherCategories, addingApplicableBranches, addingEditing,
      copying, downloadingDeleting, isPublicType, isSpecificBranches, loadingBranches,
      loadingMerchant, loadingVoucherCategory, onActionTypeOptionSelected,
      onAddApplicableBranchesPressed, onApplicableAreaModeOptionSelected,
      onApplicableBranchesOptionSelected, onCategoryOptionSelected,
      onChangeApplicableBranchesText, onChangeCategoryText, onChangeDescriptionText,
      onChangeMerchantText, onChangeTermsConditionsText, onCopyPressed,
      onDeleteApplicableBranchesPressed, onImageSelected, onMerchantOptionSelected,
      onStatusOptionSelected, onTypeOptionSelected, onVisibilityOptionSelected,
      selectedApplicableBranches, currentMerchant, selectedMerchantId, pageMode,
      onIsPromotedSelected, isPromoted)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOUCHER,
  validate: rxformValidateVoucher,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(VoucherPage);

VoucherPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  tappedVoucher: FormInitialValueShape,
  branches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  merchants: PropTypes.arrayOf(SimpleDataShape).isRequired,
  tableDataApplicableBranches: PropTypes.arrayOf(SimpleDataShape).isRequired,
  voucherCategories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingApplicableBranches: PropTypes.bool.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  isPublicType: PropTypes.bool.isRequired,
  isSpecificBranches: PropTypes.bool.isRequired,
  loadingBranches: PropTypes.bool.isRequired,
  loadingMerchant: PropTypes.bool.isRequired,
  loadingVoucherCategory: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onActionTypeOptionSelected: PropTypes.func.isRequired,
  onAddApplicableBranchesPressed: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplicableAreaModeOptionSelected: PropTypes.func.isRequired,
  onApplicableBranchesOptionSelected: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onChangeApplicableBranchesText: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangeDescriptionText: PropTypes.func.isRequired,
  onChangeMerchantText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeTermsConditionsText: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteApplicableBranchesPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onMerchantOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onStatusOptionSelected: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTypeOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityOptionSelected: PropTypes.func.isRequired,
  vouchers: PropTypes.objectOf(PropTypes.object).isRequired,
  selectedApplicableBranches: PropTypes.oneOfType([SimpleDataShape, PropTypes.string]),
  currentMerchant: PropTypes.string.isRequired,
  currentVoucherStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedMerchantId: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  onIsPromotedSelected: PropTypes.func.isRequired,
  isPromoted: PropTypes.bool.isRequired,
};

VoucherPage.defaultProps = {
  tappedVoucher: {},
  selectedApplicableBranches: null,
};

import { addPersonalHoliday, downloadingPersonalHoliday } from '../simple-action';
import { downloadPersonalHoliday } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingPersonalHoliday(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiPersonalHoliday;

    const result = await downloadPersonalHoliday(tappedId, token);
    dispatch(addPersonalHoliday(result));
  } finally {
    dispatch(downloadingPersonalHoliday(false));
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Grid } from '@material-ui/core';
import { Field, reduxForm } from 'redux-form';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_VOUCHER_CATEGORY_DESCRIPTION, RXFIELD_VOUCHER_CATEGORY_NAME,
  RXFORM_VOUCHER_CATEGORY, RXSTATE_VOUCHER_CATEGORIES, RXSTATE_VOUCHER_CATEGORY_PAGE,
  RXFIELD_VOUCHER_CATEGORY_IMAGE,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedTextField, renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { rxformValidateVoucherCategory } from '../../validation';
import { FormInitialValueShape } from '../../type';

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode, onImageSelected, initialImage,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_CATEGORY_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherCategoryScreen.labelName}
          label={LocalizedString.voucherCategoryScreen.labelName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          required
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_CATEGORY_IMAGE}
          component={renderReduxFormImageInputField}
          label={LocalizedString.voucherCategoryScreen.placeholderImage}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          onImageSelected={onImageSelected}
          onBlur={(e) => e.preventDefault()}
          defaultValue={initialImage}
          useFullWidthImage
          useAvatarWithoutCropper
          fullImageWidth="100%"
          useCropper={false}
          required
        />
      </Grid>
    </Grid>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_VOUCHER_CATEGORY_DESCRIPTION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.voucherCategoryScreen.labelDescription}
          label={LocalizedString.voucherCategoryScreen.labelDescription}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW || downloadingDeleting}
          multiline
          required
        />
      </Grid>
    </Grid>
  </Grid>
);

const VoucherCategoryPage = ({
  addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage,
  onChangePageSize, onCreatePressed, onEditPressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed, onDeletePressed, onConfirmDeletePressed, pageMode,
  onImageSelected, initialValues,
}) => (
  <FunctionalPage
    data={RXSTATE_VOUCHER_CATEGORIES}
    uiPage={RXSTATE_VOUCHER_CATEGORY_PAGE}
    tableColumns={[
      {
        title: LocalizedString.voucherCategoryScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.voucherCategoryScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.voucherCategoryScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onCreatePressed={onCreatePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onDeletePressed={onDeletePressed}
    createNewButtonCaption={LocalizedString.voucherCategoryScreen.buttonCaptionCreate}
    deleteButtonCaption={LocalizedString.voucherCategoryScreen.buttonCaptionDelete}
    editButtonCaption={LocalizedString.voucherCategoryScreen.buttonCaptionEdit}
    title={LocalizedString.voucherCategoryScreen.title}
    useFullWidth
    createPermissionName="VOUCHER_CREATE_CATEGORY"
    deletePermissionName="VOUCHER_DELETE_CATEGORY"
    editPermissionName="VOUCHER_EDIT_CATEGORY"
  >
    {renderDialogContent(
      addingEditing, downloadingDeleting, pageMode, onImageSelected, initialValues.image,
    )}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_VOUCHER_CATEGORY,
  validate: rxformValidateVoucherCategory,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(VoucherCategoryPage);
VoucherCategoryPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  onAppear: PropTypes.func.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onImageSelected: PropTypes.func.isRequired,
};

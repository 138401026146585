import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Avatar, CircularProgress, Grid, makeStyles, Snackbar, TextField,
} from '@material-ui/core';
import {
  AccentButton, Comment, FunctionalPage, ImageInputField, NumberTextFieldWithoutArrowDial,
  SectionTitle, SendNotificationDialog,
} from '../../component';
import {
  COLOR_PRIMARY, DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, FILTER_TYPE_NUMBER_RANGE, FILTER_TYPE_SWITCH, IMAGE_SOURCE_URI_PREFIX,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, PICKER_MODE_DATE_TIME, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  REST_BASE_URL, REST_URL_IMAGE_STORAGE, RXFIELD_ARTICLES_AUTHOR, RXFIELD_ARTICLES_CATEGORY,
  RXFIELD_ARTICLES_CONTENT, RXFIELD_ARTICLES_DISCUSSION_ID, RXFIELD_ARTICLES_MEDIA,
  RXFIELD_ARTICLES_PUBLISHED_DATE, RXFIELD_ARTICLES_REACTION_ID, RXFIELD_ARTICLES_SHORT_URL,
  RXFIELD_ARTICLES_TITLE, RXFIELD_ARTICLES_VIEW_COUNT, RXFIELD_ARTICLES_VISIBILITY_STATUS,
  snackbarDuration, visibilityStatuses, RXSTATE_ARTICLES, RXSTATE_ARTICLE_PAGE, RXFORM_ARTICLES,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField,
  renderReduxFormRichTextEditorField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateArticles } from '../../validation';

const useStyles = makeStyles(() => ({
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  italicButton: {
    fontStyle: 'italic',
    color: COLOR_PRIMARY,
    marginRight: 10,
  },
}));

const renderDialogContent = (initialValues, categories, addingEditing, copying, downloadingDeleting,
  loadingCategory, onAddMediaPressed, onAddMediaMenuSelected, onCategoryOptionSelected,
  onChangeCategoryText, onChangeContentText, onCopyPressed,
  onDeleteMediaPressed, onVisibilityStatusOptionSelected,
  discusId, pageMode, selectedAddMediaMenu) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item xs sm md>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.articlesScreen.placeholderTitle}
              label={LocalizedString.articlesScreen.placeholderTitle}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_PUBLISHED_DATE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.articlesScreen.placeholderPublishedDate}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={PICKER_MODE_DATE_TIME}
              disableFuture
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.articlesScreen.placeholderCategory}
              label={LocalizedString.articlesScreen.placeholderCategory}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={categories}
              value={initialValues.category.label}
              loading={loadingCategory}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_VISIBILITY_STATUS}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.articlesScreen.placeholderVisibilityStatus}
              label={LocalizedString.articlesScreen.placeholderVisibilityStatus}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={visibilityStatuses}
              value={initialValues.visibilityStatus}
              onOptionSelected={onVisibilityStatusOptionSelected}
              required
            />
          </Grid>
        </Grid>

        <Grid item xs sm md>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_VIEW_COUNT}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.articlesScreen.placeholderViewCount}
              label={LocalizedString.articlesScreen.placeholderViewCount}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_SHORT_URL}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.articlesScreen.placeholderShortUrl}
              label={LocalizedString.articlesScreen.placeholderShortUrl}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_REACTION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.articlesScreen.placeholderReactionId}
              label={LocalizedString.articlesScreen.placeholderReactionId}
              disabled
              multiline
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_DISCUSSION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.articlesScreen.placeholderDiscussionId}
              label={LocalizedString.articlesScreen.placeholderDiscussionId}
              disabled
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_ARTICLES_AUTHOR}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.articlesScreen.placeholderAuthor}
              label={LocalizedString.articlesScreen.placeholderAuthor}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
              required
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Field
          name={RXFIELD_ARTICLES_CONTENT}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.articlesScreen.placeholderContent}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          onChangeText={onChangeContentText}
          loading={downloadingDeleting}
          required
        />
      </Grid>

      <SectionTitle title={LocalizedString.articlesScreen.labelMedia} />

      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_ARTICLES_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.articlesScreen.labelMedia}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={initialValues.media}
            addMenuList={[
              {
                caption: LocalizedString.articlesScreen.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.articlesScreen.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.articlesScreen.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.articlesScreen.labelOrder}
                    label={LocalizedString.articlesScreen.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                    required
                  />
                ),
              },
              {
                title: LocalizedString.articlesScreen.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.articlesScreen.labelYoutube}
                    label={LocalizedString.articlesScreen.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.articlesScreen.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ onChange }) => (
                  <ImageInputField
                    useCropper={false}
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}

      {pageMode !== PAGE_MODE_TABLE && (<Comment discusId={discusId} reactionMode="updown" />)}

      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.articlesScreen.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>
);

const ArticlesPage = ({
  initialValues, categories, addingEditing, copying, downloading, downloadingDeleting,
  loadingCategory, sendingNotification, sendingNotifications,
  handleSubmit, onAddMediaPressed, onAddMediaMenuSelected, onAdvancedFilterPressed, onAppear,
  onApplyAdvancedFilterPressed, onCancelAdvancedFilterPressed, onCancelPressed,
  onCategoryOptionSelected, onChangeCategoryText, onChangeContentText, onChangePage,
  onChangePageSize, onConfirmDeletePressed, onCopyPressed,
  onCreatePressed, onDeleteMediaPressed, onDeletePressed, onEditPressed,
  onOpenSendNotificationDialog, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSendToMePressed, onSendNotificationsPressed, onSortPressed,
  onSubmitPressed, onViewPressed, onVisibilityStatusOptionSelected,
  discusId, pageMode, selectedAddMediaMenu, onDownloadPressed,
}) => {
  const classes = useStyles();

  return (
    <div>
      {pageMode === PAGE_MODE_VIEW && (
      <SendNotificationDialog
        initialValues={initialValues.notification}
        loading={sendingNotification}
        useFilterSection
        recipient={initialValues.title}
        sendingToLabel={LocalizedString.articlesScreen.labelSendNotificationTo}
        disabledUrlField
        additionalUrlPlaceholder={GlobalLocalizedString.common.labelAdditionalUrlPlaceholders}
      />
      )}

      <FunctionalPage
        data={RXSTATE_ARTICLES}
        uiPage={RXSTATE_ARTICLE_PAGE}
        filterColumns={[
          {
            title: LocalizedString.articlesScreen.labelPublishedDate, field: 'publishedDate', type: FILTER_TYPE_DATE_RANGE, pickerMode: PICKER_MODE_DATE_TIME, format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
          {
            title: LocalizedString.articlesScreen.labelCategory,
            field: 'categoryName',
            type: FILTER_TYPE_DROPDOWN,
            data: categories,
            loading: loadingCategory,
            onChangeFilterText: onChangeCategoryText,
            onFilterOptionSelected: onCategoryOptionSelected,
          },
          {
            title: LocalizedString.articlesScreen.labelStatus, field: 'visibilityStatus', type: FILTER_TYPE_DROPDOWN, data: visibilityStatuses,
          },
          { title: LocalizedString.articlesScreen.labelViewCount, field: 'viewCount', type: FILTER_TYPE_NUMBER_RANGE },
          { title: LocalizedString.articlesScreen.labelHeadline, field: 'isHeadline', type: FILTER_TYPE_SWITCH },
        ]}
        tableColumns={[
          {
            title: LocalizedString.articlesScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
          },
          { title: LocalizedString.articlesScreen.labelTitle, field: 'title', sorting: !downloading },
          { title: LocalizedString.articlesScreen.labelCategory, field: 'category.name', sorting: !downloading },
          {
            title: LocalizedString.articlesScreen.labelPublishedDate,
            field: 'publishedDate',
            render: ({ publishedDate }) => (
              toMoment(publishedDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)),
            sorting: !downloading,
          },
          { title: LocalizedString.articlesScreen.labelViewCount, field: 'viewCount', sorting: !downloading },
        ]}
        handleSubmit={handleSubmit}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onAppear={onAppear}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onConfirmDeletePressed={onConfirmDeletePressed}
        onCreatePressed={onCreatePressed}
        onDeletePressed={onDeletePressed}
        onEditPressed={onEditPressed}
        onOpenSendNotificationDialog={onOpenSendNotificationDialog}
        onRefresh={onRefresh}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        onSavePressed={onSavePressed}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSendToMePressed={onSendToMePressed}
        onSortPressed={onSortPressed}
        onSubmitPressed={onSubmitPressed}
        onViewPressed={onViewPressed}
        onDownloadPressed={onDownloadPressed}
        createNewButtonCaption={LocalizedString.articlesScreen.buttonCaptionCreateNewArticle}
        deleteButtonCaption={LocalizedString.articlesScreen.buttonCaptionDeleteArticle}
        editButtonCaption={LocalizedString.articlesScreen.buttonCaptionEditArticle}
        title={LocalizedString.articlesScreen.title}
        useFullWidth
        enableSave
        useSendToMeAndUserNotification
        createPermissionName="KNOWLEDGEMANAGEMENT_CREATE_ARTICLE"
        editPermissionName="KNOWLEDGEMANAGEMENT_EDIT_ARTICLE"
        deletePermissionName="KNOWLEDGEMANAGEMENT_DELETE_ARTICLE"
        savePermissionName="KNOWLEDGEMANAGEMENT_GET_ARTICLE"
        sendNotificationPermissionName="CORE_SEND_SIMPLE_NOTIFICATION"
      >
        {pageMode === PAGE_MODE_VIEW && (
        <div className={classes.buttonContainer}>
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing || sendingNotifications}
            loading={sendingNotification}
            disableElevation
            caption={LocalizedString.articlesScreen.buttonCaptionSendToMe}
            onClick={onSendToMePressed}
            size="small"
          />
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing || sendingNotification}
            loading={sendingNotifications}
            disableElevation
            caption={LocalizedString.articlesScreen.buttonCaptionSendToUsers}
            onClick={onSendNotificationsPressed}
            size="small"
          />
        </div>
        )}

        {renderDialogContent(initialValues, categories, addingEditing, copying, downloadingDeleting,
          loadingCategory, onAddMediaPressed, onAddMediaMenuSelected, onCategoryOptionSelected,
          onChangeCategoryText, onChangeContentText, onCopyPressed,
          onDeleteMediaPressed, onVisibilityStatusOptionSelected,
          discusId, pageMode, selectedAddMediaMenu)}
      </FunctionalPage>
    </div>
  );
};

export default reduxForm({
  form: RXFORM_ARTICLES,
  validate: rxformValidateArticles,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ArticlesPage);

ArticlesPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  categories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCategory: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  sendingNotifications: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangeContentText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onOpenSendNotificationDialog: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSendNotificationsPressed: PropTypes.func.isRequired,
  onSendToMePressed: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  discusId: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedAddMediaMenu: PropTypes.string,
};

ArticlesPage.defaultProps = {
  selectedAddMediaMenu: null,
};

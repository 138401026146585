import moment from 'moment';
import {
  exportingAttendanceAddonSummary,
} from '../simple-action';
import { FILE_EXTENSION_PDF, REVERSED_ISO_DATE_FORMAT } from '../../../constant';
import { exportAttendanceAddonSummary } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(exportingAttendanceAddonSummary(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedYear, selectedMonth,
    } = getState().uiAttendanceAddonSummary;

    const month = moment.months().indexOf(selectedMonth);
    const startDate = moment([selectedYear, month]).startOf('month')
      .format(REVERSED_ISO_DATE_FORMAT);
    const endDate = moment([selectedYear, month]).endOf('month')
      .format(REVERSED_ISO_DATE_FORMAT);
    const userId = tappedId || '';

    const response = await exportAttendanceAddonSummary(
      startDate, endDate, tappedId, token,
    );

    response.blob().then((blob) => {
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${userId}${FILE_EXTENSION_PDF}`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    });
  } finally {
    dispatch(exportingAttendanceAddonSummary(false));
  }
};

import {
  DOWNLOADING_ATTENDANCE_ADDON_SUMMARY,
  SET_ATTENDANCE_ADDON_SUMMARY_TAPPED_ID,
  EXPORTING_ATTENDANCE_ADDON_SUMMARY,
  SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_MONTH,
  SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_YEAR,
} from '../action/simple-action';

const initialState = {
  downloading: false,
  exporting: false,
  tappedId: '',
  selectedMonth: '',
  selectedYear: 0,
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_ATTENDANCE_ADDON_SUMMARY:
      return { ...state, downloading: action.status };
    case SET_ATTENDANCE_ADDON_SUMMARY_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case EXPORTING_ATTENDANCE_ADDON_SUMMARY:
      return { ...state, exporting: action.status };
    case SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_MONTH:
      return { ...state, selectedMonth: action.value };
    case SET_ATTENDANCE_ADDON_SUMMARY_SELECTED_YEAR:
      return { ...state, selectedYear: action.value };
    default:
      return state;
  }
};

import { reset } from 'redux-form';
import { PAGE_MODE_TABLE, RXFORM_WFA_APPROVAL_MAPPING } from '../../../constant';
import { addWfaApprovalMapping, editWfaApprovalMapping } from '../../../helper';
import {
  addingEditingWfaApprovalMapping, clearWfaApprovalMappings, setAlertErrorMessage,
  setFunctionalPageMode,
} from '../simple-action';
import downloadWfaApprovalMappingsAsync from './downloadWfaApprovalMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(addingEditingWfaApprovalMapping(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedApprover, selectedDepartment, selectedType, selectedUser,
    } = getState().uiWfaApprovalMapping;

    if (tappedId) {
      await editWfaApprovalMapping(tappedId, selectedType, selectedUser?.value,
         selectedDepartment?.label, selectedApprover.value, token);
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
    } else {
      await addWfaApprovalMapping(selectedType, selectedUser?.value, selectedDepartment?.label,
        selectedApprover.value, token);
    }

    dispatch(reset(RXFORM_WFA_APPROVAL_MAPPING));
    dispatch(clearWfaApprovalMappings());
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(addingEditingWfaApprovalMapping(false));
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Divider, Grid, makeStyles } from '@material-ui/core';
import { FunctionalPage } from '../../../../component';
import {
  approval, COLOR_BACKGROUND, COLOR_DISABLED_ROW, COLOR_PRIMARY, ENUM_FIELD_DATE,
  ENUM_FIELD_DATE_TIME, ENUM_FIELD_FLOAT, ENUM_FIELD_INTEGER, ENUM_FIELD_OPTIONS, ENUM_FIELD_PHOTO,
  ENUM_FIELD_PICKER, ENUM_FIELD_TEXT, ENUM_FIELD_TIME, FIELD_TYPES, FILTER_TYPE_DROPDOWN,
  FILTER_TYPE_SWITCH, KEYBOARD_TYPES, OPTION_MODES, OPTION_VALUE_SEPARATORS,
  PAGE_MODE_TABLE, PAGE_MODE_VIEW, PICKER_MODE_DATE, PICKER_MODE_DATE_TIME, PICKER_MODE_TIME,
  PICKER_MODES, RXFIELD_QUIZ_QUESTION_CLEAR_IMAGE, RXFIELD_QUIZ_QUESTION_DATE_TIME_MAX_VALUE,
  RXFIELD_QUIZ_QUESTION_DATE_TIME_MIN_VALUE,
  RXFIELD_QUIZ_QUESTION_FLOAT_MAX_VALUE, RXFIELD_QUIZ_QUESTION_FLOAT_MIN_VALUE,
  RXFIELD_QUIZ_QUESTION_INT_MAX_VALUE, RXFIELD_QUIZ_QUESTION_INT_MIN_VALUE,
  RXFIELD_QUIZ_QUESTION_LABEL, RXFIELD_QUIZ_QUESTION_MUST_USE_CAMERA,
  RXFIELD_QUIZ_QUESTION_NAME, RXFIELD_QUIZ_QUESTION_FIELD_TYPE,
  RXFIELD_QUIZ_QUESTION_OPTION_MODE, RXFIELD_QUIZ_QUESTION_OPTION_VALUE_SEPARATOR,
  RXFIELD_QUIZ_QUESTION_OPTION_VALUES, RXFIELD_QUIZ_QUESTION_ORDER,
  RXFIELD_QUIZ_QUESTION_PICKER_MODE,
  RXFIELD_QUIZ_QUESTION_SECTION_ID,
  RXFIELD_QUIZ_QUESTION_SECTION_LABEL,
  RXFIELD_QUIZ_QUESTION_IMAGE,
  RXFIELD_QUIZ_QUESTION_QUIZ, RXFIELD_QUIZ_QUESTION_TEXT_KEYBOARD_TYPE,
  RXFIELD_QUIZ_QUESTION_TEXT_MAX_LENGTH, RXFIELD_QUIZ_QUESTION_TEXT_MIN_LENGTH,
  RXFIELD_QUIZ_QUESTION_TEXT_MULTILINE, RXFIELD_QUIZ_QUESTION_TEXT_REGEX_VALIDATION,
  RXFIELD_QUIZ_QUESTION_SCORE, RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER,
  RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER_LOGICAL_OPERATOR,
  RXSTATE_QUIZ_QUESTION_PAGE, RXSTATE_QUIZ_QUESTIONS, RXFORM_QUIZ_QUESTION,
  LOGICAL_OPERATOR,
  STATUS_DISABLED, STATUS_ENABLED, status,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormDateTimePickerField, renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField, renderReduxFormSimpleDropdownField, renderReduxFormSwitchField,
  renderReduxFormImageInputField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape } from '../../../../type';
import { rxformValidateQuizQuestion } from '../../validation';

const useStyles = makeStyles(() => ({
  divider: {
    height: 2,
    margin: '25px 0px 25px 0px',
    background: COLOR_PRIMARY,
  },
}));

const renderQuizQuestion = (initialValues, addingEditing, downloadingDeleting,
  onMustUseCameraOptionSelected, onOptionModeOptionSelected, onOptionValueSeparatorOptionSelected,
  onPickerModeOptionSelected, onTextKeyboardTypeOptionSelected, onTextMultilineOptionSelected,
  pageMode, selectedFieldType) => {
  const datePickerMode = selectedFieldType === ENUM_FIELD_DATE ? PICKER_MODE_DATE
    : PICKER_MODE_TIME;
  const dateTimePickerMode = selectedFieldType === ENUM_FIELD_DATE_TIME ? PICKER_MODE_DATE_TIME
    : datePickerMode;

  switch (selectedFieldType) {
    case ENUM_FIELD_DATE:
    case ENUM_FIELD_DATE_TIME:
    case ENUM_FIELD_TIME:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_DATE_TIME_MIN_VALUE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.quizQuestionScreen.placeholderDateTimeMinValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={dateTimePickerMode}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_DATE_TIME_MAX_VALUE}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.quizQuestionScreen.placeholderDateTimeMaxValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              pickerMode={dateTimePickerMode}
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_TEXT:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_TEXT_MULTILINE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderTextMultiline}
              label={LocalizedString.quizQuestionScreen.placeholderTextMultiline}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.textMultiline}
              onOptionSelected={onTextMultilineOptionSelected}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_TEXT_KEYBOARD_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderTextKeyboardType}
              label={LocalizedString.quizQuestionScreen.placeholderTextKeyboardType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={KEYBOARD_TYPES}
              value={initialValues.textKeyboardType}
              onOptionSelected={onTextKeyboardTypeOptionSelected}
              required={selectedFieldType === ENUM_FIELD_TEXT}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_TEXT_MIN_LENGTH}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderTextMinLength}
              label={LocalizedString.quizQuestionScreen.placeholderTextMinLength}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_TEXT_MAX_LENGTH}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderTextMaxLength}
              label={LocalizedString.quizQuestionScreen.placeholderTextMaxLength}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_TEXT_REGEX_VALIDATION}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderTextRegexValidation}
              label={LocalizedString.quizQuestionScreen.placeholderTextRegexValidation}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_INTEGER:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_INT_MIN_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderIntMinValue}
              label={LocalizedString.quizQuestionScreen.placeholderIntMinValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_INT_MAX_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderIntMaxValue}
              label={LocalizedString.quizQuestionScreen.placeholderIntMaxValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_FLOAT:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_FLOAT_MIN_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderFloatMinValue}
              label={LocalizedString.quizQuestionScreen.placeholderFloatMinValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_FLOAT_MAX_VALUE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderFloatMaxValue}
              label={LocalizedString.quizQuestionScreen.placeholderFloatMaxValue}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_PICKER:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_PICKER_MODE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderPickerMode}
              label={LocalizedString.quizQuestionScreen.placeholderPickerMode}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={PICKER_MODES}
              value={initialValues.pickerMode}
              onOptionSelected={onPickerModeOptionSelected}
              required={selectedFieldType === ENUM_FIELD_PICKER}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_OPTION_VALUES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderOptionValues}
              label={LocalizedString.quizQuestionScreen.labelOptionValues}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              required={selectedFieldType === ENUM_FIELD_PICKER}
              secondaryHelperText={LocalizedString.quizQuestionScreen.labelOptionValuesHelper}
              useHelperIcon
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_OPTIONS:
      return (
        <div>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_OPTION_MODE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderOptionMode}
              label={LocalizedString.quizQuestionScreen.placeholderOptionMode}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={OPTION_MODES}
              value={initialValues.optionMode}
              onOptionSelected={onOptionModeOptionSelected}
              required={selectedFieldType === ENUM_FIELD_OPTIONS}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_OPTION_VALUES}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderOptionValues}
              label={LocalizedString.quizQuestionScreen.labelOptionValues}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              required={selectedFieldType === ENUM_FIELD_OPTIONS}
              secondaryHelperText={LocalizedString.quizQuestionScreen.labelOptionValuesHelper}
              useHelperIcon
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_OPTION_VALUE_SEPARATOR}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderOptionValueSeparator}
              label={LocalizedString.quizQuestionScreen.placeholderOptionValueSeparator}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={OPTION_VALUE_SEPARATORS}
              value={initialValues.optionValueSeparator}
              onOptionSelected={onOptionValueSeparatorOptionSelected}
              required={selectedFieldType === ENUM_FIELD_OPTIONS}
            />
          </Grid>
        </div>
      );
    case ENUM_FIELD_PHOTO:
      return (
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_QUESTION_MUST_USE_CAMERA}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.quizQuestionScreen.placeholderMustUseCamera}
            label={LocalizedString.quizQuestionScreen.placeholderMustUseCamera}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approval}
            value={initialValues.mustUseCamera}
            onOptionSelected={onMustUseCameraOptionSelected}
            required={selectedFieldType === ENUM_FIELD_PHOTO}
          />
        </Grid>
      );
    default: return null;
  }
};

const QuizQuestionPage = ({
  initialValues, quizes,
  addingEditing, downloading, downloadingDeleting, enablingDisabling,
  loadingQuizes,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onCancelPressed, onChangeLabelText, onChangePage, onChangePageSize,
  onChangeQuizText, onConfirmPressed, onCreatePressed, onDeletePressed,
  onEditPressed, onEnableDisablePressed, onFieldTypeOptionSelected,
  onMustUseCameraOptionSelected, onOptionModeOptionSelected, onOptionValueSeparatorOptionSelected,
  onPickerModeOptionSelected, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged,
  onSortPressed, onSubmitPressed, onQuizOptionSelected, onTextKeyboardTypeOptionSelected,
  onTextMultilineOptionSelected, onViewPressed,
  onImageSelected, onClearImageOptionSelected,
  onCorrectAnswerLogicalOperatorOptionSelected,
  currentQuizQuestionStatus, pageMode, selectedFieldType, tappedId,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_QUIZ_QUESTIONS}
      uiPage={RXSTATE_QUIZ_QUESTION_PAGE}
      moreMenus={[
        {
          caption: LocalizedString.quizQuestionScreen.buttonCaptionEnable,
          disabled: currentQuizQuestionStatus === STATUS_ENABLED || enablingDisabling,
          onPress: () => onEnableDisablePressed(tappedId,
            LocalizedString.quizQuestionScreen.msgEnableConfirmation),
        },
        {
          caption: LocalizedString.quizQuestionScreen.buttonCaptionDisable,
          disabled: currentQuizQuestionStatus === STATUS_DISABLED || enablingDisabling,
          onPress: () => onEnableDisablePressed(tappedId,
            LocalizedString.quizQuestionScreen.msgDisableConfirmation),
        },
      ]}
      filterColumns={[
        {
          title: LocalizedString.quizQuestionScreen.labelQuiz,
          field: 'quiz',
          type: FILTER_TYPE_DROPDOWN,
          data: quizes,
          loading: loadingQuizes,
          onChangeFilterText: onChangeQuizText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.quizQuestionScreen.labelFieldType, field: 'fieldType', type: FILTER_TYPE_DROPDOWN, data: FIELD_TYPES,
        },
        { title: LocalizedString.quizQuestionScreen.labelRequired, field: 'required', type: FILTER_TYPE_SWITCH },
        { title: LocalizedString.quizQuestionScreen.labelVisible, field: 'visible', type: FILTER_TYPE_SWITCH },
        { title: LocalizedString.quizQuestionScreen.labelReadOnly, field: 'readOnly', type: FILTER_TYPE_SWITCH },
        {
          title: LocalizedString.quizQuestionScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
        },
      ]}
      tableColumns={[
        {
          title: LocalizedString.quizQuestionScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.quizQuestionScreen.labelQuiz, field: 'quiz.title', sorting: !downloading },
        { title: LocalizedString.quizQuestionScreen.labelLabel, field: 'label', sorting: !downloading },
        { title: LocalizedString.quizQuestionScreen.labelFieldType, field: 'fieldType', sorting: !downloading },
        { title: LocalizedString.quizQuestionScreen.labelOrder, field: 'order', sorting: !downloading },
        { title: LocalizedString.quizQuestionScreen.labelVisible, field: 'visible', sorting: !downloading },
        { title: LocalizedString.quizQuestionScreen.labelRequired, field: 'required', sorting: !downloading },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmPressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={LocalizedString.quizQuestionScreen
        .buttonCaptionCreateNewQuizQuestion}
      deleteButtonCaption={LocalizedString.quizQuestionScreen.buttonCaptionDeleteQuizQuestion}
      title={LocalizedString.quizQuestionScreen.title}
      editButtonCaption={LocalizedString.quizQuestionScreen.buttonCaptionEditQuizQuestion}
      useFullWidth
      usefullWidthDialog
      rowStyle={(rowData) => ({
        backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
      createPermissionName="SURVEY_CREATE_QUIZ_QUESTION"
      editPermissionName="SURVEY_EDIT_QUIZ_QUESTION"
      deletePermissionName="SURVEY_DELETE_QUIZ_QUESTION"
    >
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_QUIZ}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderQuiz}
              label={LocalizedString.quizQuestionScreen.placeholderQuiz}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={quizes}
              value={initialValues.quiz}
              loading={loadingQuizes}
              onChangeText={onChangeQuizText}
              onOptionSelected={onQuizOptionSelected}
              required
            />

          </Grid>
          <Grid item container>
            <Grid item sm md>
              <Field
                name={RXFIELD_QUIZ_QUESTION_NAME}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.quizQuestionScreen.placeholderName}
                label={LocalizedString.quizQuestionScreen.placeholderName}
                disabled
                required
              />
            </Grid>
            <Grid item sm md>
              <Field
                name={RXFIELD_QUIZ_QUESTION_LABEL}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.quizQuestionScreen.placeholderLabel}
                label={LocalizedString.quizQuestionScreen.placeholderLabel}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                onChangeText={(e) => onChangeLabelText(e, pageMode)}
                required
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_SCORE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderScore}
              label={LocalizedString.quizQuestionScreen.placeholderScore}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              type="number"
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_IMAGE}
              component={renderReduxFormImageInputField}
              label={LocalizedString.quizQuestionScreen.placeholderImage}
              disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
              onImageSelected={onImageSelected}
              onBlur={(e) => e.preventDefault()}
              defaultValue={initialValues.image}
              useFullWidthImage
              useAvatarWithoutCropper
              fullImageWidth="100%"
              useCropper={false}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_CLEAR_IMAGE}
              component={renderReduxFormSwitchField}
              onOptionSelected={onClearImageOptionSelected}
              label={LocalizedString.quizQuestionScreen.placeholderClearImage}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={pageMode === PAGE_MODE_TABLE}
            />
          </Grid>
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_FIELD_TYPE}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderFieldType}
              label={LocalizedString.quizQuestionScreen.placeholderFieldType}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={[FIELD_TYPES[6], FIELD_TYPES[8]]}
              value={initialValues.fieldTypes}
              onOptionSelected={onFieldTypeOptionSelected}
              required
            />
          </Grid>
          {renderQuizQuestion(initialValues, addingEditing, downloadingDeleting,
            onMustUseCameraOptionSelected, onOptionModeOptionSelected,
            onOptionValueSeparatorOptionSelected, onPickerModeOptionSelected,
            onTextKeyboardTypeOptionSelected, onTextMultilineOptionSelected,
            pageMode, selectedFieldType)}
        </Grid>
        <Grid item sm md>
          {selectedFieldType === ENUM_FIELD_PHOTO ? (
            <Grid item>
              <Field
                name={RXFIELD_QUIZ_QUESTION_ORDER}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.quizQuestionScreen.placeholderOrder}
                label={LocalizedString.quizQuestionScreen.placeholderOrder}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                type="number"
                required
              />
            </Grid>
          ) : (
            <Grid item container>
              <Grid item sm md>
                <Field
                  name={RXFIELD_QUIZ_QUESTION_ORDER}
                  component={renderReduxFormOutlinedTextField}
                  placeholder={LocalizedString.quizQuestionScreen.placeholderOrder}
                  label={LocalizedString.quizQuestionScreen.placeholderOrder}
                  disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                  type="number"
                  required
                />
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Field
              name={RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.quizQuestionScreen.placeholderCorrectAnswer}
              label={LocalizedString.quizQuestionScreen.placeholderCorrectAnswer}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              secondaryHelperText={
                    selectedFieldType === ENUM_FIELD_OPTIONS
                      ? LocalizedString.quizQuestionScreen.labelOptionValuesHelper : ''
              }
              useHelperIcon={selectedFieldType === ENUM_FIELD_OPTIONS}
              required
            />
          </Grid>
          {selectedFieldType === ENUM_FIELD_OPTIONS ? (
            <Grid item>
              <Field
                name={RXFIELD_QUIZ_QUESTION_CORRECT_ANSWER_LOGICAL_OPERATOR}
                component={renderReduxFormSimpleDropdownField}
                placeholder={
                  LocalizedString.quizQuestionScreen.placeholderLogicalOperator
                }
                label={LocalizedString.quizQuestionScreen.placeholderLogicalOperator}
                disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
                data={LOGICAL_OPERATOR}
                value={initialValues.correctAnswerLogicalOperator}
                onOptionSelected={onCorrectAnswerLogicalOperatorOptionSelected}
              />
            </Grid>
          ) : null}
        </Grid>
      </Grid>

      <Divider className={classes.divider} />

      <Grid container spacing={3}>
        <Grid item sm md>
          <Field
            name={RXFIELD_QUIZ_QUESTION_SECTION_ID}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizQuestionScreen.placeholderSectionId}
            label={LocalizedString.quizQuestionScreen.placeholderSectionId}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
        <Grid item sm md>
          <Field
            name={RXFIELD_QUIZ_QUESTION_SECTION_LABEL}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizQuestionScreen.placeholderSectionLabel}
            label={LocalizedString.quizQuestionScreen.placeholderSectionLabel}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          />
        </Grid>
      </Grid>
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_QUIZ_QUESTION,
  validate: rxformValidateQuizQuestion,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuizQuestionPage);

QuizQuestionPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  quizes: PropTypes.arrayOf(PropTypes.object).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingQuizes: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeLabelText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeQuizText: PropTypes.func.isRequired,
  onConfirmPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onEnableDisablePressed: PropTypes.func.isRequired,
  onFieldTypeOptionSelected: PropTypes.func.isRequired,
  onMustUseCameraOptionSelected: PropTypes.func.isRequired,
  onOptionModeOptionSelected: PropTypes.func.isRequired,
  onOptionValueSeparatorOptionSelected: PropTypes.func.isRequired,
  onPickerModeOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onQuizOptionSelected: PropTypes.func.isRequired,
  onTextKeyboardTypeOptionSelected: PropTypes.func.isRequired,
  onTextMultilineOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onImageSelected: PropTypes.func.isRequired,
  onClearImageOptionSelected: PropTypes.func.isRequired,
  onCorrectAnswerLogicalOperatorOptionSelected: PropTypes.func.isRequired,
  currentQuizQuestionStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedFieldType: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};

import { connect } from 'react-redux';
import { isEmpty, toNumber } from 'lodash';
import WebsiteUserEngagements from './website-user-engagements.presentation';
import { getNDaysAgo, toMoment } from '../../../../helper/helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  downloadAnalyticsWebsiteUserEngagementsAsync, setAlertErrorMessage,
  setAnalyticsWebsiteUserEngagementsDateRanges, setWebsiteUserEngagementsFilterDialogVisibility,
} from '../../redux/action';

const getFilterDateRange = (dateRange) => {
  let dateFrom = dateRange.from;
  let dateTo = dateRange.to;
  if (dateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }
  if (dateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }
  return { from: dateFrom, to: dateTo };
};
const getWebsiteUserEngagementData = (state) => {
  const { websiteUserEngagements } = state.analytics;
  const data = !isEmpty(websiteUserEngagements)
    ? websiteUserEngagements.map((item) => (
      { ...item, screenPageViews: toNumber(item.screenPageViews) }
    ))
    : [];
  return data;
};
const getTotalWebsiteUserEngagementData = (state) => {
  const { websiteUserEngagements } = state.analytics;
  return websiteUserEngagements?.length || 0;
};
const mapStateToProps = (state) => ({
  websiteUserEngagements: getWebsiteUserEngagementData(state),
  downloadingWebsiteUserEngagements: state.uiAnalytics.downloadingWebsiteUserEngagements,
  websiteUserEngagementsDateRange:
    getFilterDateRange(state.uiAnalytics.websiteUserEngagementsDateRange),
  websiteUserEngagementsFilterDialogVisibility:
        state.uiAnalytics.websiteUserEngagementsFilterDialogVisibility,
  totalPages: getTotalWebsiteUserEngagementData(state),
});
const mapDispatchToProps = (dispatch) => ({
  onFilterIconPressed: () => {
    dispatch(setWebsiteUserEngagementsFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setWebsiteUserEngagementsFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsWebsiteUserEngagementsDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsWebsiteUserEngagementsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setWebsiteUserEngagementsFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsWebsiteUserEngagementsDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsWebsiteUserEngagementsAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setWebsiteUserEngagementsFilterDialogVisibility(false));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(WebsiteUserEngagements);

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_DISABLED_ROW, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  RXFIELD_SUBDISTRICT_NAME, RXFIELD_SUBDISTRICT_DISTRICT, RXFORM_SUBDISTRICT,
  RXSTATE_SUBDISTRICTS, RXSTATE_SUBDISTRICT_PAGE, STATUS_DISABLED,
  STATUS_ENABLED, status, RXFIELD_SUBDISTRICT_CODE,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateSubdistrict } from '../../validation';

const renderDialogContent = (defaultValue, districts, addingEditing, loadingDistrict,
  onChangeDistrictText, onDistrictOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SUBDISTRICT_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.subdistrictScreen.placeholderSubdistrictName}
            label={LocalizedString.subdistrictScreen.placeholderSubdistrictName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_SUBDISTRICT_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.subdistrictScreen.placeholderSubdistrictCode}
            label={LocalizedString.subdistrictScreen.placeholderSubdistrictCode}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_SUBDISTRICT_DISTRICT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.subdistrictScreen.placeholderDistrictName}
            label={LocalizedString.subdistrictScreen.placeholderDistrictName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={districts}
            value={defaultValue}
            loading={loadingDistrict}
            onChangeText={onChangeDistrictText}
            onOptionSelected={onDistrictOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>
);

const SubdistrictPage = ({
  initialValues, districts,
  addingEditing, downloading, enablingDisabling, loadingDistrict, resyncing,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onChangeDistrictText, onConfirmContextMenuPressed,
  onContextMenuPressed, onCreatePressed, onDownloadPressed, onEditPressed,
  onDistrictOptionSelected, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  currentSubdistrictStatus, pageMode, tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_SUBDISTRICTS}
    uiPage={RXSTATE_SUBDISTRICT_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.common.buttonCaptionEnable,
        disabled: currentSubdistrictStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionDisable,
        disabled: currentSubdistrictStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgDisableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionResync,
        disabled: resyncing,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgResyncConfirmation),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.subdistrictScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.subdistrictScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.subdistrictScreen.labelDistrict, field: 'district.name', sorting: !downloading },
      { title: LocalizedString.subdistrictScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      { title: LocalizedString.subdistrictScreen.labelLastModifiedDate, field: 'lastModifiedDate', sorting: !downloading },
      { title: LocalizedString.subdistrictScreen.labelStatus, field: 'status', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.subdistrictScreen.labelDistrict,
        field: 'district',
        type: FILTER_TYPE_DROPDOWN,
        data: districts,
        loading: loadingDistrict,
        onChangeFilterText: onChangeDistrictText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.subdistrictScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDownloadPressed={onDownloadPressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.subdistrictScreen.buttonCaptionCreateNewSubdistrict}
    deleteButtonCaption={LocalizedString.subdistrictScreen.buttonCaptionDeleteSubdistrict}
    editButtonCaption={LocalizedString.subdistrictScreen.buttonCaptionEditSubdistrict}
    title={LocalizedString.subdistrictScreen.title}
    disableDelete
    enableSave
    rowStyle={(rowData) => ({
      backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
    })}
    useFullWidth
    createPermissionName="MDATA_CREATE_SUBDISTRICT"
    editPermissionName="MDATA_EDIT_SUBDISTRICT"
    savePermissionName="MDATA_GET_SUBDISTRICT"
  >
    {renderDialogContent(initialValues.district.label, districts, addingEditing, loadingDistrict,
      onChangeDistrictText, onDistrictOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SUBDISTRICT,
  validate: rxformValidateSubdistrict,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SubdistrictPage);

SubdistrictPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  districts: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  loadingDistrict: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeDistrictText: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onDistrictOptionSelected: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentSubdistrictStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};

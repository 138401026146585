import { PAGE_MODE_TABLE } from '../../../constant';
import { downloadWfaApprovalMapping, deleteWfaApprovalMapping } from '../../../helper';
import {
  addWfaApprovalMapping, clearWfaApprovalMappings, downloadingDeletingWfaApprovalMapping,
  setAlertErrorMessage, setFunctionalPageMode, setWfaApprovalMappingSelectedApprover,
  setWfaApprovalMappingSelectedDepartment, setWfaApprovalMappingSelectedType,
  setWfaApprovalMappingSelectedUser, setWfaApprovalMappingTappedId,
} from '../simple-action';
import downloadWfaApprovalMappingsAsync from './downloadWfaApprovalMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingDeletingWfaApprovalMapping(true));

    const { token } = getState().authentication;
    const { tappedId } = getState().uiWfaApprovalMapping;
    const { confirmation } = getState().alert;

    if (confirmation) {
      await deleteWfaApprovalMapping(tappedId, token);
      dispatch(setWfaApprovalMappingTappedId(''));
      dispatch(setFunctionalPageMode(PAGE_MODE_TABLE));
      dispatch(clearWfaApprovalMappings());
      dispatch(downloadWfaApprovalMappingsAsync(1))
        .catch((err) => dispatch(setAlertErrorMessage(err)));
    } else {
      const result = await downloadWfaApprovalMapping(tappedId, token);
      dispatch(addWfaApprovalMapping(result));
      dispatch(setWfaApprovalMappingSelectedType(result?.type));
      dispatch(setWfaApprovalMappingSelectedApprover({
        label: result?.approver?.fullName,
        value: result?.approver?.id,
      }));
      dispatch(setWfaApprovalMappingSelectedDepartment({
        label: result?.departmentName,
        value: result?.departmentName,
      }));
      dispatch(setWfaApprovalMappingSelectedUser({
        label: result?.user?.fullName,
        value: result?.user?.id,
      }));
    }
  } finally {
    dispatch(downloadingDeletingWfaApprovalMapping(false));
  }
};

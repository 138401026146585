import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  FILTER_TYPE_MULTIPLE_VALUES, PAGE_MODE_VIEW, RXFIELD_SCOPE_DESCRIPTION, RXFIELD_SCOPE_NAME,
  RXFIELD_SCOPE_SERVICE, RXFORM_SCOPE, RXSTATE_SCOPE_PAGE, RXSTATE_SCOPES,
} from '../../constant';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateScope } from '../../validation';

const renderDialogContent = (defaultValue, services, addingEditing, loadingService,
  onChangeServiceText, onServiceOptionSelected, pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid>
          <Field
            name={RXFIELD_SCOPE_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scopeScreen.placeholderScopeName}
            label={LocalizedString.scopeScreen.placeholderScopeName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid>
          <Field
            name={RXFIELD_SCOPE_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.scopeScreen.placeholderScopeDescription}
            label={LocalizedString.scopeScreen.placeholderScopeDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Field
          name={RXFIELD_SCOPE_SERVICE}
          component={renderReduxFormOutlinedDropdownTextField}
          placeholder={LocalizedString.scopeScreen.placeholderServiceName}
          label={LocalizedString.scopeScreen.placeholderServiceName}
          disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
          data={services}
          value={defaultValue}
          loading={loadingService}
          onChangeText={onChangeServiceText}
          onOptionSelected={onServiceOptionSelected}
          required
        />
      </Grid>
    </Grid>
);

const ScopePage = ({
  initialValues, services,
  addingEditing, downloading, loadingService,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed, onCancelPressed,
  onChangePage, onChangePageSize, onChangeServiceText, onConfirmDeletePressed, onCreatePressed,
  onDeletePressed, onEditPressed, onRefresh, onResetAdvancedFilterPressed, onSavePressed,
  onSearchBarTextChanged, onServiceOptionSelected, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode,
}) => (
  <FunctionalPage
    data={RXSTATE_SCOPES}
    uiPage={RXSTATE_SCOPE_PAGE}
    filterColumns={[
      {
        title: LocalizedString.scopeScreen.labelService,
        field: RXFIELD_SCOPE_SERVICE,
        type: FILTER_TYPE_MULTIPLE_VALUES,
        data: services,
        loading: loadingService,
        onChangeFilterText: onChangeServiceText,
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.scopeScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.scopeScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.scopeScreen.labelDescription, field: 'description', sorting: !downloading },
      { title: LocalizedString.scopeScreen.labelService, field: 'service.name', sorting: !downloading },
      { title: LocalizedString.scopeScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.scopeScreen.buttonCaptionCreateNewScope}
    deleteButtonCaption={LocalizedString.scopeScreen.buttonCaptionDeleteScope}
    editButtonCaption={LocalizedString.scopeScreen.buttonCaptionEditScope}
    title={LocalizedString.scopeScreen.title}
    useFullWidth
    createPermissionName="AUTH_CREATE_SCOPE"
    deletePermissionName="AUTH_DELETE_SCOPE"
    editPermissionName="AUTH_EDIT_SCOPE"
  >
    {renderDialogContent(initialValues.service.label, services, addingEditing, loadingService,
      onChangeServiceText, onServiceOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_SCOPE,
  validate: rxformValidateScope,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ScopePage);

ScopePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  services: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  loadingService: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeServiceText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onServiceOptionSelected: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
};

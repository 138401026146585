import { getAnalyticsWebsiteUserEngagements } from '../../../helper';
import {
  downloadingAnalyticsWebsiteUserEngagements, setAnalyticsWebsiteUserEngagements,
  clearAnalyticsWebsiteUserEngagements,
} from '../simple-action';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAnalyticsWebsiteUserEngagements(true));
    const { authentication, uiAnalytics, myConfigItems } = getState();
    const { websiteUserEngagementsDateRange } = uiAnalytics;
    const { token } = authentication;
    const requestBody = {
      dateRanges: [{
        startDate: websiteUserEngagementsDateRange.from,
        endDate: websiteUserEngagementsDateRange.to,
      }],
      dimensions: [
        { name: 'pageLocation' },
        { name: 'pageTitle' },
      ],
      metrics: [
        { name: 'screenPageViews' },
      ],
    };
    const response = await getAnalyticsWebsiteUserEngagements(
      myConfigItems?.core?.googleAnalyticsPropertyId, requestBody, token,
    );
    dispatch(clearAnalyticsWebsiteUserEngagements());
    dispatch(setAnalyticsWebsiteUserEngagements(response));
  } finally {
    dispatch(downloadingAnalyticsWebsiteUserEngagements(false));
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage, AccentButton } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME,
  RXFIELD_WFA_APPROVAL_MAPPING_APPROVER, RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT,
  RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE,
  RXFIELD_WFA_APPROVAL_MAPPING_TYPE, RXFIELD_WFA_APPROVAL_MAPPING_USER,
  RXFORM_WFA_APPROVAL_MAPPING, RXSTATE_WFA_APPROVAL_MAPPINGS, RXSTATE_WFA_APPROVAL_MAPPING_PAGE,
  WFA_APPROVAL_MAPPING_TYPES, WFA_APPROVAL_MAPPING_TYPE_DEPARTMENT, WFA_APPROVAL_MAPPING_TYPE_USER,
  FILE_EXTENSION_SPREADSHEET,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import GlobalLocalizedString from '../../../../localization';
import { renderReduxFormOutlinedDropdownTextField, renderReduxFormSimpleDropdownField, renderReduxFormFilePickerField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateWfaApprovalMapping } from '../../validation';

const renderImportDialogContent = (
  templateUrl, importing, onDownloadTemplatePressed, onFileSelected,
) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE}
          component={renderReduxFormFilePickerField}
          label={LocalizedString.personalHolidayPage.placeholderFile}
          disabled={importing}
          onFileSelected={onFileSelected}
          acceptedFileExtension={FILE_EXTENSION_SPREADSHEET}
          required
          onBlur={(e) => e.preventDefault()}
        />
        <AccentButton
          variant="text"
          caption={GlobalLocalizedString.common.buttonCaptionDownloadTemplate}
          onClick={() => onDownloadTemplatePressed(templateUrl)}
          disabled={importing}
        />
      </Grid>
    </Grid>
  </Grid>

);

const renderDialogContent = (initialValues, approvers, departments, users, addingEditing,
  downloadingDeleting, loadingApprover, loadingDepartment, loadingUser, onApproverOptionSelected,
  onChangeApproverText, onChangeDepartmentText, onChangeUserText, onDepartmentOptionSelected,
  onTypeOptionSelected, onUserOptionSelected, pageMode, selectedType) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WFA_APPROVAL_MAPPING_TYPE}
            component={renderReduxFormSimpleDropdownField}
            placeholder={LocalizedString.wfaApprovalMappingPage.placeholderType}
            label={LocalizedString.wfaApprovalMappingPage.placeholderType}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={WFA_APPROVAL_MAPPING_TYPES}
            value={initialValues[RXFIELD_WFA_APPROVAL_MAPPING_TYPE]}
            onOptionSelected={onTypeOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.wfaApprovalMappingPage.placeholderDepartment}
            label={LocalizedString.wfaApprovalMappingPage.placeholderDepartment}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={departments}
            value={initialValues[RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT]}
            loading={loadingDepartment}
            onChangeText={onChangeDepartmentText}
            onOptionSelected={onDepartmentOptionSelected}
            required
            hidden={selectedType !== WFA_APPROVAL_MAPPING_TYPE_DEPARTMENT}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_WFA_APPROVAL_MAPPING_USER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.wfaApprovalMappingPage.placeholderUser}
            label={LocalizedString.wfaApprovalMappingPage.placeholderUser}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={users}
            value={initialValues[RXFIELD_WFA_APPROVAL_MAPPING_USER]}
            loading={loadingUser}
            onChangeText={onChangeUserText}
            onOptionSelected={onUserOptionSelected}
            required
            hidden={selectedType !== WFA_APPROVAL_MAPPING_TYPE_USER}
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_WFA_APPROVAL_MAPPING_APPROVER}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.wfaApprovalMappingPage.placeholderApprover}
            label={LocalizedString.wfaApprovalMappingPage.placeholderApprover}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            data={approvers}
            value={initialValues[RXFIELD_WFA_APPROVAL_MAPPING_APPROVER]}
            loading={loadingApprover}
            onChangeText={onChangeApproverText}
            onOptionSelected={onApproverOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>
);

const WfaApprovalMappingPage = ({
  initialValues, approvers, departments, users,
  addingEditing, downloading, downloadingDeleting, loadingApprover, loadingDepartment, loadingUser,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onApproverOptionSelected, onCancelAdvancedFilterPressed, onCancelPressed, onChangeApproverText,
  onChangeDepartmentText, onChangePage, onChangePageSize, onChangeUserText, onConfirmDeletePressed,
  onCreatePressed, onDeletePressed, onDepartmentOptionSelected, onEditPressed, onRefresh,
  onResetAdvancedFilterPressed, onSavePressed, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onTypeOptionSelected, onUserOptionSelected, onViewPressed,
  pageMode, selectedType, importing, templateUrl, onDownloadTemplatePressed, onFileSelected,
  onImportSubmitPressed, onImportCancelPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_WFA_APPROVAL_MAPPINGS}
    uiPage={RXSTATE_WFA_APPROVAL_MAPPING_PAGE}
    tableColumns={[
      {
        title: LocalizedString.wfaApprovalMappingPage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => rowData,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelType, field: 'type', sorting: !downloading,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelUser, field: 'user.fullName', sorting: !downloading,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelDepartment, field: 'departmentName', sorting: !downloading,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelApprover, field: 'approver.fullName', sorting: !downloading,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelCreatedDate,
        field: 'createdDate',
        sorting: false,
        render: ({ createdDate }) => (createdDate
          ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
      },
    ]}
    filterColumns={[
      {
        title: LocalizedString.wfaApprovalMappingPage.labelType,
        field: RXFIELD_WFA_APPROVAL_MAPPING_TYPE,
        type: FILTER_TYPE_DROPDOWN,
        data: WFA_APPROVAL_MAPPING_TYPES,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelDepartment,
        field: RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT,
        type: FILTER_TYPE_DROPDOWN,
        data: departments,
        loading: loadingDepartment,
        onChangeFilterText: onChangeDepartmentText,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelUser,
        field: RXFIELD_WFA_APPROVAL_MAPPING_USER,
        type: FILTER_TYPE_DROPDOWN,
        data: users,
        loading: loadingUser,
        onChangeFilterText: onChangeUserText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelApprover,
        field: RXFIELD_WFA_APPROVAL_MAPPING_APPROVER,
        type: FILTER_TYPE_DROPDOWN,
        data: approvers,
        loading: loadingApprover,
        onChangeFilterText: onChangeApproverText,
        useDropdownValue: true,
      },
      {
        title: LocalizedString.wfaApprovalMappingPage.labelCreatedDate,
        field: 'createdDate',
        type: FILTER_TYPE_DATE_RANGE,
        pickerMode: PICKER_MODE_DATE_TIME,
        format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
      },
    ]}
    onAdvancedFilterPressed={onAdvancedFilterPressed}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.wfaApprovalMappingPage
      .buttonCaptionCreateNewWfaApprovalMapping}
    deleteButtonCaption={LocalizedString.wfaApprovalMappingPage
      .buttonCaptionDeleteWfaApprovalMapping}
    editButtonCaption={LocalizedString.wfaApprovalMappingPage.buttonCaptionEditWfaApprovalMapping}
    title={LocalizedString.wfaApprovalMappingPage.title}
    createPermissionName="ATTENDANCEADDON_CREATE_WFA_APPROVAL_MAPPING"
    deletePermissionName="ATTENDANCEADDON_DELETE_WFA_APPROVAL_MAPPING"
    editPermissionName="ATTENDANCEADDON_EDIT_WFA_APPROVAL_MAPPING"
    useFullWidth
    disableImport={false}
    importDialogContent={renderImportDialogContent(
      templateUrl, importing, onDownloadTemplatePressed, onFileSelected,
    )}
    importDialogTitle={LocalizedString.wfaApprovalMappingPage.labelImportWfaApprovalMapping}
    onImportSubmitPressed={onImportSubmitPressed}
    onImportCancelPressed={onImportCancelPressed}
  >
    {renderDialogContent(initialValues, approvers, departments, users, addingEditing,
      downloadingDeleting, loadingApprover, loadingDepartment, loadingUser,
      onApproverOptionSelected, onChangeApproverText, onChangeDepartmentText, onChangeUserText,
      onDepartmentOptionSelected, onTypeOptionSelected, onUserOptionSelected, pageMode,
      selectedType)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_WFA_APPROVAL_MAPPING,
  validate: rxformValidateWfaApprovalMapping,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WfaApprovalMappingPage);

WfaApprovalMappingPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  approvers: PropTypes.arrayOf(SimpleDataShape).isRequired,
  departments: PropTypes.arrayOf(SimpleDataShape).isRequired,
  users: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingApprover: PropTypes.bool.isRequired,
  loadingDepartment: PropTypes.bool.isRequired,
  loadingUser: PropTypes.bool.isRequired,
  importing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onApproverOptionSelected: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangeApproverText: PropTypes.func.isRequired,
  onChangeDepartmentText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onDepartmentOptionSelected: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTypeOptionSelected: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onFileSelected: PropTypes.func.isRequired,
  onDownloadTemplatePressed: PropTypes.func.isRequired,
  onImportSubmitPressed: PropTypes.func.isRequired,
  onImportCancelPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedType: PropTypes.string.isRequired,
  templateUrl: PropTypes.string.isRequired,
};

import { rxFormValidateRequiredFields } from '../../validation';
import {
  RXFIELD_WFA_QUOTA_DEPARTMENT, RXFIELD_WFA_QUOTA_QUOTA_PER_MONTH, RXFIELD_WFA_DATE,
  RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES,
  RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES, RXFIELD_WORKING_HOUR_END_AFTER_MIN,
  RXFIELD_WORKING_HOUR_END_BEFORE_MIN, RXFIELD_WORKING_HOUR_END_WORKING_HOUR,
  RXFIELD_WORKING_HOUR_NAME, RXFIELD_WORKING_HOUR_START_AFTER_MIN,
  RXFIELD_WORKING_HOUR_START_BEFORE_MIN, RXFIELD_WORKING_HOUR_START_WORKING_HOUR,
  RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE, RXFIELD_HOLIDAY_YEAR, RXFIELD_PERSONAL_HOLIDAY_FILE,
  RXFIELD_HOLIDAY_FILE, RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT,
  RXFIELD_WFA_APPROVAL_MAPPING_APPROVER,
  RXFIELD_WFA_APPROVAL_MAPPING_TYPE,
  RXFIELD_WFA_APPROVAL_MAPPING_USER,
  WFA_APPROVAL_MAPPING_TYPE_DEPARTMENT,
} from './constant';

export const rxformValidateWorkingHour = (values) => {
  const requiredFields = [
    RXFIELD_WORKING_HOUR_NAME,
    RXFIELD_WORKING_HOUR_START_WORKING_HOUR,
    RXFIELD_WORKING_HOUR_END_WORKING_HOUR,
    RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE,
    RXFIELD_WORKING_HOUR_START_BEFORE_MIN,
    RXFIELD_WORKING_HOUR_START_AFTER_MIN,
    RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES,
    RXFIELD_WORKING_HOUR_END_BEFORE_MIN,
    RXFIELD_WORKING_HOUR_END_AFTER_MIN,
    RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateWFAQuota = (values) => {
  const requiredFields = [
    RXFIELD_WFA_QUOTA_DEPARTMENT,
    RXFIELD_WFA_QUOTA_QUOTA_PER_MONTH,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateWfa = (values) => {
  const requiredFields = [
    RXFIELD_WFA_DATE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateHoliday = (values) => {
  const requiredFields = [
    RXFIELD_HOLIDAY_YEAR,
    RXFIELD_HOLIDAY_FILE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidatePersonalHoliday = (values) => {
  const requiredFields = [
    RXFIELD_PERSONAL_HOLIDAY_FILE,
  ];

  const errors = rxFormValidateRequiredFields(values, requiredFields);
  return errors;
};

export const rxformValidateWfaApprovalMapping = (values, props) => {
  const { selectedType } = props;

  const requiredFields = [
    RXFIELD_WFA_APPROVAL_MAPPING_TYPE,
    RXFIELD_WFA_APPROVAL_MAPPING_APPROVER,
  ];

  const additionalRequiredFields = selectedType === WFA_APPROVAL_MAPPING_TYPE_DEPARTMENT
    ? RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT : RXFIELD_WFA_APPROVAL_MAPPING_USER;

  const errors = rxFormValidateRequiredFields(values, [...requiredFields,
    additionalRequiredFields]);
  return errors;
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { useLocation } from 'react-router-dom';
import { Grid, makeStyles } from '@material-ui/core';
import { AccentButton, AlertBox, FunctionalPage } from '../../component';
import {
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE, FILTER_TYPE_DROPDOWN,
  PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT, PICKER_MODE_DATE_TIME, RXFIELD_WFA_DATE,
  RXFIELD_WFA_REQUEST_ID, RXFIELD_WFA_REQUESTER_DEPARTMENT, RXFIELD_WFA_REQUESTER_DIVISION,
  RXFIELD_WFA_REQUESTER_EMPLOYEE_ID, RXFIELD_WFA_REQUESTER_ID, RXFIELD_WFA_REQUESTER_NAME,
  RXFIELD_WFA_STATUSES_NOTES, RXFIELD_WFA_STATUSES, RXFORM_WFA, RXSTATE_WFA_PAGE, RXSTATE_WFAS,
  SIMPLE_DATE_FORMAT, WFA_STATUSES, ANALYTICS_LOG_EDIT,
} from '../../constant';
import { toMoment, logEventToAnalytics } from '../../helper';
import LocalizedString from '../../localization';
import { renderReduxFormOutlinedTextField } from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import EditDialog from './edit-dialog';

const useStyles = makeStyles(() => ({
  fabContainer: {
    marginRight: 10,
  },
}));

const renderDialogContent = () => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WFA_REQUESTER_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelRequesterID}
          label={LocalizedString.wfaPage.labelRequesterID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_REQUESTER_NAME}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelRequesterName}
          label={LocalizedString.wfaPage.labelRequesterName}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_REQUESTER_EMPLOYEE_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelRequesterEmployeeID}
          label={LocalizedString.wfaPage.labelRequesterEmployeeID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_REQUESTER_DIVISION}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelRequesterDivision}
          label={LocalizedString.wfaPage.labelRequesterDivision}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_REQUESTER_DEPARTMENT}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelRequesterDepartement}
          label={LocalizedString.wfaPage.labelRequesterDepartement}
          disabled
        />
      </Grid>
    </Grid>

    <Grid item sm md>
      <Grid item>
        <Field
          name={RXFIELD_WFA_REQUEST_ID}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelRequestID}
          label={LocalizedString.wfaPage.labelRequestID}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_DATE}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelDate}
          label={LocalizedString.wfaPage.labelDate}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_STATUSES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelWfaStatus}
          label={LocalizedString.wfaPage.labelWfaStatus}
          disabled
        />
      </Grid>
      <Grid item>
        <Field
          name={RXFIELD_WFA_STATUSES_NOTES}
          component={renderReduxFormOutlinedTextField}
          placeholder={LocalizedString.wfaPage.labelWfaStatusNotes}
          label={LocalizedString.wfaPage.labelWfaStatusNotes}
          disabled
          multiline
        />
      </Grid>
    </Grid>
  </Grid>
);

const WfaPage = ({
  initialValues, requesters,
  addingEditing, canceling, downloading, downloadingDeleting, editDialogVisibility,
  loadingRequester,
  handleSubmit, onAdvancedFilterPressed, onAppear, onApplyAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onCancelDialogPressed, onCancelPressed, onCancelWfaPressed,
  onChangePage, onChangePageSize, onChangeRequesterText, onConfirmLogoutPressed, onEditWfaPressed,
  onRefresh, onResetAdvancedFilterPressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onViewPressed,
  tappedId,
  history, myConfigItems, userPermissions,
}) => {
  const location = useLocation();
  const classes = useStyles();

  const renderCustomFabComponent = () => !downloadingDeleting
  && initialValues.wfaStatus === WFA_STATUSES[1]
   && userPermissions.ATTENDANCEADDON_WFA_CANCEL && (
   <div className={classes.fabContainer}>
     <AccentButton
       variant="text"
       disabled={downloadingDeleting}
       loading={canceling}
       disableElevation
       caption={LocalizedString.wfaPage.buttonCaptionCancel}
       onClick={() => onCancelWfaPressed(tappedId)}
     />
   </div>
  );

  return (
    <div>
      <FunctionalPage
        data={RXSTATE_WFAS}
        uiPage={RXSTATE_WFA_PAGE}
        moreMenus={[
          {
            caption: LocalizedString.wfaPage.buttonCaptionEdit,
            disabled: addingEditing,
            onPress: () => {
              onEditWfaPressed(tappedId);
              if (location) {
                logEventToAnalytics(location, ANALYTICS_LOG_EDIT);
              }
            },
          },
          {
            caption: LocalizedString.wfaPage.buttonCaptionCancel,
            disabled: canceling,
            onPress: () => onCancelWfaPressed(tappedId),
          },
        ]}
        tableColumns={[
          {
            title: LocalizedString.wfaPage.labelNo,
            field: 'no',
            sorting: false,
            width: 40,
            customFilterAndSearch: (term, rowData) => rowData,
          },
          {
            title: LocalizedString.wfaPage.labelRequestID,
            field: 'requestId',
            sorting: false,
          },
          {
            title: LocalizedString.wfaPage.labelRequester,
            field: 'requester.fullName',
            sorting: !downloading,
          },
          {
            title: LocalizedString.wfaPage.labelDate,
            field: 'date',
            sorting: !downloading,
            render: ({ date }) => (date
              ? toMoment(date).format(SIMPLE_DATE_FORMAT)
              : null),
          },
          {
            title: LocalizedString.wfaPage.labelWfaStatus,
            field: 'wfaStatus',
            sorting: !downloading,
          },
          {
            title: LocalizedString.wfaPage.labelCreatedDate,
            field: 'createdDate',
            sorting: false,
            render: ({ createdDate }) => (createdDate
              ? toMoment(createdDate).format(DATE_TIME_FORMAT_WITHOUT_PIPE)
              : null),
          },
        ]}
        filterColumns={[
          {
            title: LocalizedString.wfaPage.labelWfaStatus,
            field: 'wfaStatus',
            type: FILTER_TYPE_DROPDOWN,
            data: WFA_STATUSES,
          },
          {
            title: LocalizedString.wfaPage.labelRequester,
            field: 'requester',
            type: FILTER_TYPE_DROPDOWN,
            loading: loadingRequester,
            data: requesters,
            onChangeFilterText: onChangeRequesterText,
            useDropdownValue: true,
          },
          {
            title: LocalizedString.wfaPage.labelCreatedDate,
            field: 'createdDate',
            type: FILTER_TYPE_DATE_RANGE,
            pickerMode: PICKER_MODE_DATE_TIME,
            format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
          },
        ]}
        onAdvancedFilterPressed={onAdvancedFilterPressed}
        onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
        onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
        onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
        handleSubmit={handleSubmit}
        onAppear={onAppear}
        onCancelPressed={onCancelPressed}
        onChangePage={onChangePage}
        onChangePageSize={onChangePageSize}
        onRefresh={onRefresh}
        onSearchBarTextChanged={onSearchBarTextChanged}
        onSortPressed={onSortPressed}
        onViewPressed={onViewPressed}
        title={LocalizedString.wfaPage.title}
        useFullWidth
        disableCreate
        disableEdit
        disableDelete
        renderCustomFabComponent={() => renderCustomFabComponent()}
      >
        {renderDialogContent()}
      </FunctionalPage>
      <EditDialog
        submitting={addingEditing}
        visibility={editDialogVisibility}
        onCancelPressed={onCancelDialogPressed}
        onSubmitPressed={onSubmitPressed}
      />
      <AlertBox onConfirmPressed={(message, reason) => onConfirmLogoutPressed(message, reason,
        history, myConfigItems)}
      />
    </div>
  );
};

export default reduxForm({
  form: RXFORM_WFA,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(WfaPage);

WfaPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  requesters: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  canceling: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  editDialogVisibility: PropTypes.bool.isRequired,
  loadingRequester: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelDialogPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCancelWfaPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeRequesterText: PropTypes.func.isRequired,
  onConfirmLogoutPressed: PropTypes.func.isRequired,
  onEditWfaPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  tappedId: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
  myConfigItems: PropTypes.object.isRequired,
  userPermissions: PropTypes.objectOf(PropTypes.string).isRequired,
};

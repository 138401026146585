import { ORDER_SORT_DESCENDING } from '../../constant';

export * from '../../constant';

export const REST_URL_WORKING_HOURS = '/attendanceaddon/api/v1/WorkingHour?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_WORKING_HOUR = '/attendanceaddon/api/v1/WorkingHour';
export const REST_URL_VIEW_DELETE_WORKING_HOUR = '/attendanceaddon/api/v1/WorkingHour/{id}';
export const REST_URL_ENABLE_DISABLE_WORKING_HOUR = '/attendanceaddon/api/v1/WorkingHour/Status';
export const REST_URL_WORKING_HOUR_ADJUSTMENTS = '/attendanceaddon/api/v1/WorkingHourAdjustment?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_WORKING_HOUR_ADJUSTMENT = '/attendanceaddon/api/v1/WorkingHourAdjustment/{id}';
export const REST_URL_WFA_REQUESTS = '/attendanceaddon/api/v1/WfaRequest?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_WFA_REQUEST = '/attendanceaddon/api/v1/WfaRequest/{id}';
export const REST_URL_WFA_QUOTAS = '/attendanceaddon/api/v1/WfaQuota?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_WFA_QUOTA = '/attendanceaddon/api/v1/WfaQuota';
export const REST_URL_VIEW_DELETE_WFA_QUOTA = '/attendanceaddon/api/v1/WfaQuota/{id}';
export const REST_URL_WFAS = '/attendanceaddon/api/v1/Wfa?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_WFA = '/attendanceaddon/api/v1/Wfa/{id}';
export const REST_URL_EDIT_WFA = '/attendanceaddon/api/v1/Wfa';
export const REST_URL_CANCEL_WFA = '/attendanceaddon/api/v1/Wfa/Cancel';
export const REST_URL_ATTENDANCE_CORRECTIONS = '/attendanceaddon/api/v1/AttendanceCorrection?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_VIEW_ATTENDANCE_CORRECTION = '/attendanceaddon/api/v1/AttendanceCorrection/{id}';
export const REST_URL_HOLIDAYS = '/attendanceaddon/api/v1/NationalHolidayImport?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_HOLIDAY = '/attendanceaddon/api/v1/NationalHolidayImport';
export const REST_URL_VIEW_HOLIDAY = '/attendanceaddon/api/v1/NationalHolidayImport/{id}';
export const REST_URL_PERSONAL_HOLIDAYS = '/attendanceaddon/api/v1/PersonalHolidayImport?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_PERSONAL_HOLIDAY = '/attendanceaddon/api/v1/PersonalHolidayImport';
export const REST_URL_VIEW_PERSONAL_HOLIDAY = '/attendanceaddon/api/v1/PersonalHolidayImport/{id}';
export const REST_URL_ATTENDANCE_ADDON_SUMMARY = '/attendanceaddon/api/v1/AttendanceSummary?month={month}&year={year}&userId={userId}';
export const REST_URL_EXPORT_ATTENDANCE_ADDON_SUMMARY = '/attendanceaddon/api/v1/AttendanceReport?startDate={startDate}&endDate={endDate}&userId={userId}';
export const REST_URL_WFA_APPROVAL_MAPPINGS = '/attendanceaddon/api/v1/WfaApprovalMapping?pageNumber={pageNumber}&pageSize={pageSize}&orderBy={orderBy}&searchString={searchText}&filterString=';
export const REST_URL_ADD_EDIT_WFA_APPROVAL_MAPPING = '/attendanceaddon/api/v1/WfaApprovalMapping';
export const REST_URL_VIEW_DELETE_WFA_APPROVAL_MAPPING = '/attendanceaddon/api/v1/WfaApprovalMapping/{id}';
export const REST_URL_IMPORT_WFA_APPROVAL_MAPPING_FILE = '/attendanceaddon/api/v1/WfaApprovalMappingImport';

export const ROUTE_NAME_WORKING_HOUR = '/working-hour';
export const ROUTE_NAME_WORKING_HOUR_ADJUSTMENT = '/working-hour-adjustment';
export const ROUTE_NAME_WFA_REQUEST = '/wfa-request';
export const ROUTE_NAME_WFA_QUOTA = '/wfa-quota';
export const ROUTE_NAME_WFA = '/wfa';
export const ROUTE_NAME_ATTENDANCE_CORRECTION = '/attendance-correction';
export const ROUTE_NAME_HOLIDAY = '/holiday';
export const ROUTE_NAME_PERSONAL_HOLIDAY = '/personal-holiday';
export const ROUTE_NAME_ATTENDANCE_ADDON_SUMMARY = '/attendance-addon-summary';
export const ROUTE_NAME_WFA_APPROVAL_MAPPING = '/wfa-approval-mapping';

export const RXFORM_WORKING_HOUR = 'workingHourPage';
export const RXFORM_WORKING_HOUR_ADJUSTMENT = 'workingHourAdjustmentPage';
export const RXFORM_WFA_REQUEST = 'wfaRequestPage';
export const RXFORM_WFA_QUOTA = 'wfaQuotaPage';
export const RXFORM_WFA = 'wfaPage';
export const RXFORM_ATTENDANCE_CORRECTION = 'attendanceCorrectionPage';
export const RXFORM_HOLIDAY = 'holidayPage';
export const RXFORM_WFA_EDIT_DIALOG = 'wfaEditDialog';
export const RXFORM_PERSONAL_HOLIDAY = 'personalHolidayPage';
export const RXFORM_SUMMARY = 'attendanceAddOnSummaryPage';
export const RXFORM_WFA_APPROVAL_MAPPING = 'wfaApprovalMappingPage';

export const RXFIELD_WORKING_HOUR_CREATED_DATE = 'createdDate';
export const RXFIELD_WORKING_HOUR_NAME = 'name';
export const RXFIELD_WORKING_HOUR_DESCRIPTION = 'description';
export const RXFIELD_WORKING_HOUR_START_WORKING_HOUR = 'startWorkingHour';
export const RXFIELD_WORKING_HOUR_END_WORKING_HOUR = 'endWorkingHour';
export const RXFIELD_WORKING_HOUR_START_BEFORE_MIN = 'startWorkingHourMarginBeforeMinutes';
export const RXFIELD_WORKING_HOUR_START_AFTER_MIN = 'startWorkingHourMarginAfterMinutes';
export const RXFIELD_WORKING_HOUR_END_BEFORE_MIN = 'endWorkingHourMarginBeforeMinutes';
export const RXFIELD_WORKING_HOUR_END_AFTER_MIN = 'endWorkingHourMarginAfterMinutes';
export const RXFIELD_WORKING_HOUR_WORKING_HOUR_TIMEZONE = 'workingHourTimeZone';
export const RXFIELD_WORKING_HOUR_STATUS = 'status';
export const RXFIELD_WORKING_HOUR_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES = 'earliestClockInDriftTimeMinutes';
export const RXFIELD_WORKING_HOUR_AUTO_CLOCK_OUT_AFTER_MINUTES = 'autoClockOutAfterMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_ID = 'approvalId';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_NUMBER = 'approvalNumber';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_STATUS = 'approvalStatus';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_STATUS_TIME = 'approvalStatusTime';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_PIC_ID = 'approvalPicId';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_APPROVAL_PIC_NAME = 'approvalPicName';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_ID = 'requester.id';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_NAME = 'requester.fullName';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_EMPLOYEE_ID = 'requester.employeeId';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_DEPARTMENT = 'requester.department';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_REQUESTER_DIVISION = 'requester.division';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_START_DATE = 'startDate';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_END_DATE = 'endDate';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_ID = 'workingHour.id';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_NAME = 'workingHour.name';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_START_WORKING_HOUR = 'startWorkingHour';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_END_WORKING_HOUR = 'endWorkingHour';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_START_BEFORE_MIN = 'startWorkingHourMarginBeforeMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_START_AFTER_MIN = 'startWorkingHourMarginAfterMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_END_BEFORE_MIN = 'endWorkingHourMarginBeforeMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_END_AFTER_MIN = 'endWorkingHourMarginAfterMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_WORKING_HOUR_TIMEZONE = 'workingHourTimeZone';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_STATUS = 'status';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_EARLIEST_CLOCK_IN_DRIFT_TIME_MINUTES = 'earliestClockInDriftTimeMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_AUTO_CLOCK_OUT_AFTER_MINUTES = 'autoClockOutAfterMinutes';
export const RXFIELD_WORKING_HOUR_ADJUSTMENT_CREATED_DATE = 'createdDate';
export const RXFIELD_WFA_QUOTA_DEPARTMENT = 'departmentName';
export const RXFIELD_WFA_QUOTA_QUOTA_PER_MONTH = 'quotaPerMonth';
export const RXFIELD_WFA_REQUEST_APPROVAL_ID = 'approvalId';
export const RXFIELD_WFA_REQUEST_APPROVAL_NUMBER = 'approvalNumber';
export const RXFIELD_WFA_REQUEST_APPROVAL_STATUS = 'approvalStatus';
export const RXFIELD_WFA_REQUEST_REQUESTER_ID = 'requesterId';
export const RXFIELD_WFA_REQUEST_REQUESTER_NAME = 'requesterName';
export const RXFIELD_WFA_REQUEST_DATES = 'dates';
export const RXFIELD_WFA_REQUEST_APPROVAL_PIC_ID = 'approvalPicId';
export const RXFIELD_WFA_REQUEST_APPROVAL_PIC_NAME = 'approvalPicName';
export const RXFIELD_WFA_REQUEST_ID = 'requestId';
export const RXFIELD_WFA_REQUESTER_ID = 'requesterId';
export const RXFIELD_WFA_REQUESTER_NAME = 'requesterName';
export const RXFIELD_WFA_DATE = 'date';
export const RXFIELD_WFA_STATUSES = 'wfaStatus';
export const RXFIELD_WFA_STATUSES_NOTES = 'wfaStatusNotes';
export const RXFIELD_WFA_REQUESTER_EMPLOYEE_ID = 'requesterEmployeeId';
export const RXFIELD_WFA_REQUESTER_DIVISION = 'requesterDivision';
export const RXFIELD_WFA_REQUESTER_DEPARTMENT = 'requesterDepartment';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_ID = 'attendance.id';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR = 'attendance.startWorkingHour';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR_TIME = 'attendance.startWorkingHourTime';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_HOUR_TIME_ZONE = 'attendance.startWorkingHourTimeZone';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_BEFORE_MIN = 'attendance.startWorkingHourMarginBeforeMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_START_AFTER_MIN = 'attendance.startWorkingHourMarginAfterMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_DRIFT_TIME_MIN = 'attendance.earliestClockInDriftTimeMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_CLOCK_IN_TIME = 'attendance.clockInTime';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_DRIFT_MIN = 'attendance.clockInDriftMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LAT = 'attendance.clockInLatitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LNG = 'attendance.clockInLongitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_REPORTED_LAT = 'attendance.clockInUserReportedLatitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_REPORTED_LNG = 'attendance.clockInUserReportedLongitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_NAME = 'attendance.clockInLocationName';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_ID = 'attendance.clockInLocationId';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_NOTES = 'attendance.clockInNotes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_STATUS = 'attendance.clockInStatus';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_IN_LOC_STATUS = 'attendance.clockInLocationStatus';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_HOUR = 'attendance.endWorkingHour';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_HOUR_TIME = 'attendance.endWorkingHourTime';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_HOUR_TIME_ZONE = 'attendance.endWorkingHourTimeZone';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_BEFORE_MIN = 'attendance.endWorkingHourMarginBeforeMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_END_AFTER_MIN = 'attendance.endWorkingHourMarginAfterMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_AUTO_CLOCK_OUT_TIME = 'attendance.autoClockOutTime';
export const RXFIELD_ATTENDANCE_CORRECTION_CLOCK_OUT_TIME = 'attendance.clockOutTime';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_DRIFT_MIN = 'attendance.clockOutDriftMinutes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LAT = 'attendance.clockOutLatitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LNG = 'attendance.clockOutLongitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_REPORTED_LAT = 'attendance.clockOutUserReportedLatitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_REPORTED_LNG = 'attendance.clockOutUserReportedLongitude';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_NAME = 'attendance.clockOutLocationId';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_ID = 'attendance.clockOutLocationName';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_NOTES = 'attendance.clockOutNotes';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_BY_SYSTEM = 'attendance.clockOutBySystem';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_STATUS = 'attendance.clockOutStatus';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTENDANCE_CLOCK_OUT_LOC_STATUS = 'attendance.clockOutLocationStatus';
export const RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_ID = 'requester.id';
export const RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_NAME = 'requester.fullName';
export const RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_EMPLOYEE_ID = 'requester.employeeId';
export const RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_DEPT = 'requester.department';
export const RXFIELD_ATTENDANCE_CORRECTION_REQUESTER_DIVISION = 'requester.division';
export const RXFIELD_ATTENDANCE_CORRECTION_CLOCK_IN = 'clockIn';
export const RXFIELD_ATTENDANCE_CORRECTION_CLOCK_OUT = 'clockOut';
export const RXFIELD_ATTENDANCE_CORRECTION_ATTACHMENT = 'attachment';
export const RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_STATUS = 'approvalStatus';
export const RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_STATUS_TIME = 'approvalStatusTime';
export const RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_ID = 'approvalId';
export const RXFIELD_ATTENDANCE_CORRECTION_APPROVAL_NUMBER = 'approvalNumber';
export const RXFIELD_ATTENDANCE_CORRECTION_PIC_ID = 'approvalPicId';
export const RXFIELD_ATTENDANCE_CORRECTION_PIC_NAME = 'approvalPicName';
export const RXFIELD_HOLIDAY_DAY = 'day';
export const RXFIELD_HOLIDAY_MONTH = 'month';
export const RXFIELD_HOLIDAY_YEAR = 'year';
export const RXFIELD_HOLIDAY_IMPORT_STATUS = 'importStatus';
export const RXFIELD_HOLIDAY_IMPORT_STATUS_NOTES = 'importStatusNotes';
export const RXFIELD_HOLIDAY_DESCRIPTION = 'description';
export const RXFIELD_HOLIDAY_FILE = 'file';
export const RXFIELD_PERSONAL_HOLIDAY_USER_ID = 'user.id';
export const RXFIELD_PERSONAL_HOLIDAY_USER_NAME = 'user.fullName';
export const RXFIELD_PERSONAL_HOLIDAY_USER_DEPARTMENT = 'user.department';
export const RXFIELD_PERSONAL_HOLIDAY_USER_DIVISION = 'user.division';
export const RXFIELD_PERSONAL_HOLIDAY_USER_EMPLOYEE_ID = 'user.employeeId';
export const RXFIELD_PERSONAL_HOLIDAY_START_DATE = 'startDate';
export const RXFIELD_PERSONAL_HOLIDAY_END_DATE = 'endDate';
export const RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS = 'importStatus';
export const RXFIELD_PERSONAL_HOLIDAY_IMPORT_STATUS_NOTES = 'importStatusNotes';
export const RXFIELD_PERSONAL_HOLIDAY_DESCRIPTION = 'description';
export const RXFIELD_PERSONAL_HOLIDAY_FILE = 'file';
export const RXFIELD_SUMMARY_USER = 'user';
export const RXFIELD_SUMMARY_PLANNED_TOTAL_WORKING_HOUR = 'plannedTotalWorkingHour';
export const RXFIELD_SUMMARY_ACTUAL_TOTAL_WORKING_HOUR = 'actualTotalWorkingHour';
export const RXFIELD_SUMMARY_LEAVE = 'leave';
export const RXFIELD_SUMMARY_WFA = 'wfa';
export const RXFIELD_SUMMARY_MONTH = 'month';
export const RXFIELD_SUMMARY_YEAR = 'year';
export const RXFIELD_WFA_APPROVAL_MAPPING_DEPARTMENT = 'departmentName';
export const RXFIELD_WFA_APPROVAL_MAPPING_APPROVER = 'approver';
export const RXFIELD_WFA_APPROVAL_MAPPING_USER = 'user';
export const RXFIELD_WFA_APPROVAL_MAPPING_TYPE = 'type';
export const RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE = 'file';

export const RXSTATE_WORKING_HOURS = 'workingHours';
export const RXSTATE_WORKING_HOUR_PAGE = 'uiWorkingHour';
export const RXSTATE_WORKING_HOUR_ADJUSTMENTS = 'workingHourAdjustments';
export const RXSTATE_WORKING_HOUR_ADJUSTMENT_PAGE = 'uiWorkingHourAdjustment';
export const RXSTATE_WFA_QUOTAS = 'wfaQuotas';
export const RXSTATE_WFA_QUOTA_PAGE = 'uiWFAQuota';
export const RXSTATE_WFA_REQUESTS = 'wfaRequests';
export const RXSTATE_WFA_REQUEST_PAGE = 'uiWfaRequest';
export const RXSTATE_WFAS = 'wfas';
export const RXSTATE_WFA_PAGE = 'uiWfa';
export const RXSTATE_ATTENDANCE_CORRECTIONS = 'attendanceCorrections';
export const RXSTATE_ATTENDANCE_CORRECTION_PAGE = 'uiAttendanceCorrection';
export const RXSTATE_HOLIDAYS = 'holidays';
export const RXSTATE_HOLIDAY_PAGE = 'uiHoliday';
export const RXSTATE_PERSONAL_HOLIDAYS = 'personalHolidays';
export const RXSTATE_PERSONAL_HOLIDAY_PAGE = 'uiPersonalHoliday';
export const RXSTATE_WFA_APPROVAL_MAPPINGS = 'wfaApprovalMappings';
export const RXSTATE_WFA_APPROVAL_MAPPING_PAGE = 'uiWfaApprovalMapping';

export const INITIAL_ORDER_BY_WORKING_HOURS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WORKING_HOUR_ADJUSTMENTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WFA_REQUESTS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WFA_QUOTAS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WFAS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_ATTENDANCE_CORRECTIONS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_HOLIDAYS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_PERSONAL_HOLIDAYS = `createdDate ${ORDER_SORT_DESCENDING}`;
export const INITIAL_ORDER_BY_WFA_APPROVAL_MAPPINGS = `createdDate ${ORDER_SORT_DESCENDING}`;

export const MENUID_ATTENDANCE_ADDON_WORKING_HOUR = 'MENUID_ATTENDANCE_ADDON_WORKING_HOUR';
export const MENUID_ATTENDANCE_ADDON_WORKING_HOUR_ADJUSTMENT = 'MENUID_ATTENDANCE_ADDON_WORKING_HOUR_ADJUSTMENT';
export const MENUID_ATTENDANCE_ADDON_WFA_REQUEST = 'MENUID_ATTENDANCE_ADDON_WFA_REQUEST';
export const MENUID_ATTENDANCE_ADDON_WFA_QUOTA = 'MENUID_ATTENDANCE_ADDON_WFA_QUOTA';
export const MENUID_ATTENDANCE_ADDON_WFA = 'MENUID_ATTENDANCE_ADDON_WFA';
export const MENUID_ATTENDANCE_ADDON_ATTENDANCE_CORRECTION = 'MENUID_ATTENDANCE_ADDON_ATTENDANCE_CORRECTION';
export const MENUID_ATTENDANCE_ADDON_PERSONAL_HOLIDAY = 'MENUID_ATTENDANCE_ADDON_PERSONAL_HOLIDAY';
export const MENUID_ATTENDANCE_ADDON_HOLIDAY = 'MENUID_ATTENDANCE_ADDON_HOLIDAY';
export const MENUID_ATTENDANCE_ADDON_SUMMARY = 'MENUID_ATTENDANCE_ADDON_SUMMARY';
export const MENUID_ATTENDANCE_ADDON_WFA_APPROVAL_MAPPING = 'MENUID_ATTENDANCE_ADDON_WFA_APPROVAL_MAPPING';

export const WFA_APPROVAL_MAPPING_TYPE_DEPARTMENT = 'Department';
export const WFA_APPROVAL_MAPPING_TYPE_USER = 'User';

export const WFA_STATUSES = ['Cancelled', 'Approved'];
export const IMPORT_STATUSES = ['Pending', 'Success', 'Error'];
export const WFA_APPROVAL_MAPPING_TYPES = [WFA_APPROVAL_MAPPING_TYPE_DEPARTMENT,
  WFA_APPROVAL_MAPPING_TYPE_USER];

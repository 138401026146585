import moment from 'moment';
import {
  downloadingAttendanceAddonSummary, setAttendanceAddonSummary,
} from '../simple-action';
import { downloadAttendanceAddonSummary } from '../../../helper';

export default () => async (dispatch, getState) => {
  try {
    dispatch(downloadingAttendanceAddonSummary(true));

    const { token } = getState().authentication;
    const {
      tappedId, selectedMonth, selectedYear,
    } = getState().uiAttendanceAddonSummary;

    const month = moment.months().indexOf(selectedMonth) + 1;
    const userId = tappedId || '';

    const response = await downloadAttendanceAddonSummary(
      month, selectedYear, userId, token,
    );

    dispatch(setAttendanceAddonSummary(response));
  } finally {
    dispatch(downloadingAttendanceAddonSummary(false));
  }
};

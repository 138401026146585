import { INITIAL_ORDER_BY_WFA_APPROVAL_MAPPINGS } from '../../constant';
import {
  ADDING_EDITING_WFA_APPROVAL_MAPPING, DOWNLOADING_DELETING_WFA_APPROVAL_MAPPING,
  DOWNLOADING_WFA_APPROVAL_MAPPINGS, IMPORTING_WFA_APPROVAL_MAPPING_FILE,
  SET_WFA_APPROVAL_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  SET_WFA_APPROVAL_MAPPING_SEARCH_TEXT, SET_WFA_APPROVAL_MAPPING_SELECTED_APPROVER,
  SET_WFA_APPROVAL_MAPPING_SELECTED_DEPARTMENT, SET_WFA_APPROVAL_MAPPING_SELECTED_ORDER_BY,
  SET_WFA_APPROVAL_MAPPING_SELECTED_PAGE_SIZE, SET_WFA_APPROVAL_MAPPING_SELECTED_TYPE,
  SET_WFA_APPROVAL_MAPPING_SELECTED_USER, SET_WFA_APPROVAL_MAPPING_TAPPED_ID,
  SET_WFA_APPROVAL_MAPPING_SELECTED_IMPORT_FILE,
} from '../action';

const initialState = {
  addingEditing: false,
  downloadingDeleting: false,
  downloading: false,
  importing: false,
  selectedPageSize: 20,
  tappedId: '',
  searchBarText: '',
  filterString: '',
  orderBy: INITIAL_ORDER_BY_WFA_APPROVAL_MAPPINGS,
  selectedDepartment: '',
  selectedApprover: '',
  selectedType: '',
  selectedUser: '',
  selectedFile: '',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case DOWNLOADING_WFA_APPROVAL_MAPPINGS:
      return { ...state, downloading: action.status };
    case ADDING_EDITING_WFA_APPROVAL_MAPPING:
      return { ...state, addingEditing: action.status };
    case DOWNLOADING_DELETING_WFA_APPROVAL_MAPPING:
      return { ...state, downloadingDeleting: action.status };
    case IMPORTING_WFA_APPROVAL_MAPPING_FILE:
      return { ...state, importing: action.status };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_PAGE_SIZE:
      return { ...state, selectedPageSize: action.size };
    case SET_WFA_APPROVAL_MAPPING_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_WFA_APPROVAL_MAPPING_SEARCH_TEXT:
      return { ...state, searchBarText: action.text };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_ORDER_BY:
      return { ...state, orderBy: action.order };
    case SET_WFA_APPROVAL_MAPPING_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_DEPARTMENT:
      return { ...state, selectedDepartment: action.option };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_APPROVER:
      return { ...state, selectedApprover: action.option };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_TYPE:
      return { ...state, selectedType: action.option };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_USER:
      return { ...state, selectedUser: action.option };
    case SET_WFA_APPROVAL_MAPPING_SELECTED_IMPORT_FILE:
      return { ...state, selectedFile: action.file };
    default:
      return state;
  }
};

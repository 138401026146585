import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Avatar, CircularProgress, Grid, TextField,
} from '@material-ui/core';
import {
  FunctionalPage, ImageInputField, NumberTextFieldWithoutArrowDial, SectionTitle,
} from '../../component';
import {
  approval, DATE_TIME_FORMAT_WITHOUT_PIPE, IMAGE_SOURCE_URI_PREFIX, PAGE_MODE_VIEW, REST_BASE_URL,
  REST_URL_IMAGE_STORAGE, RXFIELD_CHALLENGE_CATEGORY, RXFIELD_CHALLENGE_CODES,
  RXFIELD_CHALLENGE_CONTENT, RXFIELD_CHALLENGE_IS_PROMOTED, RXFIELD_CHALLENGE_MEDIA,
  RXFIELD_CHALLENGE_REACTION_ID, RXFIELD_CHALLENGE_ORDER, RXFIELD_CHALLENGE_SCAN_POLICY,
  RXFIELD_CHALLENGE_TAGS, RXFIELD_CHALLENGE_TITLE, RXFIELD_CHALLENGE_VALID_FROM,
  RXFIELD_CHALLENGE_VALID_UNTIL, RXFIELD_CHALLENGE_VISIBILITY_STATUS, RXFORM_CHALLENGE,
  RXSTATE_CHALLENGES, RXSTATE_CHALLENGE_PAGE, SCAN_POLICIES, visibilityStatuses,
} from '../../constant';
import { toMoment } from '../../helper';
import LocalizedString from '../../localization';
import {
  renderReduxFormEditableTableField, renderReduxFormOutlinedTextField,
  renderReduxFormOutlinedDropdownTextField, renderReduxFormDateTimePickerField,
  renderReduxFormRichTextEditorField, renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';
import { rxformValidateChallenge } from '../../validation';

const renderDialogContent = (initialValues, categories, addingEditing, downloadingDeleting,
  loadingCategory, onAddCodePressed, onAddMediaMenuSelected, onAddMediaPressed,
  onCategoryOptionSelected, onChangeCategoryText, onChangeContentText, onDeleteCodePressed,
  onDeleteMediaPressed, onScanPolicyOptionSelected, onVisibilityStatusOptionSelected, pageMode,
  selectedAddMediaMenu, onIsPromotedSelected, isPromoted) => (
    <Grid>
      <Grid container spacing={3}>
        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_TITLE}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.challengePage.placeholderTitle}
              label={LocalizedString.challengePage.placeholderTitle}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_IS_PROMOTED}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.challengePage.placeholderPromoted}
              label={LocalizedString.challengePage.placeholderPromoted}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={approval}
              value={initialValues.isPromoted}
              onOptionSelected={onIsPromotedSelected}
              required
              onBlur={(e) => e.preventDefault()}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_ORDER}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.challengePage.placeholderOrder}
              label={LocalizedString.challengePage.placeholderOrder}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              hidden={!isPromoted}
              type="number"
              required={isPromoted}
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_VISIBILITY_STATUS}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.challengePage.placeholderVisibilityStatus}
              label={LocalizedString.challengePage.placeholderVisibilityStatus}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={visibilityStatuses}
              value={initialValues.visibilityStatus}
              onOptionSelected={onVisibilityStatusOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_TAGS}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.challengePage.placeholderTags}
              label={LocalizedString.challengePage.placeholderTags}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              multiline
            />
          </Grid>
        </Grid>

        <Grid item sm md>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_CATEGORY}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.challengePage.placeholderCategory}
              label={LocalizedString.challengePage.placeholderCategory}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={categories}
              value={initialValues.category.label}
              loading={loadingCategory}
              onChangeText={onChangeCategoryText}
              onOptionSelected={onCategoryOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_VALID_FROM}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.challengePage.placeholderValidFrom}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_VALID_UNTIL}
              component={renderReduxFormDateTimePickerField}
              label={LocalizedString.challengePage.placeholderValidUntil}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_SCAN_POLICY}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.challengePage.placeholderScanPolicy}
              label={LocalizedString.challengePage.placeholderScanPolicy}
              disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
              data={SCAN_POLICIES}
              value={initialValues.scanPolicy}
              onOptionSelected={onScanPolicyOptionSelected}
              required
            />
          </Grid>
          <Grid item>
            <Field
              name={RXFIELD_CHALLENGE_REACTION_ID}
              component={renderReduxFormOutlinedTextField}
              placeholder={LocalizedString.challengePage.placeholderReactionId}
              label={LocalizedString.challengePage.placeholderReactionId}
              disabled
              hidden={pageMode !== PAGE_MODE_VIEW}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item sm={12}>
        <Field
          name={RXFIELD_CHALLENGE_CONTENT}
          component={renderReduxFormRichTextEditorField}
          label={LocalizedString.challengePage.placeholderContent}
          disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
          onChangeText={onChangeContentText}
          loading={downloadingDeleting}
          required
        />
      </Grid>

      <SectionTitle title={LocalizedString.challengePage.labelMedia} />

      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_CHALLENGE_MEDIA}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.challengePage.labelMedia}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            loading={downloadingDeleting}
            onAddPressed={onAddMediaPressed}
            onDeletePressed={onDeleteMediaPressed}
            defaultValue={initialValues.media}
            addMenuList={[
              {
                caption: LocalizedString.challengePage.buttonCaptionYoutube,
                onPress: () => onAddMediaMenuSelected('Youtube'),
              },
              {
                caption: LocalizedString.challengePage.buttonCaptionImage,
                onPress: () => onAddMediaMenuSelected('Image'),
              },
            ]}
            requiredTableColumnField={['order']}
            optionalTableColumnField={['content', 'link']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.challengePage.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.challengePage.labelOrder}
                    label={LocalizedString.challengePage.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.challengePage.labelYoutube,
                field: 'link',
                sorting: false,
                editable: selectedAddMediaMenu === 'Youtube' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Youtube' && !prop.tableData.editing) {
                        const path = prop.path.includes(`${REST_BASE_URL}${REST_URL_IMAGE_STORAGE}`) ? prop.path.slice(68) : prop.path;
                        return path;
                      }
                      if ((prop.type === 'Youtube' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Youtube');
                      }
                    }
                    if (prop.link) {
                      return prop.link.path;
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.challengePage.labelYoutube}
                    label={LocalizedString.challengePage.labelYoutube}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange({ path: e.target.value, type: 'Youtube' })}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.challengePage.labelImage,
                field: 'content',
                sorting: false,
                editable: selectedAddMediaMenu === 'Image' ? 'onAdd' : 'never',
                render: (prop) => {
                  if (prop) {
                    if (prop.path) {
                      if (prop.type === 'Image' && !prop.tableData.editing) {
                        return (<Avatar variant="square" src={prop.path} />);
                      }
                      if ((prop.type === 'Image' || Object.prototype.hasOwnProperty.call(prop, 'path')) && prop.tableData.editing) {
                        onAddMediaMenuSelected('Image');
                      }
                    }
                    if (prop.content) {
                      return (
                        <Avatar
                          variant="square"
                          src={IMAGE_SOURCE_URI_PREFIX.concat(prop.content.path)}
                        />
                      );
                    }
                    return null;
                  }
                  return null;
                },
                // eslint-disable-next-line react/prop-types
                editComponent: ({ onChange }) => (
                  <ImageInputField
                    useCropper={false}
                    onImageSelected={(path) => onChange({ path, type: 'Image' })}
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}

      <SectionTitle title={LocalizedString.challengePage.labelCodes} />

      {downloadingDeleting ? (<CircularProgress color="inherit" />) : (
        <Grid item sm={12}>
          <Field
            name={RXFIELD_CHALLENGE_CODES}
            component={renderReduxFormEditableTableField}
            label={LocalizedString.challengePage.labelCodes}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            loading={downloadingDeleting}
            onAddPressed={onAddCodePressed}
            onDeletePressed={onDeleteCodePressed}
            defaultValue={initialValues.codes}
            requiredTableColumnField={['order', 'points', 'label']}
            disableEdit
            disableToolbar={pageMode === PAGE_MODE_VIEW}
            tableColumns={[
              {
                title: LocalizedString.challengePage.labelOrder,
                field: 'order',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.challengePage.labelOrder}
                    label={LocalizedString.challengePage.labelOrder}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.challengePage.labelCode,
                field: 'code',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.challengePage.labelCode}
                    label={LocalizedString.challengePage.labelCode}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.challengePage.labelPoints,
                field: 'points',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <NumberTextFieldWithoutArrowDial
                    placeholder={LocalizedString.challengePage.labelPoints}
                    label={LocalizedString.challengePage.labelPoints}
                    variant="outlined"
                    type="number"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
              {
                title: LocalizedString.challengePage.labelLabel,
                field: 'label',
                sorting: false,
                editable: 'onAdd',
                // eslint-disable-next-line react/prop-types
                editComponent: ({ value, onChange }) => (
                  <TextField
                    placeholder={LocalizedString.challengePage.labelLabel}
                    label={LocalizedString.challengePage.labelLabel}
                    variant="outlined"
                    defaultValue={value}
                    onChange={(e) => onChange(e.target.value)}
                    fullWidth
                  />
                ),
              },
            ]}
            required
          />
        </Grid>
      )}
    </Grid>
);
const ChallengePage = ({
  initialValues, categories,
  addingEditing, downloading, downloadingDeleting, loadingCategory,
  handleSubmit, onAddCodePressed, onAddMediaPressed, onAddMediaMenuSelected, onAppear,
  onCancelPressed, onCategoryOptionSelected, onChangeCategoryText, onChangeContentText,
  onChangePage, onChangePageSize, onConfirmDeletePressed, onCreatePressed,
  onDeleteCodePressed, onDeleteMediaPressed, onDeletePressed, onEditPressed, onRefresh,
  onSavePressed, onScanPolicyOptionSelected, onSearchBarTextChanged, onSortPressed,
  onSubmitPressed, onViewPressed, onVisibilityStatusOptionSelected,
  pageMode, selectedAddMediaMenu, onIsPromotedSelected, isPromoted,
}) => (
  <FunctionalPage
    data={RXSTATE_CHALLENGES}
    uiPage={RXSTATE_CHALLENGE_PAGE}
    tableColumns={[
      {
        title: LocalizedString.challengePage.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.challengePage.labelTitle, field: 'title', sorting: !downloading },
      { title: LocalizedString.challengePage.labelCategory, field: 'category.name', sorting: !downloading },
      {
        title: LocalizedString.challengePage.labelValidFrom,
        field: 'validFrom',
        render: ({ validFrom }) => (validFrom
          ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        sorting: !downloading,
      },
      {
        title: LocalizedString.challengePage.labelValidUntil,
        field: 'validUntil',
        render: ({ validUntil }) => (validUntil
          ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
        sorting: !downloading,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.challengePage.buttonCaptionCreateNewChallenge}
    deleteButtonCaption={LocalizedString.challengePage.buttonCaptionDeleteChallenge}
    editButtonCaption={LocalizedString.challengePage.buttonCaptionEditChallenge}
    title={LocalizedString.challengePage.title}
    useFullWidth
    createPermissionName="GAMEQR_CREATE_CHALLENGE"
    deletePermissionName="GAMEQR_DELETE_CHALLENGE"
    editPermissionName="GAMEQR_EDIT_CHALLENGE"
  >
    {renderDialogContent(initialValues, categories, addingEditing, downloadingDeleting,
      loadingCategory, onAddCodePressed, onAddMediaMenuSelected, onAddMediaPressed,
      onCategoryOptionSelected, onChangeCategoryText, onChangeContentText, onDeleteCodePressed,
      onDeleteMediaPressed, onScanPolicyOptionSelected, onVisibilityStatusOptionSelected, pageMode,
      selectedAddMediaMenu, onIsPromotedSelected, isPromoted)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_CHALLENGE,
  validate: rxformValidateChallenge,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ChallengePage);

ChallengePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  categories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingCategory: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAddCodePressed: PropTypes.func.isRequired,
  onAddMediaPressed: PropTypes.func.isRequired,
  onAddMediaMenuSelected: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCategoryOptionSelected: PropTypes.func.isRequired,
  onChangeCategoryText: PropTypes.func.isRequired,
  onChangeContentText: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeleteCodePressed: PropTypes.func.isRequired,
  onDeleteMediaPressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onScanPolicyOptionSelected: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  selectedAddMediaMenu: PropTypes.string,
  onIsPromotedSelected: PropTypes.func.isRequired,
  isPromoted: PropTypes.bool.isRequired,
};

ChallengePage.defaultProps = {
  selectedAddMediaMenu: null,
};

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import { isSupported } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAmBVkDNoBSlPeRjDrCIc4xFi5exSr9-r0',
  authDomain: 'plnbb-1b52f.firebaseapp.com',
  projectId: 'plnbb-1b52f',
  storageBucket: 'plnbb-1b52f.appspot.com',
  messagingSenderId: '32499752507',
  appId: '1:32499752507:web:939c3d8d5895cab57d461e',
  measurementId: 'G-8319R3WGD1',
};

// eslint-disable-next-line import/prefer-default-export
export const getFirebaseAnalytics = async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (isSupportedBrowser) {
      const app = initializeApp(firebaseConfig);
      return getAnalytics(app);
    }
    return null;
  } catch (err) {
    return null;
  }
};

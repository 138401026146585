import { change } from 'redux-form';
import { importWfaApprovalMappingFile } from '../../../helper';
import {
  clearWfaApprovalMappings, importingWfaApprovalMappingFile, setAlertErrorMessage,
  setWfaApprovalMappingSelectedImportFile,
} from '../simple-action';
import { RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE } from '../../../constant';
import downloadWfaApprovalMappingsAsync from './downloadWfaApprovalMappingsAsync';

export default () => async (dispatch, getState) => {
  try {
    dispatch(importingWfaApprovalMappingFile(true));

    const { token } = getState().authentication;
    const { selectedFile } = getState().uiWfaApprovalMapping;

    await importWfaApprovalMappingFile(selectedFile, token);
    dispatch(setWfaApprovalMappingSelectedImportFile(''));
    dispatch(change(RXFORM_WFA_APPROVAL_MAPPING, RXFIELD_WFA_APPROVAL_MAPPING_IMPORT_FILE, ''));
    dispatch(clearWfaApprovalMappings());
    dispatch(downloadWfaApprovalMappingsAsync(1))
      .catch((err) => dispatch(setAlertErrorMessage(err)));
  } finally {
    dispatch(importingWfaApprovalMappingFile(false));
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  Grid, makeStyles, Avatar, Typography, InputAdornment, IconButton, Snackbar,
} from '@material-ui/core';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import { StarOutline, ClockOutline } from 'mdi-material-ui';
import { CloudDownload, FileCopyOutlined } from '@material-ui/icons';
import { isEmpty } from 'lodash';
import CopyToClipboard from 'react-copy-to-clipboard';
import { FunctionalPage, AccentButton, SectionTitle } from '../../component';
import {
  PAGE_MODE_VIEW,
  RXSTATE_QUIZES, RXSTATE_QUIZ_PAGE, RXFORM_QUIZ,
  DATE_TIME_FORMAT_WITHOUT_PIPE, FILTER_TYPE_DATE_RANGE,
  FILTER_TYPE_DROPDOWN, PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
  PICKER_MODE_DATE_TIME, RXFIELD_QUIZ_CATEGORY, RXFIELD_QUIZ_DESCRIPTION,
  RXFIELD_QUIZ_DURATION, RXFIELD_QUIZ_TITLE,
  RXFIELD_QUIZ_VALID_FROM, RXFIELD_QUIZ_VALID_UNTIL,
  RXFIELD_QUIZ_VISIBILITY_STATUS, visibilityStatuses,
  COLOR_BACKGROUND, COLOR_DISABLED_ROW,
  RXFIELD_QUIZ_PARTICIPANT_COUNT,
  RXFIELD_QUIZ_RESULT, COLOR_PRIMARY,
  COLOR_ICON_LIGHTER, RXFIELD_QUIZ_DEEPLINK,
  snackbarDuration,
} from '../../constant';
import {
  renderReduxFormDateTimePickerField,
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
  renderReduxFormEditableTableField,
} from '../../../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateQuiz } from '../../validation';
import { toMoment } from '../../helper';
import { FormInitialValueShape, SimpleDataShape } from '../../../../type';

const rank1Trophy = require('../../../../asset/rank1.png');
const rank2Trophy = require('../../../../asset/rank2.png');
const rank3Trophy = require('../../../../asset/rank3.png');

const theme = createMuiTheme();

theme.typography.h5 = {
  fontSize: '1rem',
  '@media (min-width:600px)': {
    fontSize: '1rem',
  },
  [theme.breakpoints.up('md')]: {
    fontSize: '2rem',
  },
};

const useStyles = makeStyles(() => ({
  buttonsContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  horizontalSpacer: {
    width: 10,
  },
  saveButtonWrapper: {
    marginLeft: 10,
  },
  saveButtonLabel: {
    width: '0%',
  },
  buttonContainer: {
    margin: '10px 0px 10px 0px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  italicButton: {
    fontStyle: 'italic',
    color: COLOR_PRIMARY,
    marginRight: 10,
  },
  top1Container: {
    borderColor: COLOR_PRIMARY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 2px',
    height: '100%',
  },
  top3Container: {
    borderColor: COLOR_PRIMARY,
    borderWidth: 1,
    borderStyle: 'solid',
    borderRadius: 20,
    alignItems: 'center',
    justifyContent: 'center',
    padding: '20px 2px',
    marginTop: 10,
    height: '90%',
  },
  top3LabelContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginTop: 10,
  },
  boldText: {
    fontWeight: 'bold',
  },
  icon: {
    color: COLOR_PRIMARY,
    background: COLOR_ICON_LIGHTER,
  },
  avatar: {
    width: theme.spacing(10),
    height: theme.spacing(10),
  },
  top3RankDescriptionText: {
    fontWeight: 'bold',
    textAlign: 'left',
  },
  leftAlignedText: {
    textAlign: 'left',
  },
}));

const renderTop3RankItem = (value, label, Icon, classes) => (
  <Grid container justify="center" align="stretch">
    <Grid item container direction="row" xs sm md>
      <Grid item xs={6}>
        <Icon />
      </Grid>
      <Grid item xs={6}>
        <Typography variant="body2" className={classes.leftAlignedText}>{label}</Typography>
      </Grid>
    </Grid>
    <Grid item xs sm md>
      <Typography variant="body2">:</Typography>
    </Grid>
    <Grid item xs sm md>
      <Typography variant="body2" className={classes.top3RankDescriptionText}>
        {`${value}`}
      </Typography>
    </Grid>
  </Grid>
);

const renderDialogContent = (
  addingEditing, downloadingDeleting, pageMode,
  defaultVisibilityStatusValue, onVisibilityStatusOptionSelected,
  defaultQuizCategoryLabel, quizCategories, loadingQuizCategories,
  onChangeQuizCategoryText, onQuizCategoryOptionSelected,
  classes, onShowParticipantPressed, tappedId,
  history, initialValues, onDownloadPressed,
  saving, copying, onCopyPressed,
) => (
  <Grid>
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_TITLE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizScreen.placeholderTitle}
            label={LocalizedString.quizScreen.placeholderTitle}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_DESCRIPTION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizScreen.placeholderDescription}
            label={LocalizedString.quizScreen.placeholderDescription}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_CATEGORY}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.quizScreen.placeholderCategory}
            label={LocalizedString.quizScreen.placeholderCategory}
            data={quizCategories}
            loading={loadingQuizCategories}
            value={defaultQuizCategoryLabel}
            onChangeText={onChangeQuizCategoryText}
            onOptionSelected={onQuizCategoryOptionSelected}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_DURATION}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizScreen.placeholderDuration}
            label={LocalizedString.quizScreen.labelDuration}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
            required
          />
        </Grid>
      </Grid>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_VALID_FROM}
            component={renderReduxFormDateTimePickerField}
            pickerMode={PICKER_MODE_DATE_TIME}
            label={LocalizedString.quizScreen.placeholderValidFrom}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_VALID_UNTIL}
            component={renderReduxFormDateTimePickerField}
            pickerMode={PICKER_MODE_DATE_TIME}
            label={LocalizedString.quizScreen.placeholderValidUntil}
            disabled={addingEditing || downloadingDeleting || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_VISIBILITY_STATUS}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.quizScreen.placeholderVisibilityStatus}
            label={LocalizedString.quizScreen.placeholderVisibilityStatus}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={visibilityStatuses}
            value={defaultVisibilityStatusValue}
            onOptionSelected={onVisibilityStatusOptionSelected}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_DEEPLINK}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizScreen.placeholderDeeplink}
            label={LocalizedString.quizScreen.placeholderDeeplink}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            multiline
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton onClick={() => onCopyPressed(copying)} edge="end">
                    <CopyToClipboard text={initialValues.deepLink}>
                      <FileCopyOutlined style={{ fill: COLOR_PRIMARY }} />
                    </CopyToClipboard>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_QUIZ_PARTICIPANT_COUNT}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.quizScreen.placeholderParticipantCount}
            label={LocalizedString.quizScreen.placeholderParticipantCount}
            disabled
            hidden={pageMode !== PAGE_MODE_VIEW}
          />
        </Grid>
        {pageMode === PAGE_MODE_VIEW && (
          <Grid item>
            <div className={classes.buttonsContainer}>
              <AccentButton
                onClick={() => onShowParticipantPressed(tappedId, initialValues.title, history)}
                caption={LocalizedString.quizScreen.buttonCaptionShowParticipant}
                disabled={!initialValues[RXFIELD_QUIZ_PARTICIPANT_COUNT]}
              />
              <div className={classes.horizontalSpacer} />
              <AccentButton
                onClick={onDownloadPressed}
                startIcon={<CloudDownload />}
                caption=""
                disabled={addingEditing || downloadingDeleting
                  || !initialValues[RXFIELD_QUIZ_PARTICIPANT_COUNT]}
                loading={saving}
                variant="outlined"
                classes={{
                  startIcon: classes.saveButtonWrapper,
                  label: classes.saveButtonLabel,
                  root: classes.saveButtonWrapper,
                }}
              />
            </div>
          </Grid>
        )}
      </Grid>
      <Snackbar
        open={copying}
        autoHideDuration={snackbarDuration}
        message={LocalizedString.quizScreen.labelCopied}
        onClose={() => onCopyPressed(copying)}
      />
    </Grid>

    {pageMode === PAGE_MODE_VIEW && !isEmpty(initialValues.result)
    && (<SectionTitle title={LocalizedString.quizScreen.labelQuizResult} />)}

    {pageMode === PAGE_MODE_VIEW && initialValues.result && (
    <ThemeProvider theme={theme}>
      <Grid container spacing={3} align="center" justify="center">
        {initialValues.top3.map((item) => {
          let icon;
          if (item.rank === 1)icon = rank1Trophy;
          if (item.rank === 2)icon = rank2Trophy;
          if (item.rank === 3)icon = rank3Trophy;
          return (
            <Grid item xs sm md key={item.rank}>
              <Grid
                item
                className={item.rank === 1 ? classes.top1Container : classes.top3Container}
              >
                <Avatar
                  src={icon}
                  className={classes.avatar}
                />

                <Typography variant="h5" className={classes.boldText}>
                  {item.userName}
                </Typography>

                {renderTop3RankItem(item.score, LocalizedString.quizScreen.labelScore,
                  StarOutline, classes)}

                {renderTop3RankItem(item.duration, LocalizedString.quizScreen.labelDuration,
                  ClockOutline, classes)}
              </Grid>
            </Grid>
          );
        })}

        <Grid item xs={12} sm={12} md={12}>
          <Field
            name={RXFIELD_QUIZ_RESULT}
            component={renderReduxFormEditableTableField}
            disabled
            defaultValue={initialValues.result}
            tableColumns={[
              { title: LocalizedString.quizScreen.labelRank, field: 'rank', sorting: false },
              { title: LocalizedString.quizScreen.labelUserName, field: 'userName', sorting: false },
              { title: LocalizedString.quizScreen.labelScore, field: 'score', sorting: false },
              { title: LocalizedString.quizScreen.labelDuration, field: 'duration', sorting: false },
            ]}
            disableToolbar
          />
        </Grid>
      </Grid>
    </ThemeProvider>
    )}
  </Grid>
);

const QuizPage = ({
  initialValues, addingEditing, downloading, downloadingDeleting,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed, quizCategories, loadingQuizCategories, onChangeQuizCategoryText,
  onVisibilityStatusOptionSelected, onQuizCategoryOptionSelected,
  onAdvancedFilterPressed, onApplyAdvancedFilterPressed, onResetAdvancedFilterPressed,
  onCancelAdvancedFilterPressed, onShowParticipantPressed, tappedId,
  history, onDownloadPressed, saving, sendingNotification, onSendToMePressed,
  copying, onCopyPressed,
}) => {
  const classes = useStyles();

  return (
    <FunctionalPage
      data={RXSTATE_QUIZES}
      uiPage={RXSTATE_QUIZ_PAGE}
      tableColumns={[
        {
          title: LocalizedString.quizScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
        },
        { title: LocalizedString.quizScreen.labelTitle, field: 'title', sorting: !downloading },
        {
          title: LocalizedString.quizScreen.labelValidFrom,
          field: 'validFrom',
          render: ({ validFrom }) => (validFrom
            ? toMoment(validFrom).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          sorting: !downloading,
        },
        {
          title: LocalizedString.quizScreen.labelValidUntil,
          field: 'validUntil',
          render: ({ validUntil }) => (validUntil
            ? toMoment(validUntil).format(DATE_TIME_FORMAT_WITHOUT_PIPE) : null),
          sorting: !downloading,
        },
        { title: LocalizedString.quizScreen.labelDuration, field: 'durationMinutes', sorting: !downloading },
        { title: LocalizedString.quizScreen.labelCategory, field: 'category.name', sorting: !downloading },
        { title: LocalizedString.quizScreen.labelVisibilityStatus, field: 'visibilityStatus', sorting: !downloading },
      ]}
      filterColumns={[
        {
          title: LocalizedString.quizScreen.placeholderValidFrom,
          field: RXFIELD_QUIZ_VALID_FROM,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.quizScreen.placeholderValidUntil,
          field: RXFIELD_QUIZ_VALID_UNTIL,
          type: FILTER_TYPE_DATE_RANGE,
          pickerMode: PICKER_MODE_DATE_TIME,
          format: PICKER_DATE_TIME_WITHOUT_SECOND_FORMAT,
        },
        {
          title: LocalizedString.quizScreen.placeholderCategory,
          field: RXFIELD_QUIZ_CATEGORY,
          type: FILTER_TYPE_DROPDOWN,
          data: quizCategories,
          loading: loadingQuizCategories,
          onChangeFilterText: onChangeQuizCategoryText,
          useDropdownValue: true,
        },
        {
          title: LocalizedString.quizScreen.placeholderVisibilityStatus,
          field: RXFIELD_QUIZ_VISIBILITY_STATUS,
          type: FILTER_TYPE_DROPDOWN,
          data: visibilityStatuses,
        },
      ]}
      handleSubmit={handleSubmit}
      onAdvancedFilterPressed={onAdvancedFilterPressed}
      onAppear={onAppear}
      onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
      onCancelPressed={onCancelPressed}
      onCancelAdvancedFilterPressed={onCancelAdvancedFilterPressed}
      onChangePage={onChangePage}
      onChangePageSize={onChangePageSize}
      onConfirmDeletePressed={onConfirmDeletePressed}
      onCreatePressed={onCreatePressed}
      onDeletePressed={onDeletePressed}
      onEditPressed={onEditPressed}
      onRefresh={onRefresh}
      onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
      onSavePressed={onSavePressed}
      onSearchBarTextChanged={onSearchBarTextChanged}
      onSortPressed={onSortPressed}
      onSubmitPressed={onSubmitPressed}
      onViewPressed={onViewPressed}
      createNewButtonCaption={LocalizedString.quizScreen.buttonCaptionCreateNewQuiz}
      editButtonCaption={LocalizedString.quizScreen.buttonCaptionEditQuiz}
      deleteButtonCaption={LocalizedString.quizScreen.buttonCaptionDeleteQuiz}
      title={LocalizedString.quizScreen.title}
      useFullWidth
      rowStyle={(rowData) => ({
        backgroundColor: rowData.visibilityStatus === visibilityStatuses[0]
          ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
      })}
      createPermissionName="SURVEY_CREATE_QUIZ"
      editPermissionName="SURVEY_EDIT_QUIZ"
      deletePermissionName="SURVEY_DELETE_QUIZ"
    >
      {pageMode === PAGE_MODE_VIEW && (
        <div className={classes.buttonContainer}>
          <AccentButton
            variant="text"
            className={classes.italicButton}
            disabled={downloadingDeleting || addingEditing}
            loading={sendingNotification}
            disableElevation
            caption={LocalizedString.quizScreen.buttonCaptionSendToMe}
            onClick={onSendToMePressed}
            size="small"
          />
        </div>
      )}
      {renderDialogContent(
        addingEditing, downloadingDeleting, pageMode,
        initialValues.visibilityStatus, onVisibilityStatusOptionSelected,
        initialValues.category.name, quizCategories, loadingQuizCategories,
        onChangeQuizCategoryText, onQuizCategoryOptionSelected,
        classes, onShowParticipantPressed, tappedId,
        history, initialValues, onDownloadPressed,
        saving, copying, onCopyPressed,
      )}
    </FunctionalPage>
  );
};

export default reduxForm({
  form: RXFORM_QUIZ,
  validate: rxformValidateQuiz,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(QuizPage);

QuizPage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  history: PropTypes.object.isRequired,
  quizCategories: PropTypes.arrayOf(SimpleDataShape).isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  downloadingDeleting: PropTypes.bool.isRequired,
  loadingQuizCategories: PropTypes.bool.isRequired,
  saving: PropTypes.bool.isRequired,
  sendingNotification: PropTypes.bool.isRequired,
  copying: PropTypes.bool.isRequired,
  onCopyPressed: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAdvancedFilterPressed: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onCancelAdvancedFilterPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onChangeQuizCategoryText: PropTypes.func.isRequired,
  onQuizCategoryOptionSelected: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  onVisibilityStatusOptionSelected: PropTypes.func.isRequired,
  onShowParticipantPressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onSendToMePressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  Dialog,
  IconButton,
  Typography, makeStyles,
  TablePagination,
} from '@material-ui/core';
import { Tune } from '@material-ui/icons';
import LocalizedString from '../../localization';
import GlobalizedLocalizedString from '../../../../localization';
import {
  COLOR_PRIMARY, PICKER_MODE_DATE, SIMPLE_DATE_FORMAT, COLOR_ICON_LIGHTER,
  COLOR_BODY_TEXT,
  COLOR_BACKGROUND_SECONDARY,
} from '../../../../constant';
import { AccentButton, DateTimePickerField } from '../../../../component';
import { toMoment } from '../../../../helper';

const useStyles = makeStyles(() => ({
  w500: {
    fontWeight: '500',
  },
  mb10: {
    marginBottom: 10,
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  flex1: {
    flex: 1,
    display: 'flex',
  },
  flexEnd: {
    justifyContent: 'flex-end',
  },
  loading: {
    marginTop: 20,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
  },
  filterButtonActions: {
    gap: '10px',
    marginRight: 8,
    marginTop: 10,
  },
  itemTitle: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  colorPrimary: {
    backgroundColor: COLOR_ICON_LIGHTER,
  },
  barColorPrimary: {
    backgroundColor: COLOR_PRIMARY,
  },
  buttonOutline: {
    color: COLOR_BODY_TEXT,
    borderWidth: 1,
    borderRadius: 50,
    borderColor: COLOR_PRIMARY,
  },
  grayBackground: {
    backgroundColor: COLOR_BACKGROUND_SECONDARY,
  },
  tableHeader: {
    textTransform: 'uppercase',
    borderBottomStyle: 'dashed',
    fontWeight: 500,
  },
}));
const WebsiteUserEngagements = ({
  websiteUserEngagements, downloadingWebsiteUserEngagements, websiteUserEngagementsDateRange,
  websiteUserEngagementsFilterDialogVisibility, totalPages, onFilterIconPressed,
  onCloseDialogPressed, onApplyFilterPressed, onResetFilterPressed,
}) => {
  const [dateRange, setDateRange] = useState(websiteUserEngagementsDateRange);
  const [page, setPage] = useState(0);
  const rowsPerPage = 9;
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
  const classes = useStyles();
  useEffect(() => {
    setDateRange({
      from: websiteUserEngagementsDateRange.from,
      to: websiteUserEngagementsDateRange.to,
    });
  }, [websiteUserEngagementsDateRange]);
  return (
    <>
      <div className={`${classes.row} ${classes.mb10}`}>
        <Typography
          variant="body1"
          className={`${classes.w500} ${classes.flex1}`}
        >
          {`${LocalizedString.analyticsScreen.labelWebsiteUserEngagements} (${toMoment(websiteUserEngagementsDateRange.from).format(SIMPLE_DATE_FORMAT)} - ${toMoment(websiteUserEngagementsDateRange.to).format(SIMPLE_DATE_FORMAT)})`}
        </Typography>
        <IconButton edge="end" onClick={onFilterIconPressed}>
          <Tune style={{ fill: COLOR_PRIMARY }} />
        </IconButton>
      </div>
      <div>
        <div className={`${classes.mb10} ${classes.mt10}`}>
          <div className={`${classes.row} ${classes.spaceBetween}`}>
            <div style={{ flex: 1 }}>
              <span className={classes.tableHeader}>
                {LocalizedString.analyticsScreen.labelTitle}
              </span>
            </div>
            <div className={`${classes.flexEnd} ${classes.flex1}`}>
              <span className={classes.tableHeader}>
                {LocalizedString.analyticsScreen.labelURL}
              </span>
            </div>
            <div className={`${classes.flexEnd} ${classes.flex1}`}>
              <span className={classes.tableHeader}>
                {LocalizedString.analyticsScreen.labelTotal}
              </span>
            </div>
          </div>
        </div>
      </div>
      {downloadingWebsiteUserEngagements ? (
        <div className={classes.loading}>
          <CircularProgress color="inherit" size={20} style={{ margin: 10 }} />
        </div>
      ) : (
        <>
          {websiteUserEngagements?.slice(
              page * rowsPerPage, page * rowsPerPage + rowsPerPage,
            ).map((data) => (
              <div className={classes.mb10}>
                <div className={classes.itemTitle}>
                  <Typography
                    variant="body2"
                    style={{ flex: 1 }}
                    className={classes.w500}
                  >
                    {data.pageTitle || '-'}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flex1} ${classes.flexEnd}`}
                  >
                    {data.pageLocation || '-'}
                  </Typography>
                  <Typography
                    variant="body2"
                    className={`${classes.flex1} ${classes.flexEnd}`}
                  >
                    {data.screenPageViews}
                  </Typography>
                </div>
              </div>
            ))}
          <div className={`${classes.row} ${classes.flexEnd}`}>
            <TablePagination
              component="div"
              count={totalPages}
              page={page}
              onChangePage={(e, newPage) => handleChangePage(e, newPage)}
              rowsPerPage={rowsPerPage}
              labelRowsPerPage=""
              rowsPerPageOptions={[]}
            />
          </div>
        </>
      )}
      <Dialog
        open={websiteUserEngagementsFilterDialogVisibility}
        onClose={onCloseDialogPressed}
        fullWidth
        maxWidth="md"
      >
        <div style={{ padding: 30 }}>
          <Typography
            variant="body1"
            className={`${classes.w500} ${classes.flex1}`}
            style={{ marginLeft: 10, marginBottom: 8 }}
          >
            {GlobalizedLocalizedString.common.labelFilter}
          </Typography>
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelStartDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingWebsiteUserEngagements}
            value={dateRange.from}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: date,
                to: dateRange.to,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <DateTimePickerField
            label={LocalizedString.analyticsScreen.labelEndDate}
            pickerMode={PICKER_MODE_DATE}
            disabled={downloadingWebsiteUserEngagements}
            value={dateRange.to}
            disableFuture
            onChangeDate={(date) => {
              setDateRange({
                from: dateRange.from,
                to: date,
              });
            }}
            InputProps={{ readOnly: true }}
          />
          <div className={`${classes.row} ${classes.flexEnd} ${classes.filterButtonActions}`}>
            <AccentButton
              onClick={onCloseDialogPressed}
              variant="text"
              caption={GlobalizedLocalizedString.common.buttonCaptionCancel}
              className={classes.buttonText}
              size="small"
              disabled={downloadingWebsiteUserEngagements}
            />
            <AccentButton
              onClick={onResetFilterPressed}
              variant="outlined"
              caption={GlobalizedLocalizedString.common.buttonCaptionReset}
              className={classes.buttonOutline}
              size="small"
              disabled={downloadingWebsiteUserEngagements}
            />
            <AccentButton
              type="submit"
              disableElevation
              caption={GlobalizedLocalizedString.common.buttonCaptionApply}
              size="small"
              onClick={() => onApplyFilterPressed(dateRange)}
              disabled={downloadingWebsiteUserEngagements}
            />
          </div>
        </div>
      </Dialog>
    </>
  );
};
export default WebsiteUserEngagements;
WebsiteUserEngagements.propTypes = {
  websiteUserEngagements: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloadingWebsiteUserEngagements: PropTypes.bool.isRequired,
  websiteUserEngagementsFilterDialogVisibility: PropTypes.bool.isRequired,
  websiteUserEngagementsDateRange: PropTypes.object.isRequired,
  totalPages: PropTypes.number.isRequired,
  onFilterIconPressed: PropTypes.func.isRequired,
  onCloseDialogPressed: PropTypes.func.isRequired,
  onApplyFilterPressed: PropTypes.func.isRequired,
  onResetFilterPressed: PropTypes.func.isRequired,
};

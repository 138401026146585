import { connect } from 'react-redux';
import { setImportDialogVisibility, setAlertErrorMessage } from '../../../redux/action';
import ImportDialog from './import-dialog.presentation';

const mapStateToProps = (state) => ({
  title: state.uiFunctionalPage.importTitle,
  visibility: state.uiFunctionalPage.importVisibility,
});

const mapDispatchToProps = (dispatch) => ({
  onClosePressed: () => {
    dispatch(setImportDialogVisibility(false, ''));
  },
  onError: (error) => {
    dispatch(setAlertErrorMessage(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(ImportDialog);

import { connect } from 'react-redux';
import moment from 'moment';
import TotalUsersByAppVersionsChart from './total-users-by-app-versions-chart.presentation';
import { getNDaysAgo, toMoment } from '../../../../helper/helper';
import { ANALYTICS_DATE_ONE_MONTH_AGO, ANALYTICS_DATE_TODAY, REVERSED_ISO_DATE_FORMAT } from '../../constant';
import {
  setAnalyticsUsersAppVersionChartDataDateRanges, setAlertErrorMessage,
  setUsersAppVersionChartDataFilterDialogVisibility, downloadAnalyticsUsersByAppVersionAsync,
  clearAnalyticsUsersAppVersionChartData,
} from '../../redux/action';
import { getLineChartFormattedData } from '../../helper';

const getUsersByAppVersionsDateRange = (state) => {
  const { usersAppVersionChartDataDateRange } = state.uiAnalytics;

  let dateFrom = usersAppVersionChartDataDateRange.from;
  let dateTo = usersAppVersionChartDataDateRange.to;

  if (usersAppVersionChartDataDateRange.from === ANALYTICS_DATE_ONE_MONTH_AGO) {
    dateFrom = getNDaysAgo(30);
  }

  if (usersAppVersionChartDataDateRange.to === ANALYTICS_DATE_TODAY) {
    dateTo = getNDaysAgo(0);
  }

  return { from: dateFrom, to: dateTo };
};

const getChartData = (state) => {
  const { usersAppVersionChartData, top5AppVersions } = state.analytics;
  const { usersAppVersionChartDataDateRange } = state.uiAnalytics;

  const appVersionsObject = top5AppVersions.reduce(
    (result, current) => ({ ...result, [current]: 0 }), {},
  );

  const dateRanges = getUsersByAppVersionsDateRange(state);
  const daysDifference = moment.duration(moment(dateRanges.to).diff(dateRanges.from)).asDays() + 1;
  const daysCompareArr = [];

  for (let i = 0; i < daysDifference; i += 1) {
    daysCompareArr[i] = {
      day: i.toString(),
      ...appVersionsObject,
    };
  }

  const usersAppVersions = daysCompareArr.map((day) => {
    const find = usersAppVersionChartData?.find((user) => user.day === day.day);
    if (find) return find;
    return day;
  });

  return getLineChartFormattedData(usersAppVersions, usersAppVersionChartDataDateRange);
};

const mapStateToProps = (state) => ({
  appVersions: state.analytics.top5AppVersions,
  chartData: getChartData(state),
  chartSeries: state.analytics.top5AppsChartSeries,
  downloading: state.uiAnalytics.downloadingUsersAppVersionChartData,
  usersByAppVersionsFilterDialogVisibility:
    state.uiAnalytics.usersAppVersionChartDataFilterDialogVisibility,
  usersByAppVersionsDateRange: getUsersByAppVersionsDateRange(state),
});

const mapDispatchToProps = (dispatch) => ({
  onAppear: () => {
    dispatch(clearAnalyticsUsersAppVersionChartData());
  },
  onFilterIconPressed: () => {
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(true));
  },
  onCloseDialogPressed: () => {
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(false));
  },
  onResetFilterPressed: () => {
    dispatch(setAnalyticsUsersAppVersionChartDataDateRanges({
      from: ANALYTICS_DATE_ONE_MONTH_AGO,
      to: ANALYTICS_DATE_TODAY,
    }));
    dispatch(downloadAnalyticsUsersByAppVersionAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(false));
  },
  onApplyFilterPressed: (dateRange) => {
    dispatch(setAnalyticsUsersAppVersionChartDataDateRanges({
      from: toMoment(dateRange.from).format(REVERSED_ISO_DATE_FORMAT),
      to: toMoment(dateRange.to).format(REVERSED_ISO_DATE_FORMAT),
    }));
    dispatch(downloadAnalyticsUsersByAppVersionAsync())
      .catch((err) => dispatch(setAlertErrorMessage(err)));
    dispatch(setUsersAppVersionChartDataFilterDialogVisibility(false));
  },
  onChangeStartDate: (date, setDateRange, dateRange) => {
    const startDate = toMoment(date);
    const endDate = toMoment(date).add(1, 'months');
    const isDateToWithin30Days = toMoment(dateRange.to)
      .isBetween(startDate, endDate, null, '[]');

    setDateRange({
      from: date,
      to: isDateToWithin30Days ? dateRange.to : endDate.format(REVERSED_ISO_DATE_FORMAT),
    });
  },
  onChangeEndDate: (date, setDateRange, dateRange) => {
    const startDate = toMoment(date);
    const endDate = toMoment(date).subtract(1, 'months');
    const isDateFromWithin30Days = toMoment(dateRange.from)
      .isBetween(endDate, startDate, null, '[]');

    setDateRange({
      from: isDateFromWithin30Days ? dateRange.from : endDate.format(REVERSED_ISO_DATE_FORMAT),
      to: date,
    });
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(TotalUsersByAppVersionsChart);

import {
  SET_DIALOG_VISIBILITY, SET_FUNCTIONAL_PAGE_TAPPED_ID, SET_FUNCTIONAL_PAGE_MODE,
  SET_ADVANCED_FILTER_DIALOG_VISIBILITY, SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING,
  ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM, CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM,
  SET_FUNCTIONAL_PAGE_SELECTED_ROW, SET_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM,
  SET_IMPORT_DIALOG_VISIBILITY,
} from '../action';
import { PAGE_MODE_TABLE } from '../../constant';

const initialState = {
  advancedFilterVisibility: false,
  visibility: false,
  importVisibility: false,
  importTitle: '',
  title: '',
  dialogType: '',
  tappedId: '',
  selectedRow: [],
  pageMode: PAGE_MODE_TABLE,
  filterString: '',
  advancedFilterForm: {},
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_DIALOG_VISIBILITY:
      return {
        ...state, visibility: action.status, dialogType: action.dialogType, title: action.title,
      };
    case SET_FUNCTIONAL_PAGE_TAPPED_ID:
      return { ...state, tappedId: action.id };
    case SET_FUNCTIONAL_PAGE_MODE:
      return { ...state, pageMode: action.mode };
    case SET_ADVANCED_FILTER_DIALOG_VISIBILITY:
      return { ...state, advancedFilterVisibility: action.status };
    case SET_ADVANCED_FILTER_DIALOG_SELECTED_FILTER_STRING:
      return { ...state, filterString: action.text };
    case ADD_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM:
      return {
        ...state,
        advancedFilterForm: { ...state.advancedFilterForm, [action.field]: action.value },
      };
    case SET_FUNCTIONAL_PAGE_SELECTED_ROW:
      return { ...state, selectedRow: action.selectedRow };
    case CLEAR_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM:
      return { ...state, advancedFilterForm: {} };
    case SET_FUNCTIONAL_PAGE_ADVANCED_FILTER_FORM:
      return { ...state, advancedFilterForm: action.data };
    case SET_IMPORT_DIALOG_VISIBILITY:
      return {
        ...state, importVisibility: action.status, importTitle: action.title,
      };
    default: return state;
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  PAGE_MODE_VIEW, RXFIELD_PERMISSION_NAME, RXFORM_PERMISSION, RXSTATE_PERMISSIONS,
  RXSTATE_PERMISSION_PAGE, RXFIELD_PERMISSION_DESCRIPTION, PAGE_MODE_TABLE,
} from '../../constant';
import { renderReduxFormOutlinedTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidatePermission } from '../../validation';

const renderDialogContent = (addingEditing, pageMode) => (
  <Grid container spacing={3}>
    <Grid item sm md>
      <Field
        name={RXFIELD_PERMISSION_NAME}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.permissionScreen.placeholderPermissionName}
        label={LocalizedString.permissionScreen.placeholderPermissionName}
        disabled={pageMode !== PAGE_MODE_TABLE}
        required
      />
    </Grid>
    <Grid item sm md>
      <Field
        name={RXFIELD_PERMISSION_DESCRIPTION}
        component={renderReduxFormOutlinedTextField}
        placeholder={LocalizedString.permissionScreen.placeholderDescription}
        label={LocalizedString.permissionScreen.placeholderDescription}
        disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
        required
        multiline
      />
    </Grid>
  </Grid>

);

const PermissionPage = ({
  addingEditing, downloading,
  handleSubmit, onAppear, onCancelPressed, onChangePage, onChangePageSize,
  onConfirmDeletePressed, onCreatePressed, onDeletePressed, onRefresh,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed, onViewPressed,
  pageMode, onEditPressed,
}) => (
  <FunctionalPage
    data={RXSTATE_PERMISSIONS}
    uiPage={RXSTATE_PERMISSION_PAGE}
    tableColumns={[
      {
        title: LocalizedString.permissionScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.permissionScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.permissionScreen.labelDescription, field: 'description', sorting: !downloading },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmDeletePressed}
    onCreatePressed={onCreatePressed}
    onDeletePressed={onDeletePressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.permissionScreen.buttonCaptionCreateNewPermission}
    deleteButtonCaption={LocalizedString.permissionScreen.buttonCaptionDeletePermission}
    title={LocalizedString.permissionScreen.title}
    editButtonCaption={LocalizedString.permissionScreen.buttonCaptionEditPermission}
    useFullWidth
    createPermissionName="AUTH_CREATE_PERMISSION"
    deletePermissionName="AUTH_DELETE_PERMISSION"
    editPermissionName="AUTH_EDIT_PERMISSION"
  >
    {renderDialogContent(addingEditing, pageMode)}
  </FunctionalPage>
);


export default reduxForm({
  form: RXFORM_PERMISSION,
  validate: rxformValidatePermission,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(PermissionPage);

PermissionPage.propTypes = {
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmDeletePressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDeletePressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  pageMode: PropTypes.string.isRequired,
  onEditPressed: PropTypes.func.isRequired,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import {
  CircularProgress, Grid, makeStyles, Paper, Typography, InputAdornment,
} from '@material-ui/core';
import { AccentButton, BasePage } from '../../component';
import {
  COLOR_SECONDARY,
  RXFIELD_SUMMARY_USER, RXFORM_SUMMARY,
  RXFIELD_SUMMARY_PLANNED_TOTAL_WORKING_HOUR,
  RXFIELD_SUMMARY_ACTUAL_TOTAL_WORKING_HOUR,
  RXFIELD_SUMMARY_LEAVE,
  RXFIELD_SUMMARY_WFA,
  COLOR_PRIMARY,
  RXFIELD_SUMMARY_MONTH,
  RXFIELD_SUMMARY_YEAR,
} from '../../constant';
import LocalizedString from '../../localization';
import {
  renderReduxFormOutlinedDropdownTextField,
  renderReduxFormOutlinedTextField,
  renderReduxFormSimpleDropdownField,
} from '../../../../redux-form-rendererer';
import { FormInitialValueShape, SimpleDataShape } from '../../type';

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: 23,
  },
  title: {
    color: COLOR_SECONDARY,
  },
  bodyContainer: {
    padding: '13px 35px 13px 35px',
    borderRadius: 4,
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.16)',
    justifyContent: 'space-between',
    marginBottom: 80,
  },
  columnContainer: {
    display: 'flex',
    alignItems: 'center',
  },
  activityIndicatorContainer: {
    margin: 20,
    display: 'flex',
    justifyContent: 'center',
  },
  exportButton: {
    color: COLOR_PRIMARY,
  },
}));

const renderContent = (
  downloadingSummary, classes, profileSearchText, tappedId,
  onExportToPDFPressed, exporting,
  selectedMonth, months, onMonthOptionSelected,
  selectedYear,
) => {
  if (profileSearchText && tappedId && selectedMonth && selectedYear) {
    if (downloadingSummary) {
      return (<div className={classes.activityIndicatorContainer}><CircularProgress /></div>);
    }
    return (
      <Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SUMMARY_PLANNED_TOTAL_WORKING_HOUR}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.summaryPage.placeholderPlannedTotalWorkingHour}
                label={LocalizedString.summaryPage.placeholderPlannedTotalWorkingHour}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {LocalizedString.summaryPage.labelHours}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SUMMARY_ACTUAL_TOTAL_WORKING_HOUR}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.summaryPage.placeholderActualTotalWorkingHour}
                label={LocalizedString.summaryPage.placeholderActualTotalWorkingHour}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {LocalizedString.summaryPage.labelHours}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <AccentButton
              variant="outlined"
              caption={LocalizedString.summaryPage.buttonCaptionExportToPDF}
              onClick={onExportToPDFPressed}
              loading={exporting}
              style={classes.exportButton}
            />
          </Grid>
          <Grid item sm md>
            <Grid item>
              <Field
                name={RXFIELD_SUMMARY_LEAVE}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.summaryPage.placeholderLeave}
                label={LocalizedString.summaryPage.placeholderLeave}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {LocalizedString.summaryPage.labelDays}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item>
              <Field
                name={RXFIELD_SUMMARY_WFA}
                component={renderReduxFormOutlinedTextField}
                placeholder={LocalizedString.summaryPage.placeholderWFA}
                label={LocalizedString.summaryPage.placeholderWFA}
                disabled
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="start">
                      {LocalizedString.summaryPage.labelDays}
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
};

const SummaryPage = ({
  initialValues, profiles,
  downloadingSummary, downloadingProfiles,
  onAppear, onChangeUserText, onUserOptionSelected,
  profileSearchText, tappedId,
  onExportToPDFPressed, exporting,
  selectedMonth, months, onMonthOptionSelected,
  selectedYear, years, onYearOptionSelected,
  onAllFieldSelected,
}) => {
  const classes = useStyles();
  useEffect(onAppear, []);
  useEffect(() => {
    if (selectedMonth && selectedYear && tappedId) {
      onAllFieldSelected();
    }
  }, [selectedMonth, selectedYear, tappedId, onAllFieldSelected]);

  return (
    <BasePage>
      <div className={classes.headerContainer}>
        <Typography variant="h4" className={classes.title}>{LocalizedString.summaryPage.title}</Typography>
      </div>

      <Paper elevation={3} className={classes.bodyContainer}>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Field
              name={RXFIELD_SUMMARY_USER}
              component={renderReduxFormOutlinedDropdownTextField}
              placeholder={LocalizedString.summaryPage.placeholderFullName}
              label={LocalizedString.summaryPage.placeholderFullName}
              disabled={downloadingProfiles}
              data={profiles}
              value={initialValues.user}
              loading={downloadingProfiles || downloadingSummary}
              onChangeText={onChangeUserText}
              onOptionSelected={onUserOptionSelected}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Field
              name={RXFIELD_SUMMARY_MONTH}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.summaryPage.labelMonths}
              label={LocalizedString.summaryPage.labelMonths}
              value={selectedMonth}
              disabled={downloadingSummary}
              data={months}
              onOptionSelected={onMonthOptionSelected}
            />
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item sm md>
            <Field
              name={RXFIELD_SUMMARY_YEAR}
              component={renderReduxFormSimpleDropdownField}
              placeholder={LocalizedString.summaryPage.labelYears}
              label={LocalizedString.summaryPage.labelYears}
              value={selectedYear}
              disabled={downloadingSummary}
              data={years}
              onOptionSelected={onYearOptionSelected}
            />
          </Grid>
        </Grid>

        {renderContent(
          downloadingSummary, classes, profileSearchText, tappedId,
          onExportToPDFPressed, exporting,
          selectedMonth, months, onMonthOptionSelected,
          selectedYear,
        )}
      </Paper>
    </BasePage>
  );
};

export default reduxForm({
  form: RXFORM_SUMMARY,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(SummaryPage);

SummaryPage.propTypes = {
  profiles: PropTypes.arrayOf(SimpleDataShape).isRequired,
  months: PropTypes.arrayOf(PropTypes.string),
  years: PropTypes.arrayOf(PropTypes.number),
  initialValues: FormInitialValueShape.isRequired,

  downloadingProfiles: PropTypes.bool.isRequired,
  downloadingSummary: PropTypes.bool.isRequired,
  exporting: PropTypes.bool.isRequired,

  profileSearchText: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
  selectedMonth: PropTypes.string.isRequired,

  selectedYear: PropTypes.number.isRequired,

  onAppear: PropTypes.func.isRequired,
  onChangeUserText: PropTypes.func.isRequired,
  onUserOptionSelected: PropTypes.func.isRequired,
  onExportToPDFPressed: PropTypes.func.isRequired,
  onAllFieldSelected: PropTypes.func.isRequired,
  onYearOptionSelected: PropTypes.func.isRequired,
  onMonthOptionSelected: PropTypes.func.isRequired,
};

SummaryPage.defaultProps = {
  months: [],
  years: [],
};

import React from 'react';
import PropTypes from 'prop-types';
import { Field, reduxForm } from 'redux-form';
import { Grid } from '@material-ui/core';
import { FunctionalPage } from '../../component';
import {
  COLOR_BACKGROUND, COLOR_DISABLED_ROW, FILTER_TYPE_DROPDOWN, PAGE_MODE_VIEW, RXFIELD_PROVINCE_NAME,
  RXFIELD_PROVINCE_TIME_ZONE, RXFORM_PROVINCE, RXSTATE_PROVINCES, RXSTATE_PROVINCE_PAGE, status,
  STATUS_DISABLED, STATUS_ENABLED, timezoneOffset, RXFIELD_PROVINCE_CODE,
} from '../../constant';
import { FormInitialValueShape } from '../../type';
import { renderReduxFormOutlinedTextField, renderReduxFormOutlinedDropdownTextField } from '../../redux-form-rendererer';
import LocalizedString from '../../localization';
import { rxformValidateProvince } from '../../validation';

const renderDialogContent = (defaultTimeZoneValue, addingEditing, onTimeZoneOptionSelected,
  pageMode) => (
    <Grid container spacing={3}>
      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROVINCE_NAME}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.provinceScreen.placeholderProvinceName}
            label={LocalizedString.provinceScreen.placeholderProvinceName}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            required
          />
        </Grid>
        <Grid item>
          <Field
            name={RXFIELD_PROVINCE_CODE}
            component={renderReduxFormOutlinedTextField}
            placeholder={LocalizedString.provinceScreen.placeholderProvinceCode}
            label={LocalizedString.provinceScreen.placeholderProvinceCode}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            type="number"
          />
        </Grid>
      </Grid>

      <Grid item sm md>
        <Grid item>
          <Field
            name={RXFIELD_PROVINCE_TIME_ZONE}
            component={renderReduxFormOutlinedDropdownTextField}
            placeholder={LocalizedString.provinceScreen.placeholderProvinceTimeZone}
            label={LocalizedString.provinceScreen.placeholderProvinceTimeZone}
            disabled={addingEditing || pageMode === PAGE_MODE_VIEW}
            data={timezoneOffset}
            value={defaultTimeZoneValue}
            onOptionSelected={onTimeZoneOptionSelected}
            required
          />
        </Grid>
      </Grid>
    </Grid>
);

const ProvincePage = ({
  initialValues,
  addingEditing, downloading, enablingDisabling, resyncing,
  handleSubmit, onAppear, onApplyAdvancedFilterPressed, onCancelPressed, onChangePage,
  onChangePageSize, onConfirmContextMenuPressed, onCreatePressed, onDownloadPressed,
  onEditPressed, onContextMenuPressed, onRefresh, onResetAdvancedFilterPressed,
  onSavePressed, onSearchBarTextChanged, onSortPressed, onSubmitPressed,
  onTimeZoneOptionSelected, onViewPressed,
  currentProvinceStatus, pageMode, tappedId,
}) => (
  <FunctionalPage
    data={RXSTATE_PROVINCES}
    uiPage={RXSTATE_PROVINCE_PAGE}
    moreMenus={[
      {
        caption: LocalizedString.common.buttonCaptionEnable,
        disabled: currentProvinceStatus === STATUS_ENABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgEnableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionDisable,
        disabled: currentProvinceStatus === STATUS_DISABLED || enablingDisabling,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgDisableConfirmation),
      },
      {
        caption: LocalizedString.common.buttonCaptionResync,
        disabled: resyncing,
        onPress: () => onContextMenuPressed(tappedId,
          LocalizedString.common.msgResyncConfirmation),
      },
    ]}
    tableColumns={[
      {
        title: LocalizedString.provinceScreen.labelNo, field: 'no', sorting: false, width: 40, customFilterAndSearch: (term, rowData) => (rowData),
      },
      { title: LocalizedString.provinceScreen.labelName, field: 'name', sorting: !downloading },
      { title: LocalizedString.provinceScreen.labelTimeZone, field: 'timeZone', sorting: !downloading },
      { title: LocalizedString.provinceScreen.labelCreatedDate, field: 'createdDate', sorting: !downloading },
      { title: LocalizedString.provinceScreen.labelLastModifiedDate, field: 'lastModifiedDate', sorting: !downloading },
      { title: LocalizedString.provinceScreen.labelStatus, field: 'status', sorting: !downloading },
    ]}
    filterColumns={[
      {
        title: LocalizedString.provinceScreen.labelTimeZone, field: 'timeZone', type: FILTER_TYPE_DROPDOWN, data: timezoneOffset,
      },
      {
        title: LocalizedString.provinceScreen.labelStatus, field: 'status', type: FILTER_TYPE_DROPDOWN, data: status, useDropdownValue: true,
      },
    ]}
    handleSubmit={handleSubmit}
    onAppear={onAppear}
    onApplyAdvancedFilterPressed={onApplyAdvancedFilterPressed}
    onCancelPressed={onCancelPressed}
    onChangePage={onChangePage}
    onChangePageSize={onChangePageSize}
    onConfirmDeletePressed={onConfirmContextMenuPressed}
    onCreatePressed={onCreatePressed}
    onDownloadPressed={onDownloadPressed}
    onEditPressed={onEditPressed}
    onRefresh={onRefresh}
    onResetAdvancedFilterPressed={onResetAdvancedFilterPressed}
    onSavePressed={onSavePressed}
    onSearchBarTextChanged={onSearchBarTextChanged}
    onSortPressed={onSortPressed}
    onSubmitPressed={onSubmitPressed}
    onViewPressed={onViewPressed}
    createNewButtonCaption={LocalizedString.provinceScreen.buttonCaptionCreateNewProvince}
    deleteButtonCaption={LocalizedString.provinceScreen.buttonCaptionDeleteProvince}
    editButtonCaption={LocalizedString.provinceScreen.buttonCaptionEditProvince}
    title={LocalizedString.provinceScreen.title}
    disableDelete
    enableSave
    useFullWidth
    rowStyle={(rowData) => ({
      backgroundColor: rowData.status === STATUS_DISABLED ? COLOR_DISABLED_ROW : COLOR_BACKGROUND,
    })}
    createPermissionName="MDATA_CREATE_PROVINCE"
    editPermissionName="MDATA_EDIT_PROVINCE"
    savePermissionName="MDATA_GET_PROVINCE"
  >
    {renderDialogContent(initialValues.timeZone, addingEditing, onTimeZoneOptionSelected, pageMode)}
  </FunctionalPage>
);

export default reduxForm({
  form: RXFORM_PROVINCE,
  validate: rxformValidateProvince,
  enableReinitialize: true,
  keepDirtyOnReinitialize: true,
})(ProvincePage);

ProvincePage.propTypes = {
  initialValues: FormInitialValueShape.isRequired,
  addingEditing: PropTypes.bool.isRequired,
  downloading: PropTypes.bool.isRequired,
  enablingDisabling: PropTypes.bool.isRequired,
  resyncing: PropTypes.bool.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  onAppear: PropTypes.func.isRequired,
  onApplyAdvancedFilterPressed: PropTypes.func.isRequired,
  onCancelPressed: PropTypes.func.isRequired,
  onChangePage: PropTypes.func.isRequired,
  onChangePageSize: PropTypes.func.isRequired,
  onConfirmContextMenuPressed: PropTypes.func.isRequired,
  onCreatePressed: PropTypes.func.isRequired,
  onDownloadPressed: PropTypes.func.isRequired,
  onEditPressed: PropTypes.func.isRequired,
  onContextMenuPressed: PropTypes.func.isRequired,
  onRefresh: PropTypes.func.isRequired,
  onResetAdvancedFilterPressed: PropTypes.func.isRequired,
  onSavePressed: PropTypes.func.isRequired,
  onSearchBarTextChanged: PropTypes.func.isRequired,
  onSortPressed: PropTypes.func.isRequired,
  onSubmitPressed: PropTypes.func.isRequired,
  onTimeZoneOptionSelected: PropTypes.func.isRequired,
  onViewPressed: PropTypes.func.isRequired,
  currentProvinceStatus: PropTypes.string.isRequired,
  pageMode: PropTypes.string.isRequired,
  tappedId: PropTypes.string.isRequired,
};
